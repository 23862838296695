import { FC } from 'react';
import { Tag, Space } from 'antd';

import { Typography } from 'components/basic';
import { SelectTagItemsProps } from './types';
import styles from './style.module.less';

const { UcBodyText } = Typography;

const SelectTagItems: FC<SelectTagItemsProps> = ({
  data,
  valueKey,
  labelKey = 'name',
  onChange
}) => {
  return (
    <Space size="small" wrap className={styles.select_tag_items_container}>
      {data.map((item, index) => (
        <Tag
          onClose={e => {
            e.preventDefault();
            onChange(valueKey ? item[valueKey] : item.id);
          }}
          closable
          key={index}
          className={styles.tag_item}
        >
          <UcBodyText
            ellipsis
            text={item[labelKey]}
            className={styles.tag_text_content}
            title={item[labelKey]}
          />
        </Tag>
      ))}
    </Space>
  );
};

export default SelectTagItems;
