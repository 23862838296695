import { BaseType } from 'antd/es/typography/Base';
import { ReactNode } from 'react';

export enum popupTypes {
  delete = 'delete',
  filters = 'filters'
}

export interface MenuInterface {
  type?: BaseType;
  label: string;
  value: string;
  color?: string;
  icon?: ReactNode;
}
