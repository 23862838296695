import { lazyImport } from 'helpers';

export const ProjectWrapper = lazyImport(
  () => import('pages/settings/pages/ProjectWrapper')
);

export const CustomerPreview = lazyImport(
  () => import('pages/customers/customerPreview')
);

export const CustomerReviews = lazyImport(
  () => import('pages/customers/reviews')
);
export const FormSubmissions = lazyImport(
  () => import('pages/customers/formSubmissions')
);

export const FormSubmission = lazyImport(
  () => import('pages/customers/formSubmission')
);

export const AnalyticsOverview = lazyImport(
  () => import('pages/analytics/overview')
);

export const Dashboard = lazyImport(() => import('pages/dashboard'));

export const Orders = lazyImport(() => import('pages/products/orders/orders'));
export const AbandonedCheckout = lazyImport(
  () => import('pages/abandonedCheckouts')
);

export const OrderDetails = lazyImport(() => import('pages/orderDetails'));
export const Attributes = lazyImport(() => import('pages/products/attributes'));
export const Categories = lazyImport(
  () => import('components/shared/Category')
);
export const AddCategories = lazyImport(
  () => import('components/shared/Category/pages/add')
);

export const EditCategories = lazyImport(
  () => import('components/shared/Category/pages/edit')
);

export const CustomersOverview = lazyImport(
  () => import('pages/customers/overview')
);
export const CustomersGroups = lazyImport(
  () => import('pages/customers/groups')
);
export const DiscountsList = lazyImport(() => import('pages/discounts/list'));
export const CreateUpdateDiscounts = lazyImport(
  () => import('pages/discounts/CreateUpdate')
);

export const ShippingDelivery = lazyImport(
  () => import('pages/settings/pages/shippingDelivery')
);

export const CreateShippingProfile = lazyImport(
  () =>
    import(
      'pages/settings/pages/shippingDelivery/pages/ShippingProfileCreateUpdate'
    )
);

export const CreateUpdateSelfPickup = lazyImport(
  () =>
    import('pages/settings/pages/shippingDelivery/pages/SelfPickupCreateUpdate')
);

export const Taxes = lazyImport(() => import('pages/settings/pages/taxes'));
export const Checkout = lazyImport(
  () => import('pages/settings/pages/Checkout')
);
export const LegalDocs = lazyImport(
  () => import('pages/settings/pages/LegalDocs')
);

export const LegalDoc = lazyImport(
  () => import('pages/settings/pages/LegalDocs/pages/LegalDoc')
);
// export const Mailboxes = lazyImport(() => import('pages/settings/pages/Mailboxes'));
export const Languages = lazyImport(
  () => import('pages/settings/pages/languages')
);

export const Domains = lazyImport(() => import('pages/settings/pages/domains'));
export const Billing = lazyImport(() => import('pages/settings/pages/Billing'));

export const Products = lazyImport(() => import('pages/products/products'));
export const AddProduct = lazyImport(
  () => import('pages/products/products/physicalProduct/add')
);

export const EditProduct = lazyImport(
  () => import('pages/products/products/physicalProduct/edit')
);

export const AddSubscriptionProduct = lazyImport(
  () => import('pages/products/products/subscriptionProduct/add')
);

export const EditSubscriptionProduct = lazyImport(
  () => import('pages/products/products/subscriptionProduct/edit')
);

export const AddDigitalProduct = lazyImport(
  () => import('pages/products/products/digitalProduct/add')
);

export const EditDigitalProduct = lazyImport(
  () => import('pages/products/products/digitalProduct/edit')
);

export const AddAppointmentProduct = lazyImport(
  () => import('pages/products/products/appointmentProduct/add')
);

export const EditAppointmentProduct = lazyImport(
  () => import('pages/products/products/appointmentProduct/edit')
);

export const AddBooking = lazyImport(
  () => import('pages/bookings/bookings/bookingProduct/add')
);

export const EditBooking = lazyImport(
  () => import('pages/bookings/bookings/bookingProduct/edit')
);

export const AddRentalProduct = lazyImport(
  () => import('pages/rentals/rentals/rentalProduct/add')
);

export const EditRentalProduct = lazyImport(
  () => import('pages/rentals/rentals/rentalProduct/edit')
);

export const AddTableProduct = lazyImport(
  () => import('pages/products/products/tableProduct/add')
);

export const EditTableProduct = lazyImport(
  () => import('pages/products/products/tableProduct/edit')
);

export const AddGiftCardProduct = lazyImport(
  () => import('pages/products/products/giftCardProduct/add')
);

export const EditGiftCardProduct = lazyImport(
  () => import('pages/products/products/giftCardProduct/edit')
);

export const Bookings = lazyImport(() => import('pages/bookings/bookings'));
export const Rentals = lazyImport(() => import('pages/rentals/rentals'));

export const RentalTypes = lazyImport(
  () => import('pages/rentals/rentalTypes')
);

export const AddRentalType = lazyImport(
  () => import('pages/rentals/rentalTypes/pages/add')
);

export const EditRentalType = lazyImport(
  () => import('pages/rentals/rentalTypes/pages/edit')
);

export const RentalOrders = lazyImport(
  () => import('pages/rentals/rentalOrders')
);

export const AddBookingCategories = lazyImport(
  () => import('components/shared/Category/pages/add')
);

export const EditBookingCategories = lazyImport(
  () => import('components/shared/Category/pages/edit')
);

export const AddEventCategories = lazyImport(
  () => import('components/shared/Category/pages/add')
);

export const EditEventCategories = lazyImport(
  () => import('components/shared/Category/pages/edit')
);

export const ProductFamily = lazyImport(
  () => import('pages/products/productFamily')
);

export const ProductFamilyCreation = lazyImport(
  () => import('pages/products/productFamily/pages/add')
);

export const ProductFamilyEdit = lazyImport(
  () => import('pages/products/productFamily/pages/edit')
);

export const BookingTypes = lazyImport(
  () => import('pages/bookings/bookingTypes')
);

export const BookingProductFamilyCreation = lazyImport(
  () => import('pages/bookings/bookingTypes/pages/add')
);

export const BookingProductFamilyEdit = lazyImport(
  () => import('pages/bookings/bookingTypes/pages/edit')
);

export const Payments = lazyImport(
  () => import('pages/settings/pages/Payments')
);
export const OtherPayments = lazyImport(
  () =>
    import(
      'pages/settings/pages/Payments/pages/OtherPaymentMethods/OtherPaymentMethods'
    )
);

export const SinglePayments = lazyImport(
  () =>
    import(
      'pages/settings/pages/Payments/pages/PaymentMethodConfiguration/PaymentMethodConfiguration'
    )
);

export const ProjectSetup = lazyImport(
  () => import('pages/settings/pages/ProjectSetup')
);

export const Notifications = lazyImport(
  () => import('pages/settings/pages/Notifications')
);

export const PreviewPage = lazyImport(
  () =>
    import('pages/settings/pages/Notifications/pages/PreviewPage/PreviewPage')
);

export const Team = lazyImport(
  () => import('pages/settings/pages/team/pages/teamMembersTable')
);

export const InviteMember = lazyImport(
  () => import('pages/settings/pages/team/pages/inviteMember')
);

export const Locations = lazyImport(
  () => import('pages/settings/pages/locations')
);

export const Projects = lazyImport(() => import('pages/projects'));

export const Apps = lazyImport(() => import('pages/apps'));
export const ActivityLog = lazyImport(() => import('pages/activityLog'));
// const Channels = lazyImport(() => import('pages/channels'));
// const Analytics = lazyImport(() => import('pages/analytics'));

// const NoFoundPage = lazyImport(() => import('pages/NotFound'));

export const Collections = lazyImport(
  () => import('pages/itemManagement/collections')
);

export const AddCollection = lazyImport(
  () => import('pages/itemManagement/collections/pages/add')
);

export const EditCollection = lazyImport(
  () => import('pages/itemManagement/collections/pages/edit')
);

export const CollectionItems = lazyImport(
  () => import('pages/itemManagement/items')
);

export const AddCollectionItem = lazyImport(
  () => import('pages/itemManagement/collectionItem/pages/add')
);

export const EditCollectionItem = lazyImport(
  () => import('pages/itemManagement/collectionItem/pages/edit')
);

export const AddCollectionCategories = lazyImport(
  () => import('components/shared/Category/pages/add')
);

export const EditCollectionCategories = lazyImport(
  () => import('components/shared/Category/pages/edit')
);

export const FormFields = lazyImport(
  () => import('pages/itemManagement/formFields')
);

export const TimeSlots = lazyImport(
  () => import('pages/settings/pages/timeSlots')
);

export const Events = lazyImport(() => import('pages/events/events'));

export const Attendees = lazyImport(() => import('pages/events/attendees'));

export const EventTypes = lazyImport(() => import('pages/events/eventTypes'));

export const EventProductFamilyCreation = lazyImport(
  () => import('pages/events/eventTypes/pages/add')
);

export const EventProductFamilyEdit = lazyImport(
  () => import('pages/events/eventTypes/pages/edit')
);

export const Reservations = lazyImport(
  () => import('pages/bookings/reservations')
);
export const AddEvent = lazyImport(
  () => import('pages/events/events/pages/add')
);

export const EditEvent = lazyImport(
  () => import('pages/events/events/pages/edit')
);

export const SalesChannels = lazyImport(() => import('pages/salesChannels'));
