import React, { FC } from 'react';
import { Drawer } from 'antd';
import { noop } from 'lodash';
import { useReactiveVar } from '@apollo/client';
import { isMobileDevice } from 'apollo/cache';

import { FilterProps } from './types';
import styles from './styles.module.less';
import { Typography } from 'components/basic';
import { ShowElement } from 'components/shared';
import {
  ButtonGroups,
  Date,
  DateRange,
  FilterItem,
  Footer,
  MultiSelectFilter,
  NumberInput,
  PriceRange,
  SelectFilter,
  SelectTagItems,
  Switcher,
  TextInput
} from './Components';
import { Popup } from 'components/mobile';

const { UcHeading } = Typography;

const FilterDrawer: FC<FilterProps> = ({
  title,
  width = 400,
  className,
  footer,
  visible,
  onResetFilters,
  rightBarValue,
  closable = false,
  children,
  onClose = noop
}) => {
  const isMobile = useReactiveVar(isMobileDevice);

  if (isMobile) {
    return (
      <Popup
        zIndex="1004"
        position="bottom"
        visible={Boolean(visible)}
        padding="onlyBody"
        popupScreen="fullScreen"
        onClose={onClose}
        onRightBarAction={onResetFilters}
        title={title}
        footer={footer}
        rightBarValue={rightBarValue}
      >
        {children}
      </Popup>
    );
  }

  return (
    <Drawer
      destroyOnClose
      title={
        <div className={styles.filter_drawer_header_container}>
          <div className={styles.header_content}>
            <UcHeading defaultMargin={false} level={4}>
              {title}
            </UcHeading>
          </div>
        </div>
      }
      footer={
        <div className={styles.filter_drawer_footer_container}>
          <ShowElement isShow={Boolean(footer)}>
            <div className={styles.footer_content}>{footer}</div>
          </ShowElement>
        </div>
      }
      width={width}
      open={visible}
      onClose={onClose}
      closable={closable}
      rootClassName={`${styles.us_filter_drawer} ${className}`}
    >
      <div className={styles.filter_drawer_children_container}>{children}</div>
    </Drawer>
  );
};

const UcFilterDrawer = Object.assign(FilterDrawer, {
  Date,
  Switcher,
  TextInput,
  FilterItem,
  PriceRange,
  NumberInput,
  ButtonGroups,
  SelectFilter,
  SelectTagItems,
  MultiSelectFilter,
  Footer,
  DateRange
});

export default UcFilterDrawer;
