import React, {
  SetStateAction,
  Dispatch,
  useMemo,
  useState,
  useEffect,
  useRef
} from 'react';
import { FormInstance, Table } from 'antd';
import { useTranslation } from 'react-i18next';
import { ImportableField } from 'generatedHooks/commerce/generated';
import { KeyValuePair, StateDispatcher } from 'core/globalTypes';
import { SystemMessage } from 'helpers';

import StepContentHeading from 'components/global/ImportModal/components/StepContentHeading';
import {
  ImportableEntity,
  ImportableFieldExtended
} from 'components/global/ImportModal/types';
import { IMPORTABLE_ENTITY_KEYS } from 'components/global/ImportModal/constants';
import { Skeleton, ShowElement } from 'components/shared';
import useImportableFields from 'components/global/ImportModal/hooks/useImportableFields';
import columns from './columns';
import styles from './styles.module.less';
import { importableFieldsAdapter } from 'components/global/ImportModal/utils';
import NewFieldModal from 'components/global/ImportModal/pages/Mapping/components/NewFieldModal';
import Form from 'components/core/form';

const { useForm } = Form;

interface Props {
  fileHeader: string[] | undefined;
  form: FormInstance;
  entityType: ImportableEntity;
  setIsMappingValid: Dispatch<SetStateAction<boolean>>;
  productFamily: string | undefined;
  selectedFamilyId: string;
  setFields: Dispatch<SetStateAction<ImportableFieldExtended[]>>;
  setNewFieldsDefaultValues: StateDispatcher<KeyValuePair<string>>;
}

const MappingStep: React.FC<Props> = ({
  fileHeader,
  entityType,
  form,
  setIsMappingValid,
  productFamily,
  selectedFamilyId,
  setFields,
  setNewFieldsDefaultValues
}) => {
  const [newFieldModalVisible, setNewFieldModalVisible] =
    useState<boolean>(false);

  const [, forceUpdate] = useState<boolean>(false);
  const { t } = useTranslation(['importModal', 'errors']);
  const currentlyCreatingField = useRef('');

  const { importableFields, loading } = useImportableFields(
    entityType,
    selectedFamilyId
  );

  const [newFieldForm] = useForm();

  const isFieldSelected = (key: string) => {
    return !!form.getFieldValue(key);
  };

  const adaptedImportableFields = useMemo(() => {
    return importableFieldsAdapter(importableFields);
  }, [importableFields]);

  const requiredFieldsKeys = useMemo(() => {
    if (adaptedImportableFields) {
      return adaptedImportableFields.reduce((keys: string[], field) => {
        if (field.required) {
          keys.push(field.key);
        }

        return keys;
      }, []);
    }
  }, [adaptedImportableFields]);

  useEffect(() => {
    // In case if the required fields are already filled by autocomplete (setMatchingValues).
    setIsMappingValid(validateMappingFields());
  }, [requiredFieldsKeys]);

  const setMatchingValues = (label: string, key: string) => {
    // In case if the uploaded file header includes exactly the same label as some of the importable fields
    const value = fileHeader?.find(
      item => item.toLowerCase() === label.toLowerCase()
    );

    if (value) {
      form.setFieldsValue({ [key]: value });
    }
  };

  const validateMappingFields = () => {
    if (requiredFieldsKeys) {
      const isMappingValid = requiredFieldsKeys?.every(
        (key: string) => !!form.getFieldValue(key)
      );

      return isMappingValid;
    }

    return false;
  };

  const onSelectChange = (e: string, rec: ImportableField) => {
    if (!e) {
      form.resetFields([rec.key]);
    }

    forceUpdate(prev => !prev);

    setIsMappingValid(validateMappingFields());
  };

  useEffect(() => {
    if (adaptedImportableFields) {
      setFields(adaptedImportableFields);
    }
  }, [adaptedImportableFields]);

  const openNewFieldModal = (key: string, searchValue: string) => {
    newFieldForm.setFieldValue('fieldName', searchValue);
    setNewFieldModalVisible(true);
    currentlyCreatingField.current = key;
  };

  const closeNewFieldModal = () => {
    setNewFieldModalVisible(false);
    currentlyCreatingField.current = '';
    newFieldForm.setFieldsValue({ fieldName: '', defaultValue: '' });
  };

  const isFieldNameAvailable = (fieldName: string) => {
    const fields = form.getFieldsValue();

    return !Object.values(fields).some(value => value === fieldName);
  };

  const createNewFieldHandler = () => {
    const fieldNameValue = newFieldForm.getFieldValue('fieldName') || '';

    if (!isFieldNameAvailable(fieldNameValue)) {
      return SystemMessage.error(t('exisingFieldError'));
    }

    form.setFieldsValue({
      [currentlyCreatingField.current]: fieldNameValue
    });
    setNewFieldsDefaultValues(prevValues => {
      return {
        ...prevValues,
        [currentlyCreatingField.current]:
          newFieldForm.getFieldValue('defaultValue') || ''
      };
    });
    setIsMappingValid(validateMappingFields());
    closeNewFieldModal();
  };

  return (
    <div className={styles.wrapper}>
      <StepContentHeading
        title={t('importModal:mappingTitle')}
        description={t('importModal:mappingDescription', {
          data: t(IMPORTABLE_ENTITY_KEYS[entityType])
        })}
      />
      {loading ? (
        <div className={styles.skeletonContainer}>
          <Skeleton.Table rowCount={7} />
        </div>
      ) : (
        <>
          <Table
            dataSource={adaptedImportableFields}
            columns={columns(
              t,
              productFamily || t(entityType.toLowerCase()),
              fileHeader,
              onSelectChange,
              isFieldSelected,
              setMatchingValues,
              openNewFieldModal
            )}
            pagination={false}
            className={styles.cs__table}
            scroll={{ x: 500, y: 480 }}
          />
          <ShowElement isShow={newFieldModalVisible}>
            <Form form={newFieldForm} colon={false}>
              <NewFieldModal
                open={newFieldModalVisible}
                onCloseModal={closeNewFieldModal}
                onSaveNewField={createNewFieldHandler}
              />
            </Form>
          </ShowElement>
        </>
      )}
    </div>
  );
};

export default MappingStep;
