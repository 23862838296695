import { FC, useMemo } from 'react';
import { useFilterQuery, useHistory } from 'hooks';

import { Switch } from 'components/basic';
import { SwitcherProps } from './types';

const Switcher: FC<SwitcherProps> = ({
  checked,
  name,
  onChange,
  ...restProps
}) => {
  const { queryObject } = useFilterQuery();
  const { push } = useHistory();

  const handleSwitcherChange = (value: boolean) => {
    if (onChange) {
      onChange(value);
    }

    if (name) {
      push({ [name]: Number(value) });
    }
  };

  const switcherValue = useMemo(() => {
    if (name && Object.hasOwn(queryObject, name)) {
      return Boolean(+queryObject[name]);
    }

    if (checked !== undefined) {
      return checked;
    }
  }, [queryObject, checked, name]);

  return (
    <Switch
      checked={switcherValue}
      onChange={handleSwitcherChange}
      {...restProps}
    />
  );
};

export default Switcher;
