// @ts-nocheck

import { ApolloLink, from, HttpLink, makeVar } from '@apollo/client';
import { RestLink } from 'apollo-link-rest';
import { onError } from '@apollo/client/link/error';
import i18n from 'i18next';
import { RetryLink } from '@apollo/client/link/retry';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import { isEqual } from 'lodash';
import { customHeaders, ERROR_CODES } from 'core/globalTypes';
import {
  CLEAR_LAYOUT_CACHE_KEY,
  ERROR_CODES_TO_SKIP_GLOBAL_HANDLING
} from 'core/constants/common';
import { timeVariableToTimezone } from 'helpers/commonHelpers/timeVariableToTimezone';
import getAnalyticsHeaders from 'helpers/getAnalyticsHeaders';
import SystemMessage from 'helpers/utils/SystemMessage';

import {
  HapticHelper,
  HapticStyleNameEnum
} from '../helpers/utils/HapticHelper';

const {
  VITE_APP_COMMERCE_URL,
  VITE_APP_BUILDER_URL,
  VITE_APP_SSO_URL,
  VITE_APP_REMOTE_HOST,
  VITE_APP_APP_STORE_URL,
  VITE_APP_WEBHOOK_URL
} = import.meta.env;

const commerce_uri = VITE_APP_COMMERCE_URL + '/graphql';
const builder_uri = VITE_APP_BUILDER_URL + '/graphql';
const accounts_uri = VITE_APP_SSO_URL + '/graphql';
const appstore_uri = VITE_APP_APP_STORE_URL;
const accounts_rest_uri = VITE_APP_SSO_URL;
const webhook_uri = VITE_APP_WEBHOOK_URL;

export const permission = makeVar<number[]>([]);

type OptionType = {
  onProgress: (event: ProgressEvent) => void;
  onAbortPossible: Function;
  useUpload?: boolean;
} & RequestInit;

export const projectNameForRemoteHost = makeVar<string>('');

function uploadFetch(url: string, options: OptionType): Promise<Response> {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();

    xhr.onload = () => {
      const opts: ResponseInit = {
        status: xhr.status,
        statusText: xhr.statusText
      };

      const body =
        'response' in xhr ? xhr.response : (xhr as XMLHttpRequest).responseText;

      resolve(new Response(body, opts));
    };

    xhr.onerror = () => {
      reject(new TypeError('Network request failed'));
    };

    xhr.ontimeout = () => {
      reject(new TypeError('Network request failed'));
    };

    xhr.open(options.method as string, url, true);

    Object.keys(options.headers as Headers).forEach(key => {
      xhr.setRequestHeader(
        key,
        (options.headers as Headers)[key as keyof typeof options.headers]
      );
    });

    xhr.upload.onprogress = options.onProgress;

    options.onAbortPossible(() => {
      xhr.abort();
    });

    xhr.send(options.body as any);
  });
}

function customFetch(uri: string, options: OptionType): Promise<Response> {
  if (options.useUpload) {
    return uploadFetch(uri, options);
  }

  return fetch(uri, options);
}

const links = {
  parseQueryForTimezone: () =>
    new ApolloLink((operation, forward) =>
      forward ? forward(timeVariableToTimezone(operation)) : null
    ),
  namedLink: () =>
    new ApolloLink((operation, forward) => {
      const contextUrl = operation.getContext().urlType;
      const currentUri =
        contextUrl === linkTypes.builder
          ? builder_uri
          : contextUrl === linkTypes.accounts
            ? accounts_uri
            : commerce_uri;

      if (import.meta.env.NODE_ENV === 'development') {
        operation.setContext(() => ({
          uri: `${currentUri}?${operation.operationName}`
        }));
      }

      return forward ? forward(operation) : null;
    }),

  auth: () =>
    new ApolloLink((operation, forward) => {
      operation.setContext(() => ({
        headers: {
          ...customHeaders,
          ...(!operation.getContext().ignoreAnalyticsHeader &&
            getAnalyticsHeaders()),
          'Remote-Host': `${
            location.pathname.split('/')[1]
          }.${VITE_APP_REMOTE_HOST}`,
          authorization: `Bearer ${localStorage.getItem('token')}`,
          ...(operation.getContext()[CLEAR_LAYOUT_CACHE_KEY]
            ? { 'CLEAR-LAYOUT-CACHE': true }
            : {}),
          ...(operation.getContext().locale
            ? { 'x-current-language-code': operation.getContext().locale }
            : {}),
          ...(operation.getContext().headers || {})
        }
      }));

      if (
        operation.getContext().permissionId &&
        !permission().includes(operation.getContext().permissionId)
      ) {
        return null;
      }

      return forward(operation)?.map(data => {
        if (operation.query.definitions[0].operation) {
          if (
            operation.query.definitions[0]?.operation === 'mutation' &&
            !data.errors?.length
          ) {
            const context = operation.getContext();

            HapticHelper.triggerVibrationEvent(
              HapticStyleNameEnum.ImpactMedium
            );

            if (!context.hideMessages) {
              SystemMessage.success(
                i18n.t(
                  `mutation:${
                    context.customMessage || operation.operationName
                  }`,
                  'Changes have been saved'
                )
              );
            }
          }
        }

        return data;
      });
    }),

  retry: () =>
    new RetryLink({
      delay: {
        initial: 2000,
        max: Infinity,
        jitter: true
      },
      attempts: {
        max: 10,
        retryIf: error => {
          if (
            isEqual(error?.response?.status, ERROR_CODES.SERVICE_UNAVAILABLE)
          ) {
            return false;
          }

          return !!error;
        }
      }
    }),

  error: () =>
    onError(({ graphQLErrors, networkError, operation }) => {
      const context = operation.getContext();

      if (graphQLErrors) {
        for (const { message, locations, path, extensions } of graphQLErrors) {
          if (extensions?.category === 'authentication') {
            location.href = `${VITE_APP_SSO_URL}/callback?target=dashboard&returnUrl=${location.pathname}`;
            localStorage.clear();
          } else if (
            extensions?.category !== 'validation' &&
            extensions?.category !== 'forbidden' &&
            !context.hideMessages &&
            !ERROR_CODES_TO_SKIP_GLOBAL_HANDLING.includes(extensions?.code)
          ) {
            const msg = message
              ? `remoteValidation.${message}`
              : operation.operationName;

            SystemMessage.error(i18n.t(`errors:${msg}`));
          } else if (extensions?.category === 'forbidden') {
            location.href = `/forbidden`;
          } else if (
            extensions?.category === 'validation' &&
            operation.operationName === 'mediaWithFolders'
          ) {
            const { validations } = extensions;

            for (const fieldKey in validations) {
              // eslint-disable-next-line no-prototype-builtins
              if (validations.hasOwnProperty(fieldKey)) {
                const validationArr: { rule: string; placeholders: {} | [] }[] =
                  validations[fieldKey];

                validationArr.forEach(v => {
                  const placeholders = v.placeholders || {};

                  SystemMessage.error(
                    i18n.t(`errors:${v.rule}`, {
                      attribute: fieldKey,
                      ...placeholders
                    })
                  );
                });
              }
            }
          }

          // eslint-disable-next-line no-console
          console.error(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          );
        }
      }

      if (networkError) {
        // eslint-disable-next-line no-console
        console.log(`[Network error]: ${networkError}`);

        if (typeof window !== 'undefined' && !window.navigator.onLine) {
          i18n.t('Sorry, your browser is offline.');
        } else {
          i18n.t('Some other network error occurred.');
        }
      }
    }),

  builder: () =>
    new HttpLink({
      uri: builder_uri
    }),

  file: () =>
    createUploadLink({
      uri: commerce_uri
    }),

  builderFile: () => {
    return createUploadLink({
      fetch: (u, o) => {
        return customFetch(builder_uri, o);
      },
      uri: builder_uri
    });
  },

  accounts: () =>
    new HttpLink({
      uri: accounts_uri
    }),

  commerce: () =>
    new HttpLink({
      uri: commerce_uri
    }),
  appstore: () => new RestLink({ uri: appstore_uri }),
  accountsRest: () => new RestLink({ uri: accounts_rest_uri }),
  webhook: () => new RestLink({ uri: webhook_uri })
};

export enum linkTypes {
  auth = 'auth',
  file = 'file',
  accounts = 'accounts',
  commerce = 'commerce',
  error = 'error',
  retry = 'retry',
  builder = 'builder',
  builderFile = 'builderFile',
  appstore = 'appstore',
  accountsRest = 'accountsRest',
  webhook = 'webhook'
}

const findLink = () => {
  return ApolloLink.split(
    op => op.getContext().urlType === linkTypes.builder,
    links.builder(),
    ApolloLink.split(
      op => op.getContext().urlType === linkTypes.accounts,
      links.accounts(),
      ApolloLink.split(
        op => op.getContext().urlType === linkTypes.accountsRest,
        links.accountsRest(),
        ApolloLink.split(
          op => op.getContext().urlType === linkTypes.webhook,
          links.webhook(),
          ApolloLink.split(
            op => op.getContext().urlType === linkTypes.file,
            links.file(),
            ApolloLink.split(
              op => op.getContext().urlType === linkTypes.builderFile,
              links.builderFile(),
              ApolloLink.split(
                op => op.getContext().urlType === linkTypes.appstore,
                links.appstore(),
                links.commerce()
              )
            )
          )
        )
      )
    )
  );
};

export const link = from([
  links.namedLink(),
  links.parseQueryForTimezone(),
  links.auth(),
  links.error(),
  links.retry(),
  findLink()
]);
