import { ReactNode } from 'react';

export interface PermissionsRouteResponsibleContainerProps {
  routeAlias: RouteAliases;
  children: ReactNode;
}

export enum RouteAliases {
  PROJECTS_PAGE = 'projects',
  DASHBOARD_PAGE = 'dashboard',
  ORDERS_PAGE = 'orders',
  ABANDONED_CHECKOUT_PAGE = 'abandonedCheckout',
  EVENTS_ABANDONED_CHECKOUT_PAGE = 'eventsAbandonedCheckout',
  BOOKINGS_ABANDONED_CHECKOUT_PAGE = 'bookingsAbandonedCheckout',
  RENTALS_ABANDONED_CHECKOUT_PAGE = 'rentalsAbandonedCheckout',
  ORDER_DETAILS_PAGE = 'orderDetails',
  ATTRIBUTES_PAGE = 'attributes',
  CATEGORIES_PAGE = 'categories',
  ADD_PRODUCT_AUTOMATIC_CATEGORIES_PAGE = 'addAutomaticCategories',
  ADD_PRODUCT_MANUAL_CATEGORIES_PAGE = 'addManualCategories',
  EDIT_PRODUCT_CATEGORIES_PAGE = 'editCategories',
  CUSTOMER_PREVIEW_PAGE = 'customerPreview',
  CUSTOMER_OVERVIEW_PAGE = 'customersOverview',
  CUSTOMER_GROUPS_PAGE = 'customersGroups',
  FORM_SUBMISSIONS = 'formSubmissions',
  FORM_SUBMISSION = 'formSubmission',
  CUSTOMER_REVIEWS_PAGE = 'customersReviews',
  ANALYTICS_OVERVIEW_PAGE = 'analyticsOverview',
  DISCOUNT_LIST_PAGE = 'discountsList',
  CREATE_AUTOMATIC_DISCOUNT = 'createAutomaticDiscount',
  CREATE_MANUAL_DISCOUNT = 'createManualDiscount',
  UPDATE_AUTOMATIC_DISCOUNT = 'updateAutomaticDiscount',
  UPDATE_MANUAL_DISCOUNT = 'updateManualDiscount',
  SHIPPING_METHODS_PAGE = 'ShippingMethods',
  SHIPPING_DELIVERY_PAGE = 'ShippingDelivery',
  CREATE_SHIPPING_PROFILE = 'CreateShippingProfile',
  CREATE_SELF_PICKUP = 'CreateSelfPickup',
  SHIPPING_METHOD_PAGE = 'ShippingMethod',
  TAXES_PAGE = 'taxes',
  CHECKOUT_PAGE = 'checkout',
  LANGUAGES_PAGE = 'languages',
  DOMAINS_PAGE = 'domains',
  BILLING_PAGE = 'billing',
  PRODUCTS_PAGE = 'products',
  ADD_PHYSICAL_PRODUCT_PAGE = 'addPhysicalProduct',
  EDIT_PHYSICAL_PRODUCT_PAGE = 'editPhysicalProduct',
  ADD_SUBSCRIPTION_PRODUCT_PAGE = 'addSubscriptionProduct',
  EDIT_SUBSCRIPTION_PRODUCT_PAGE = 'editSubscriptionProduct',
  ADD_DIGITAL_PRODUCT_PAGE = 'addDigitalProduct',
  EDIT_DIGITAL_PRODUCT_PAGE = 'editDigitalProduct',
  ADD_APPOINTMENT_PRODUCT_PAGE = 'addAppointmentProduct',
  EDIT_APPOINTMENT_PRODUCT_PAGE = 'editAppointmentProductEdit',
  RENTALS_PAGE = 'rentalsPage',
  RENTAL_ORDERS_PAGE = 'rentalOrders',
  ADD_RENTAL_PRODUCT_PAGE = 'addRentalProduct',
  EDIT_RENTAL_PRODUCT_PAGE = 'editRentalProduct',
  ADD_TABLE_PRODUCT_PAGE = 'addTableProductPage',
  EDIT_TABLE_PRODUCT_PAGE = 'editTableProductPage',
  ADD_GIFT_CARD_PRODUCT_PAGE = 'addGiftCardProductPage',
  EDIT_GIFT_CARD_PRODUCT_PAGE = 'editGiftCardProductPage',
  PRODUCT_FAMILY_CREATION_PAGE = 'productFamilyCreation',
  PRODUCT_FAMILY_EDIT_PAGE = 'productFamilyEdit',
  PRODUCT_FAMILY_PAGE = 'productFamily',
  SETTINGS_PAGE = 'settings',
  PROJECT_SETUP_PAGE = 'projectSetup',
  PAYMENTS_PAGE = 'payments',
  OTHER_PAYMENTS_PAGE = 'otherPayments',
  SINGLE_PAYMENTS_PAGE = 'singlePayments',
  NOTIFICATIONS_PAGE = 'notifications',
  PREVIEW_PAGE = 'preview',
  TEAM_PAGE = 'team',
  INVITE_MEMBER_PAGE = 'inviteMember',
  LOCATIONS_PAGE = 'locations',
  ACTIVITY_LOG_PAGE = 'activityLog',
  CODE_INJECTION = 'codeInjection',
  COLLECTIONS = 'collections',
  ADD_COLLECTION = 'addCollection',
  EDIT_COLLECTION = 'editCollection',
  COLLECTION_ITEMS = 'collectionItems',
  DELIVERY_TIME_SLOTS = 'deliveryTimeSlots',
  LEGAL_DOCS_PAGE = 'legalDocsPage',
  LEGAL_DOC_PAGE = 'legalDocPage',
  TIME_SLOTS = 'timeSlots',
  COLLECTION_CATEGORIES = 'collectionCategories',
  CREATE_COLLECTION_AUTOMATIC_CATEGORY = 'collectionAutomaticCategoryCreate',
  CREATE_COLLECTION_MANUAL_CATEGORY = 'collectionManualCategoryCreate',
  EDIT_COLLECTION_CATEGORY = 'collectionCategoryEdit',
  FORM_FIELDS = 'formFields',
  ADD_COLLECTION_ITEM = 'addCollectionItem',
  EDIT_COLLECTION_ITEM = 'editCollectionItem',
  SALES_CHANNELS_PAGE = 'channels',
  EVENTS = 'events',
  ADD_EVENT = 'addEvent',
  EDIT_EVENT = 'editEvent',
  ATTENDEES_PAGE = 'attendees',
  SINGLE_ATENDEES_PAGE = 'singleAttendees',
  APPS_PAGE = 'apps',
  RESERVATIONS_PAGE = 'reservationsPage',
  RESERVATION_DETAILS_PAGE = 'reservationDetails',
  RENTAL_ORDERS_DETAILS_PAGE = 'rentalOrdersDetails',
  RENTAL_ORDER_DETAILS_PAGE = 'rentalOrderDetails',
  BOOKINGS_PAGE = 'bookingsPage',
  CREATE_BOOKING_PAGE = 'createBookingPage',
  EDIT_BOOKING_PAGE = 'editBookingPage',
  BOOKING_CATEGORIES = 'bookingCategories',
  RENTAL_CATEGORIES = 'rentalCategories',
  CREATE_BOOKING_AUTOMATIC_CATEGORY = 'createBookingAutomaticCategory',
  CREATE_BOOKING_MANUAL_CATEGORY = 'createBookingManualCategory',
  EDIT_BOOKING_CATEGORY = 'editBookingCategory',
  CREATE_RENTAL_AUTOMATIC_CATEGORY = 'createRentalAutomaticCategory',
  CREATE_RENTAL_MANUAL_CATEGORY = 'createRentalManualCategory',
  EDIT_RENTAL_CATEGORY = 'editRentalCategory',
  RENTAL_TYPES_PAGE = 'rentalTypes',
  ADD_RENTAL_TYPE = 'addRentalType',
  EDIT_RENTAL_TYPE = 'editRentalType',
  EVENT_CATEGORIES = 'eventCategory',
  CREATE_EVENT_AUTOMATIC_CATEGORY = 'createEventAutomaticCategory',
  CREATE_EVENT_MANUAL_CATEGORY = 'createEventManualCategory',
  EDIT_EVENT_CATEGORY = 'editEventCategory',
  BOOKING_TYPES_PAGE = 'bookingTypesPage',
  BOOKING_TYPE_CREATION_PAGE = 'bookingTypeCreationPage',
  BOOKING_TYPE_EDIT_PAGE = 'bookingTypeEditPage',
  EVENT_TYPES_PAGE = 'eventTypesPage',
  EVENT_TYPE_CREATION_PAGE = 'eventTypeCreationPage',
  EVENT_TYPE_EDIT_PAGE = 'eventTypeEditPage'
}
