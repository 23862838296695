import React, {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useState
} from 'react';
import { useKeyboardShortcuts } from 'hooks';
import { getCommonKeymap, SystemMessage } from 'helpers';
import { useNavigate, To, useBlocker, useLocation } from 'react-router-dom';
import { StateDispatcher } from 'core/globalTypes';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import useCheckPermissions from 'providers/PermissionAndLimitationProvider/hooks/useCheckPermissions';
import { Actions } from 'providers/PermissionAndLimitationProvider';
import { App } from 'antd';
import {
  createDataTestAttribute,
  modalConfirmRenderWithDataTest
} from 'helpers/automationHelpers';
import { DataTestAttributes } from 'helpers/automationHelpers/types';

const { useApp } = App;

const { See, Manage } = Actions;

const NavigationContext = createContext<{
  shouldShowConfirm: boolean;
  setShouldShowConfirm: StateDispatcher<boolean>;
}>({
  setShouldShowConfirm: () => {},
  shouldShowConfirm: false
});

interface Props {
  children: ReactNode;
}

export const useNavigationContext = () => useContext(NavigationContext);

const NavigationProvider: FC<Props> = ({ children }) => {
  const { t } = useTranslation(['common', 'products']);
  const { modal } = useApp();
  const navigate = useNavigate();
  const [shouldShowConfirm, setShouldShowConfirm] = useState<boolean>(false);
  const location = useLocation();
  const blocker = useBlocker(shouldShowConfirm);

  const permissions = useCheckPermissions([
    See.Orders,
    See.OwnOrders,
    See.Products,
    Manage.OwnProducts,
    See.Analytics,
    See.Customers,
    See.Discounts,
    See.ActivityLog,
    See.Settings
  ]);

  useEffect(() => {
    if (blocker.state === 'blocked') {
      if (
        location.pathname === blocker.location.pathname ||
        blocker.location.state?.blockNavigate === false
      ) {
        blocker.proceed();
        window.history.replaceState({}, '');
      } else {
        confirmNavigate();
      }
    }
  }, [blocker.state]);

  const confirmNavigate = () => {
    if (shouldShowConfirm) {
      modal.confirm({
        title: t('products:products.unsavedChanges'),
        icon: <ExclamationCircleOutlined />,
        content: t('products:products.notSavedChanges'),
        okText: t('common:confirm'),
        cancelText: t('common:cancel'),
        onOk() {
          blocker.proceed?.();
          setShouldShowConfirm(false);
          SystemMessage.warning(
            t('changesCancelled', 'Your changes were cancelled')
          );
        },
        onCancel() {
          blocker.reset?.();
        },
        type: 'warning',
        cancelButtonProps: {
          'data-test': createDataTestAttribute({
            dataTestAttribute: DataTestAttributes.Popup,
            prefix: `cancel-${DataTestAttributes.Button}`
          })
        },
        okButtonProps: {
          danger: true,
          'data-test': createDataTestAttribute({
            dataTestAttribute: DataTestAttributes.Popup,
            prefix: `confirm-${DataTestAttributes.Button}`
          })
        },
        modalRender: modalConfirmRenderWithDataTest,
        centered: true
      });
    }
  };

  const keyboardNavigate = (
    to: To,
    { I = '', Or = '' }: { I: string; Or?: string }
  ) => {
    if (permissions[I] || permissions[Or]) {
      navigate(to);
    }
  };

  useKeyboardShortcuts(getCommonKeymap(keyboardNavigate));

  return (
    <NavigationContext.Provider
      value={{
        shouldShowConfirm,
        setShouldShowConfirm
      }}
    >
      {children}
    </NavigationContext.Provider>
  );
};

export default NavigationProvider;
