import { FC, useState, ReactNode, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Pagination, Row, App } from 'antd';
import { linkTypes } from 'apollo/link';
import {
  Project,
  useDeleteProjectMutation,
  useLeaveProjectMutation
} from 'generatedHooks/accounts/generated';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
import qs from 'qs';
import { useHistory } from 'hooks';
import { SystemMessage } from 'helpers';
import {
  createDataTestAttribute,
  modalConfirmRenderWithDataTest
} from 'helpers/automationHelpers';
import { DataTestAttributes } from 'helpers/automationHelpers/types';

import Filter from 'pages/projects/Web/componets/Filter';
import ProjectList from './componets/ProjectList';
import DeleteProjectModal from 'pages/projects/Web/componets/DeleteProjectModal';
import styles from './styles.module.less';
import { Typography } from 'components/basic';
import NewProjectButton from 'pages/projects/Web/componets/NewProjectButton';
import { PROJECTS_PAGE_SIZE_OPTIONS } from '../constants';
import { ShowElement } from 'components/shared';
import NotificationSettingsModal from '../components/NotificationSettingsModal';

const { UcHeading } = Typography;
const { useApp } = App;
interface Props {
  data: any;
  pageSize: number;
  page: number;
  loading: boolean;
  projects: Array<Project>;
  searchValue: string | undefined;
  setSearchValue: (value: string) => void;
  onPaginationChange: (page: number, count?: number | undefined) => void;
}

type DeleteProjectModalOptions = {
  modalData?: object;
  modalEditMode?: boolean;
  modalTitle?: ReactNode;
  okText?: string;
  cancelText?: string;
  centered?: boolean;
  okButtonProps?: object;
  onCancel?: any;
  onDeleteProject?: (password: string) => Promise<boolean | void>;
};

const Web: FC<Props> = ({
  data,
  page,
  loading,
  projects,
  pageSize,
  onPaginationChange
}) => {
  const { modal } = useApp();
  const [notificationSettingsModalProps, setNotificationSettingsModalProps] =
    useState({
      projectId: '',
      projectClientName: '',
      open: false
    });

  const [deleteProjModalVisible, setDeleteProjModalVisible] =
    useState<boolean>(false);

  const [deleteProjModalOptions, setDeleteProjModalOptions] =
    useState<DeleteProjectModalOptions>({});

  const { resetQuery } = useHistory();

  const { t } = useTranslation('projects');

  const [deleteProject, { loading: deleteProjectLoading }] =
    useDeleteProjectMutation();

  const [leaveProject, { loading: leaveProjectLoading }] =
    useLeaveProjectMutation();

  const location = useLocation();

  const firstVisit = qs.parse(location.search, {
    ignoreQueryPrefix: true
  })?.firstVisit;

  const onCancelDeleteProjectModal = () => {
    setDeleteProjModalVisible(false);
    setDeleteProjModalOptions({});
  };

  const deleteProjectHandler = (id: string, projectName: string) => {
    const modalOptions: DeleteProjectModalOptions = {
      modalTitle: t('projects:modalTitleDelete', 'Delete Project', {
        projectName
      }),
      okText: t('common:delete', 'Delete'),
      cancelText: t('common:cancel', 'Cancel'),
      centered: true,
      okButtonProps: {
        danger: true
      },
      onDeleteProject: async (password: string) => {
        try {
          await deleteProject({
            variables: { input: { id, password } },
            context: { urlType: linkTypes.accounts, hideMessages: true },
            update(cache) {
              cache.modify({
                fields: {
                  projects: () => {}
                }
              });
            }
          });

          onCancelDeleteProjectModal();
        } catch (error: any) {
          throw new Error(error.message);
        }
      },
      onCancel: onCancelDeleteProjectModal
    };

    setDeleteProjModalVisible(true);
    setDeleteProjModalOptions(modalOptions);
  };

  const leaveProjectConfirmationHandler = (
    projectId: string,
    projectName: string
  ) => {
    modal.confirm({
      title: (
        <Trans
          i18nKey="projects:modalTitleLeave"
          values={{
            projectName,
            strong: <b />
          }}
        />
      ),
      icon: <ExclamationCircleOutlined />,
      cancelButtonProps: {
        'data-test': createDataTestAttribute({
          dataTestAttribute: DataTestAttributes.Popup,
          prefix: `cancel-${DataTestAttributes.Button}`
        })
      },
      okButtonProps: {
        danger: true,
        loading: leaveProjectLoading,
        'data-test': createDataTestAttribute({
          dataTestAttribute: DataTestAttributes.Popup,
          prefix: `confirm-${DataTestAttributes.Button}`
        })
      },
      modalRender: modalConfirmRenderWithDataTest,
      centered: true,
      okText: t('leave'),
      onOk: async () => {
        try {
          await leaveProject({
            variables: { input: { projectId } },
            context: { urlType: linkTypes.accounts },
            update(cache) {
              cache.modify({
                fields: {
                  projects: () => {}
                }
              });
            }
          });
        } catch (error: any) {
          console.error(error);
        }
      }
    });
  };

  useEffect(() => {
    if (firstVisit) {
      resetQuery('firstVisit');
      SystemMessage.success(
        t(
          'projects:verificationMessage',
          'Your account has been successfully verified.'
        )
      );
    }
  }, []);

  return (
    <>
      <DeleteProjectModal
        loading={deleteProjectLoading}
        visible={deleteProjModalVisible}
        {...deleteProjModalOptions}
      />

      <Row
        align="middle"
        justify="space-between"
        className={`${styles.wFull} ${styles.my_project_heading}`}
      >
        <UcHeading level={2} defaultMargin={false}>
          {t('projects')}
        </UcHeading>
        <NewProjectButton />
      </Row>

      <Filter />

      <div
        className={
          projects.length > 0
            ? styles.projectsWrapper
            : styles.projectWrapperEmpty
        }
      >
        <ProjectList
          loading={loading}
          projects={projects}
          deleteProjectHandler={deleteProjectHandler}
          leaveProject={leaveProjectConfirmationHandler}
          openNotificationsSettingsModal={(projectId, projectClientName) =>
            setNotificationSettingsModalProps({
              open: true,
              projectId,
              projectClientName
            })
          }
        />
        <div className={styles.my_project_pagination}>
          <ShowElement isShow={!loading}>
            <Pagination
              size="small"
              current={page}
              pageSize={pageSize}
              pageSizeOptions={PROJECTS_PAGE_SIZE_OPTIONS}
              onChange={onPaginationChange}
              total={data?.projects?.paginatorInfo.total || 0}
              showSizeChanger
            />
          </ShowElement>
        </div>
      </div>
      <NotificationSettingsModal
        open={notificationSettingsModalProps.open}
        projectId={notificationSettingsModalProps.projectId}
        projectClientName={notificationSettingsModalProps.projectClientName}
        onClose={() =>
          setNotificationSettingsModalProps({
            open: false,
            projectId: '',
            projectClientName: ''
          })
        }
      />
    </>
  );
};

export default Web;
