import { useTranslation } from 'react-i18next';

import { ImportCompletedFolderIcon } from 'components/shared/SVG';
import StepContentHeading from 'components/global/ImportModal/components/StepContentHeading';
import styles from './styles.module.less';
import {
  IImportReportData,
  ImportableEntity
} from 'components/global/ImportModal/types';
import ImportReport from './components/ImportReport';

type Props = {
  entityType: ImportableEntity;
  importReport: IImportReportData | null | undefined;
};

const FinishStep: React.FC<Props> = ({ entityType, importReport }) => {
  const { t } = useTranslation('importModal');

  return (
    <div className={styles.wrapper}>
      <StepContentHeading
        title={t('importCompleted')}
        description={t('importCompletedDescription')}
      />
      <div className={styles.content}>
        <ImportCompletedFolderIcon />
        <ImportReport entityType={entityType} importReport={importReport} />
      </div>
    </div>
  );
};

export default FinishStep;
