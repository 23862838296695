import { Component } from 'react';
import { Result } from 'antd';

import { ErrorBoundaryProps, State } from './types';
import { Button } from 'components/basic';
import styles from './styles.module.less';

export default class ErrorBoundary extends Component<
  ErrorBoundaryProps,
  State
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error: any) {
    return { hasError: true, error };
  }

  componentDidCatch(error: any, errorInfo: any) {
    console.log(error, errorInfo);
    this.setState({
      hasError: true
    });
  }

  render() {
    const { error, hasError } = this.state;

    if (hasError) {
      return (
        <Result
          className={styles.result}
          status="warning"
          title="Oops, something went wrong"
          subTitle={
            error.name === 'ChunkLoadError'
              ? 'This application has been updated, please refresh your browser to see the latest content.'
              : 'An error has occurred, please try again.'
          }
          extra={[
            <Button
              type="primary"
              key="reload"
              onClick={() => {
                //@ts-ignore
                window.location.reload(true);
              }}
            >
              Reload
            </Button>
          ]}
        />
      );
    }

    return this.props.children;
  }
}
