import { ProductType } from 'pages/products/productFamily/types';

export enum StatusValues {
  Draft,
  Active
}

const productFilterKey = {
  [ProductType.PHYSICAL]: ['simple', 'configurable'],
  [ProductType.DIGITAL]: ['downloadable'],
  [ProductType.SUBSCRIPTION]: ['subscription'],
  [ProductType.BOOKING_APPOINTMENT]: ['booking_appointment'],
  [ProductType.BOOKING_RESERVATION]: ['booking_reservation'],
  [ProductType.BOOKING_RENTAL]: ['booking_rental'],
  [ProductType.BOOKING_EVENT]: ['booking_event'],
  [ProductType.BOOKING_TABLE]: ['booking_table'],
  [ProductType.GIFT_CARD]: ['gift_card']
};

/* Creating a map of product types to their corresponding filter keys. */
const productTypeSelectValue = new Map([
  [productFilterKey[ProductType.PHYSICAL], ProductType.PHYSICAL],
  [productFilterKey[ProductType.DIGITAL], ProductType.DIGITAL],
  [productFilterKey[ProductType.SUBSCRIPTION], ProductType.SUBSCRIPTION],
  [
    productFilterKey[ProductType.BOOKING_APPOINTMENT],
    ProductType.BOOKING_APPOINTMENT
  ],
  [productFilterKey[ProductType.BOOKING_RENTAL], ProductType.BOOKING_RENTAL],
  [productFilterKey[ProductType.BOOKING_EVENT], ProductType.BOOKING_EVENT],
  [productFilterKey[ProductType.BOOKING_TABLE], ProductType.BOOKING_TABLE]
]);

const productVisibilityType = {
  all: 'all',
  onlyInvisible: 'onlyInvisible',
  includeInvisible: 'includeInvisible'
};

const productVisibilityTypes = (t: Function, isEventType: boolean) => [
  {
    label: isEventType
      ? t('products:filters.allEvents', 'All Events')
      : t('products:filters.allProducts', 'All Products'),
    value: null
  },
  {
    label: t('products:filters.visible', 'Visible'),
    value: 'visible'
  },
  {
    label: t('products:filters.invisible', 'Invisible'),
    value: 'invisible'
  }
];

const productTypes = (t: Function) => [
  {
    name: t('products:types.physical', 'Physical Product'),
    id: ProductType.PHYSICAL
  },
  {
    name: t('products:types.digital', 'Digital Product'),
    id: ProductType.DIGITAL
  },
  {
    name: t('products:types.booking_appointment', 'Appointment Product'),
    id: ProductType.BOOKING_APPOINTMENT
  },
  {
    name: t('products:types.booking_table', 'Table Product'),
    id: ProductType.BOOKING_TABLE
  },
  {
    name: t('products:types.gift_card', 'Gift Card Product'),
    id: ProductType.GIFT_CARD
  }
];

const statusList = (t: Function) => [
  {
    name: t('common:all', 'All'),
    id: null
  },
  {
    name: t('common:active', 'Active'),
    id: StatusValues.Active
  },
  {
    name: t('common:draft', 'Draft'),
    id: StatusValues.Draft
  }
];

const attributesValuesInitialState = {
  ids: [],
  data: []
};

export {
  statusList,
  productTypes,
  productFilterKey,
  productVisibilityType,
  productTypeSelectValue,
  productVisibilityTypes,
  attributesValuesInitialState
};
