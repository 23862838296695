import {
  AttributeSwatchType,
  AttributeType
} from 'generatedHooks/commerce/generated';

import { ToCurrency } from 'hooks/useCurrency';

export type Option = {
  optionId?: string;
  position?: number;
  adminName: string;
  swatchValue: string;
  price?: number;
  isPreselected?: boolean;
  unsaved?: boolean;
  translations: {
    code: string;
    label: string;
  }[];
};

export enum OptionField {
  AdminName = 'adminName',
  SwatchValue = 'swatchValue',
  Price = 'price',
  IsPreselected = 'isPreselected'
}

type OptionsTranslations = {
  code: string;
  label: string;
};

export type Options = {
  optionId?: string;
  position?: number;
  adminName: string;
  price?: number;
  isPreselected?: boolean;
  swatchValue: string;
  translations: OptionsTranslations[];
  unsaved?: boolean;
};

export type IOptionItemType = {
  index: number;
  item: Option;
  handleChange: (
    e: string | number | boolean,
    index: number,
    name: OptionField
  ) => void;
  handleDelete: (index: number) => void;
  swatchType: AttributeSwatchType | undefined;
  selectedAttributeType: AttributeType;
  isPurchaseRequired: boolean;
  isMultiselectAddon: boolean;
  activeKey: number | string;
  locale: string | undefined;
  setActiveKey: Function;
  disabled: boolean;
  toCurrency: ToCurrency;
  totalPreselectedCount: number;
  preselectedOptionsIndexes: number[];
};

export interface IAddAttributeModalRightColumnProps {
  options: Options[];
  attributeId?: string;
  resetOptions: Function;
  reorderOptions: Function;
  handleSetOptions: () => void;
  handleChangeOptions: (
    e: string | number | boolean,
    index: number,
    name: OptionField
  ) => void;
  handleDeleteOption: (index: number) => void;
  locale: string | undefined;
  disabled: boolean;
}

export enum AttributeOptions {
  IS_CONFIGURABLE = 'isConfigurable',
  IS_REQUIRED = 'isRequired',
  IS_FILTERABLE = 'isFilterable',
  IS_COMPARABLE = 'isComparable',
  VALUE_PER_CHANNEL = 'valuePerChannel',
  IS_UNIQUE = 'isUnique',
  IS_VISIBLE_ON_FRONT = 'isVisibleOnFront',
  VALUE_PER_LOCALE = 'valuePerLocale',
  IS_PURCHASE_REQUIRED = 'isPurchaseRequired'
}

export enum AttributeTypeEnum {
  Attribute,
  FormField
}

export type SubmitArgs = {
  values: any;
  options: Options[];
  configs?: unknown;
};

export type FromPageType = 'product' | 'attributes';

export enum PreselectionModifierConditionType {
  MoreThanOnePreselected = 'moreThanOnePreselected',
  NonePreselected = 'nonePreselected'
}
