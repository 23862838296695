import { Dispatch, ReactNode, ChangeEvent, SetStateAction } from 'react';
import * as React from 'react';
import { Typography, Space, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { KeyValuePair } from 'core/globalTypes';
import { IUnsplashImagesData } from 'packages/Media/StockImages/types';
import Search from 'packages/Media/UIComponents/Search';
import Overlay from 'packages/Media/UIComponents/Overlay';
import LazyImage from 'packages/Media/UIComponents/LazyImage/LazyImage';
import Modal from 'packages/Media/UIComponents/Modal';
import { unsplashImages_unsplashImages_data } from 'packages/Media/__generated__/types';
import Loading from 'packages/Media/Loading';
import { useReactiveVar } from '@apollo/client';
import { isMobileDevice } from 'apollo/cache';

import { ArrowLeftIcon } from 'components/shared/SVG';
import styles from '../styles.module.less';
import { ShowElement } from 'components/shared';

const { Text } = Typography;

type Props = {
  visible: boolean;
  loading: boolean;
  onCancel: () => void;
  search: string | null;
  selectCategories: ReactNode;
  getCurrentPage: () => number;
  selectedImages: KeyValuePair;
  images: IUnsplashImagesData[];
  loadRemoteMediaLoading: boolean;
  onImportImages: () => Promise<void>;
  setDefaultMediaId?: (arg: number | null) => void;
  toggleSelect: (image: IUnsplashImagesData) => void;
  onSearch: (event: ChangeEvent<HTMLInputElement>) => void;
  onScroll: (e: Event | React.UIEvent<HTMLElement, UIEvent>) => void;
  previewImage: unsplashImages_unsplashImages_data | null | undefined;
  setPreviewImage: Dispatch<
    SetStateAction<unsplashImages_unsplashImages_data | null | undefined>
  >;
  onImportImage: (image: unsplashImages_unsplashImages_data) => Promise<void>;
};

function StockImages({
  search,
  images,
  onCancel,
  onSearch,
  visible,
  loading,
  onScroll,
  previewImage,
  toggleSelect,
  onImportImage,
  getCurrentPage,
  selectedImages,
  onImportImages,
  setPreviewImage,
  loadRemoteMediaLoading,
  selectCategories: SelectCategories
}: Props) {
  const { t } = useTranslation(['media', 'common']);
  const isMobile = useReactiveVar(isMobileDevice);

  return (
    <Modal
      title={
        <div className={styles.StockModalHeader}>
          <Space size={24}>
            <span>{t('stockTitle')}</span>
            <ShowElement isShow={!isMobile}>
              <Search
                value={search as string}
                placeholder={t('searchPlaceholder')}
                onChange={onSearch}
                style={{ width: 175 }}
                data-test="freestock-search"
              />
            </ShowElement>
            <ShowElement isShow={!isMobile}>{SelectCategories}</ShowElement>
          </Space>

          <div>
            <Text>{t('stockPoweredBy')}</Text>{' '}
            <a
              target="_blank"
              href="https://unsplash.com/"
              rel="noopener noreferrer"
            >
              Unsplash
            </a>
          </div>
        </div>
      }
      centered
      width="80%"
      footer={previewImage ? null : undefined}
      style={{ maxWidth: '1200px' }}
      styles={{
        body: {
          padding: '19px',
          position: 'relative'
        }
      }}
      okText={
        <span data-test="add-unsplash-button">
          {Object.keys(selectedImages).length === 0
            ? t('selectImages')
            : `${t('addSelection')} (${Object.keys(selectedImages).length})`}
        </span>
      }
      okButtonProps={{
        loading: loadRemoteMediaLoading,
        disabled: Object.keys(selectedImages).length === 0
      }}
      cancelText={t('common:cancel')}
      open={visible}
      onOk={onImportImages}
      onCancel={onCancel}
      destroyOnClose
    >
      <ShowElement isShow={isMobile}>
        <Space size={24} className={styles.search_and_select}>
          <Search
            value={search as string}
            placeholder={t('searchPlaceholder')}
            onChange={onSearch}
            data-test="freestock-search"
          />
          {SelectCategories}
        </Space>
      </ShowElement>
      <div className={styles.ImagesGrid} onScroll={onScroll}>
        <Loading visible={loading} />
        {(!loading || getCurrentPage() > 1) &&
          images.map((image: unsplashImages_unsplashImages_data) => {
            return (
              <div className={styles.Item} data-test="grid-item" key={image.id}>
                <img src={image.thumbUrl} alt={image.altDescription || ''} />
                <Overlay
                  onClick={() => {
                    toggleSelect(image);
                  }}
                  centerContent={
                    <Button
                      type="primary"
                      size="small"
                      data-test="preview-unsplash"
                      onClick={() => {
                        setPreviewImage(image);
                      }}
                    >
                      {t('common:preview')}
                    </Button>
                  }
                  topLeftContent={
                    <Overlay.BulkSelect
                      data-test="image-select-checkbox"
                      onChange={() => {
                        toggleSelect(image);
                      }}
                      isChecked={Boolean(selectedImages[image.id])}
                    />
                  }
                />
              </div>
            );
          })}

        {previewImage && (
          <div className={styles.Preview}>
            <div className={styles.PreviewImage}>
              <LazyImage
                src={previewImage?.regularUrl}
                alt={previewImage?.altDescription || ''}
                width={previewImage?.width || 0}
                height={previewImage?.height || 0}
              />
              <div className={styles.CancelButton}>
                <Button
                  data-test="back"
                  onClick={() => {
                    setPreviewImage(null);
                  }}
                  icon={<ArrowLeftIcon />}
                >
                  {t('back')}
                </Button>
              </div>
              <div className={styles.AddNewImageButton}>
                <Button
                  data-test="add-image"
                  type="primary"
                  loading={loadRemoteMediaLoading}
                  onClick={async () => {
                    await onImportImage(
                      previewImage as unsplashImages_unsplashImages_data
                    );
                  }}
                >
                  {t('addImage')}
                </Button>
              </div>
            </div>
            <div className={styles.PreviewFooter}>
              <div>
                <img src={previewImage?.profileImageSmall || ''} alt="" />
                <div>
                  <Text>{previewImage?.username}</Text>
                  <Text>@{previewImage?.twitterUsername}</Text>
                </div>
              </div>
              <div>
                <Text>{t('stockTerms')} </Text>
                <a href="https://unsplash.com/terms">
                  Unsplash&apos;s {t('terms')}
                </a>
                . <a href="https://unsplash.com/license">{t('learnMore')}</a>
              </div>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
}

export default StockImages;
