import { TFunction } from 'react-i18next';
import { SortTypes } from 'core/globalTypes';
import { SortOrder } from 'generatedHooks/commerce/generated';
import { ProjectsOrderByColumn } from 'generatedHooks/accounts/generated';
import i18n from 'i18next';

import ImgIcon from 'components/mobile/ImgIcon';
import { RulerIcon } from 'components/shared/SVG';
import { MenuInterface } from './types';

const dropDownActionTypes = {
  leave: 'leave',
  delete: 'delete',
  preview: 'preview',
  duplicate: 'duplicate',
  goToPublicMode: 'goToPublicMode',
  goToVisualEditor: 'goToVisualEditor',
  goToCMS: 'goToCMS',
  notificationSettings: 'notificationSettings'
};

const MENU_ITEMS = (t: TFunction, isMobile?: boolean): Array<MenuInterface> => {
  let actionsData: Array<MenuInterface> = [
    {
      type: 'danger',
      color: '#F5222D',
      label: t('projects:delete'),
      value: dropDownActionTypes.delete,
      icon: <ImgIcon icon="deleteOutlined" paddingType="default" />
    },
    {
      label: t('projects:notificationSettings'),
      value: dropDownActionTypes.notificationSettings,
      icon: <ImgIcon icon="bellBlackOutline" paddingType="default" />
    },
    {
      label: t('menu:publicWebsite', 'Public Website'),
      value: dropDownActionTypes.goToPublicMode,
      icon: <ImgIcon icon="assignment" paddingType="default" />
    }
  ];

  if (isMobile) {
    actionsData = [
      ...actionsData,
      {
        label: t('common:viewProject', 'View Project'),
        value: dropDownActionTypes.preview,
        icon: <ImgIcon icon="eyeOutlined" paddingType="default" />
      }
    ];
  }

  if (!isMobile) {
    actionsData = [
      ...actionsData,
      {
        label: t('menu:goToVisualEditor', 'Visual Editor'),
        value: dropDownActionTypes.goToVisualEditor,
        icon: <RulerIcon color="magenta" />
      },
      {
        label: t('menu:goToCMS', 'CMS'),
        value: dropDownActionTypes.goToCMS,
        icon: <RulerIcon color="magenta" />
      },
      {
        label: t('projects:leave'),
        value: dropDownActionTypes.leave,
        icon: <ImgIcon icon="deleteOutlined" paddingType="default" />
      }
    ];
  }

  return actionsData;
};

export const SORT_DATA_GROUPS = {
  [SortTypes.Az]: {
    column: ProjectsOrderByColumn.Name,
    order: SortOrder.Asc
  },
  [SortTypes.Za]: {
    column: ProjectsOrderByColumn.Name,
    order: SortOrder.Desc
  },
  [SortTypes.Newest]: {
    column: ProjectsOrderByColumn.CreatedAt,
    order: SortOrder.Desc
  },
  [SortTypes.Oldest]: {
    column: ProjectsOrderByColumn.CreatedAt,
    order: SortOrder.Asc
  },
  [SortTypes.RecentlyModified]: {
    column: ProjectsOrderByColumn.UpdatedAt,
    order: SortOrder.Desc
  }
};

export const DEFAULT_SORT_DATA = [
  {
    value: SortTypes.Newest,
    name: i18n.t('common:sortNew', 'Newest')
  },
  {
    value: SortTypes.Oldest,
    name: i18n.t('common:sortOld', 'Oldest')
  },
  {
    value: SortTypes.Az,
    name: i18n.t('common:sortAToZ', 'A-Z')
  },
  {
    value: SortTypes.Za,
    name: i18n.t('common:sortZToA', 'Z-A')
  },
  {
    value: SortTypes.RecentlyModified,
    name: i18n.t('common:recentlyModified', 'Recently modified')
  }
];

const DEFAULT_PAGE_SIZE = 12;
const PROJECTS_PAGE_SIZE_OPTIONS = [12, 20, 50, 100];

export {
  MENU_ITEMS,
  DEFAULT_PAGE_SIZE,
  dropDownActionTypes,
  PROJECTS_PAGE_SIZE_OPTIONS
};
