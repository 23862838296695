import { GetRowKey, TableRowSelection } from 'antd/lib/table/interface';
import { KeyValuePair } from 'core/globalTypes';
import i18n from 'i18next';
import { uniq } from 'lodash';
import { RowSelectionProps } from 'services/bulkActions/types';

interface RowSelectionConfigsArgs<T> extends Omit<RowSelectionProps, 'entity'> {
  onChange: TableRowSelection<T>['onChange'];
}

export const getRowSelectionDefaultConfigs = <T,>({
  onChange,
  setAllRowsSelected,
  setExcludedRowKeys,
  selectedRowKeys,
  setSelectedRowKeys,
  resetSelections
}: RowSelectionConfigsArgs<T>): TableRowSelection<T> => {
  return {
    columnWidth: 56,
    onChange,
    selectedRowKeys,
    renderCell: (value, record, index, originNode) => {
      return <div onClick={e => e.stopPropagation()}>{originNode}</div>;
    },
    selections: [
      {
        key: 'select-all-data',
        text: <>{i18n.t('bulkActions:selectAllItems')}</>,
        onSelect: rowKeys => {
          const keys = rowKeys as string[];

          setAllRowsSelected?.(true);
          setSelectedRowKeys?.(prev => uniq(prev.concat(keys)));
          setExcludedRowKeys?.([]);
        }
      },
      {
        key: 'deselect-all-data',
        text: <>{i18n.t('bulkActions:deselectAllItems')}</>,
        onSelect: resetSelections
      }
    ]
  };
};

export const flatDataSourceKeys = <T extends KeyValuePair>(
  dataSource: T[],
  rowKey: string | number | symbol | GetRowKey<T>
) => {
  const keys: string[] = [];
  const isKeyString = typeof rowKey === 'string';
  const isKeyNumber = typeof rowKey === 'number';
  const isKeySymbol = typeof rowKey === 'symbol';

  const flat = (dataSource: T[]) => {
    dataSource.forEach(item => {
      keys.push(
        isKeyString || isKeyNumber || isKeySymbol
          ? item[rowKey as string]
          : rowKey(item)
      );

      if (item.children?.length) {
        flat(item.children);
      }
    });
  };

  flat(dataSource);

  return keys;
};
