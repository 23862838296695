import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
export type RequireFields<T, K extends keyof T> = Omit<T, K> & { [P in K]-?: NonNullable<T[P]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Array: { input: any; output: any; }
  Date: { input: any; output: any; }
  DateTime: { input: any; output: any; }
  DateTimeTz: { input: any; output: any; }
  Mixed: { input: any; output: any; }
  Upload: { input: any; output: any; }
  Url: { input: any; output: any; }
};

export type AddCustomFontsInput = {
  designPresetId: Scalars['ID']['input'];
  variants: Array<CustomFontsVariantInput>;
};

export type AddGoogleFontsInput = {
  data: Array<GoogleFontsDataInput>;
  designPresetId: Scalars['ID']['input'];
};

export type AttachTagsToMedia = {
  id: Scalars['ID']['input'];
  tags: Array<Scalars['String']['input']>;
  type: MediaType;
};

export type Audio = MediaInterface & {
  __typename?: 'Audio';
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  duration: Scalars['Float']['output'];
  extension?: Maybe<Scalars['String']['output']>;
  filename: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  mimeType: Scalars['String']['output'];
  name: Scalars['String']['output'];
  parent?: Maybe<MediaInterface>;
  parents?: Maybe<Array<MediaInterface>>;
  size: Scalars['Int']['output'];
  tags: Array<Tag>;
  thumbnail?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type: MediaType;
  updatedAt: Scalars['DateTime']['output'];
};

export type Block = {
  __typename?: 'Block';
  category?: Maybe<BlockCategory>;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  global: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  industries?: Maybe<Array<Industry>>;
  ownerId?: Maybe<Scalars['ID']['output']>;
  projectId?: Maybe<Scalars['ID']['output']>;
  schema?: Maybe<Scalars['Array']['output']>;
  thumbnail?: Maybe<Scalars['Url']['output']>;
  title: Scalars['String']['output'];
};

export type BlockCategory = {
  __typename?: 'BlockCategory';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type BlockPaginator = {
  __typename?: 'BlockPaginator';
  data: Array<Block>;
  paginatorInfo: PaginatorInfo;
};

/** List of columns by which blocks can be ordered. */
export enum BlocksOrderByColumn {
  /** Order blocks by category. */
  Category = 'CATEGORY',
  /** Order blocks by creation date. */
  CreatedAt = 'CREATED_AT',
  /** Order blocks by id. */
  Id = 'ID',
  /** Order blocks by title. */
  Title = 'TITLE'
}

export type Breakpoint = {
  __typename?: 'Breakpoint';
  default: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  selected: Scalars['Boolean']['output'];
  width: Scalars['Int']['output'];
};

export type Color = {
  __typename?: 'Color';
  alias: Scalars['String']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  setId: Scalars['ID']['output'];
  type: ColorType;
  value: Scalars['Array']['output'];
};

export type ColorPreferences = {
  __typename?: 'ColorPreferences';
  isActive: Scalars['Boolean']['output'];
  mode: ColorPreferencesMode;
};

export type ColorPreferencesInput = {
  isActive: Scalars['Boolean']['input'];
  mode: ColorPreferencesMode;
};

/** List of available color preferences modes for design preset. */
export enum ColorPreferencesMode {
  /** Auto type. */
  Auto = 'AUTO',
  /** Dark type. */
  Dark = 'DARK',
  /** Light type. */
  Light = 'LIGHT'
}

export type ColorSet = {
  __typename?: 'ColorSet';
  colors?: Maybe<Array<Color>>;
  default: Scalars['Boolean']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  type: ColorSetType;
};

/** List of available color set types. */
export enum ColorSetType {
  /** Complex type of color set. */
  Complex = 'COMPLEX',
  /** Custom type of color set. */
  Custom = 'CUSTOM',
  /** Primitive type of color set. */
  Primitive = 'PRIMITIVE'
}

/** List of available color types. */
export enum ColorType {
  /** Gradient type of color. */
  Gradient = 'GRADIENT',
  /** Solid type of color. */
  Solid = 'SOLID'
}

export type Condition = {
  __typename?: 'Condition';
  params: Scalars['Array']['output'];
  type: Scalars['String']['output'];
};

export type ConditionInput = {
  params: Scalars['Array']['input'];
  type: Scalars['String']['input'];
};

export type Configuration = {
  __typename?: 'Configuration';
  browser: Scalars['String']['output'];
  deviceType: Scalars['String']['output'];
  geographicalInfo?: Maybe<GeographicalInfo>;
  languageInfo?: Maybe<Array<LanguageInfo>>;
  operatingSystem: Scalars['String']['output'];
  projectConfig: ProjectConfiguration;
  settings: Array<Setting>;
};


export type ConfigurationLanguageInfoArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

export type CreateBreakpointInput = {
  width: Scalars['Int']['input'];
};

export type CreateColorInput = {
  designPresetId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  setId: Scalars['ID']['input'];
  type: ColorType;
  value: Scalars['Array']['input'];
};

export type CreateColorSetInput = {
  name: Scalars['String']['input'];
};

export type CreateDesignPresetInput = {
  name: Scalars['String']['input'];
};

export type CreateFolderInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  parentId?: InputMaybe<Scalars['ID']['input']>;
  type: MediaType;
};

export type CreateLanguageInput = {
  code: Scalars['String']['input'];
  country: Scalars['String']['input'];
  designPresetId?: InputMaybe<Scalars['ID']['input']>;
  domainId?: InputMaybe<Scalars['ID']['input']>;
  enforceUnicode: Scalars['Boolean']['input'];
  flag?: InputMaybe<Scalars['ID']['input']>;
  language: Scalars['String']['input'];
  name: Scalars['String']['input'];
  nativeName: Scalars['String']['input'];
  rtl?: InputMaybe<Scalars['Boolean']['input']>;
  slug: Scalars['String']['input'];
  unicodeCharacters?: InputMaybe<Scalars['String']['input']>;
};

export type CreateMediaInput = {
  alt?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  parentId?: InputMaybe<Scalars['ID']['input']>;
  thumbnail?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type: MediaType;
};

export type CreateOverlayInput = {
  alias: Scalars['String']['input'];
  name: Scalars['String']['input'];
  params?: InputMaybe<Scalars['Array']['input']>;
  triggers?: InputMaybe<Array<TriggerInput>>;
};

export type CreatePageInput = {
  alias: Scalars['String']['input'];
  group: PageGroup;
  layoutStructure: PageLayoutStructure;
  name: Scalars['String']['input'];
  parentId?: InputMaybe<Scalars['ID']['input']>;
  previousId?: InputMaybe<Scalars['ID']['input']>;
  status?: InputMaybe<PageStatus>;
  target: PageTarget;
  template?: InputMaybe<Scalars['ID']['input']>;
  type: PageType;
};

export type CreatePageTemplateInput = {
  source: Scalars['ID']['input'];
  thumbnail?: InputMaybe<Scalars['ID']['input']>;
  title: Scalars['String']['input'];
};

export type CreateRedirectionInput = {
  from: Scalars['String']['input'];
  status: RedirectionStatus;
  to: Scalars['Url']['input'];
  type: RedirectionType;
};

export type CreateUiElementInput = {
  content: Scalars['Array']['input'];
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  designPresetId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  type: UiElementType;
};

export type CssVariable = {
  __typename?: 'CssVariable';
  active: Scalars['Boolean']['output'];
  alias: Scalars['String']['output'];
  category?: Maybe<CssVariableCategory>;
  name: Scalars['String']['output'];
  value: Scalars['Array']['output'];
};

/** List of available css variable categories. */
export enum CssVariableCategory {
  /** Border thickness category of css variable. */
  BorderThickness = 'BORDER_THICKNESS',
  /** Border width category of css variable. */
  BorderWidth = 'BORDER_WIDTH',
  /** Gap category of css variable. */
  Gap = 'GAP',
  /** Margin category of css variable. */
  Margin = 'MARGIN',
  /** Padding category of css variable. */
  Padding = 'PADDING',
  /** Radius category of css variable. */
  Radius = 'RADIUS'
}

export type CssVariableDataInput = {
  active: Scalars['Boolean']['input'];
  alias: Scalars['String']['input'];
  category?: InputMaybe<CssVariableCategory>;
  name: Scalars['String']['input'];
  value: Scalars['Array']['input'];
};

export type CustomFont = {
  __typename?: 'CustomFont';
  family: Scalars['String']['output'];
  type: FontType;
  variants: Array<FontVariant>;
};

export type CustomFontsVariantInput = {
  id: Scalars['ID']['input'];
  selected?: InputMaybe<Scalars['Boolean']['input']>;
};

export type DefaultUiElement = {
  __typename?: 'DefaultUiElement';
  content: Scalars['Array']['output'];
  type: UiElementType;
};

export type DeleteBreakpointInput = {
  id: Scalars['ID']['input'];
};

export type DeleteColorInput = {
  id: Scalars['ID']['input'];
};

export type DeleteColorSetInput = {
  id: Scalars['ID']['input'];
};

export type DeleteCssVariableInput = {
  alias: Scalars['String']['input'];
};

export type DeleteCustomFontInput = {
  family: Scalars['String']['input'];
};

export type DeleteCustomFontVariantsInput = {
  ids: Array<Scalars['ID']['input']>;
};

export type DeleteDesignPresetInput = {
  designPresetId: Scalars['ID']['input'];
};

export type DeleteLanguageInput = {
  id: Scalars['ID']['input'];
};

export type DeleteMediaInput = {
  ids: Array<Scalars['ID']['input']>;
  type: MediaType;
};

export type DeletePageInput = {
  ids: Array<Scalars['ID']['input']>;
};

export type DeleteRedirectionInput = {
  id: Scalars['ID']['input'];
};

export type DeleteUiElementInput = {
  id: Scalars['ID']['input'];
};

export type DesignPreset = {
  __typename?: 'DesignPreset';
  allCssVariables: Array<CssVariable>;
  colorPreferences: ColorPreferences;
  colors: Array<Color>;
  cssVariables: Array<CssVariable>;
  darkMode: Scalars['Boolean']['output'];
  default: Scalars['Boolean']['output'];
  firstUiElements: Array<UiElement>;
  fonts: Array<PresetFont>;
  grids?: Maybe<Array<Maybe<Grid>>>;
  id: Scalars['ID']['output'];
  languages?: Maybe<Language>;
  name: Scalars['String']['output'];
  uiElement: UiElement;
  uiElements: Array<UiElement>;
};


export type DesignPresetColorsArgs = {
  setId?: InputMaybe<Scalars['ID']['input']>;
  type?: InputMaybe<ColorType>;
};


export type DesignPresetCssVariablesArgs = {
  category?: InputMaybe<CssVariableCategory>;
};


export type DesignPresetFirstUiElementsArgs = {
  types: Array<UiElementType>;
};


export type DesignPresetUiElementArgs = {
  id: Scalars['ID']['input'];
};


export type DesignPresetUiElementsArgs = {
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  types?: InputMaybe<Array<UiElementType>>;
};

export type DetachTagsFromMedia = {
  id: Scalars['ID']['input'];
  tagIds: Array<Scalars['ID']['input']>;
  type: MediaType;
};

export type Document = MediaInterface & {
  __typename?: 'Document';
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  extension?: Maybe<Scalars['String']['output']>;
  filename: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  mimeType: Scalars['String']['output'];
  name: Scalars['String']['output'];
  parent?: Maybe<MediaInterface>;
  parents?: Maybe<Array<MediaInterface>>;
  size: Scalars['Int']['output'];
  tags: Array<Tag>;
  thumbnail?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type: MediaType;
  updatedAt: Scalars['DateTime']['output'];
};

export type DuplicateColorSetInput = {
  designPresetId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type DuplicateDesignPresetInput = {
  designPresetId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type DuplicateMediaInput = {
  ids: Array<Scalars['ID']['input']>;
  type: MediaType;
};

export type DuplicateUiElementInput = {
  designPresetId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  uiElementId: Scalars['ID']['input'];
};

export type Folder = MediaInterface & {
  __typename?: 'Folder';
  children?: Maybe<Array<Maybe<MediaInterface>>>;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  extension?: Maybe<Scalars['String']['output']>;
  filename: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  mimeType: Scalars['String']['output'];
  name: Scalars['String']['output'];
  parent?: Maybe<MediaInterface>;
  parents?: Maybe<Array<MediaInterface>>;
  size: Scalars['Int']['output'];
  tags: Array<Tag>;
  thumbnail?: Maybe<Scalars['String']['output']>;
  type: MediaType;
  updatedAt: Scalars['DateTime']['output'];
};

/** A paginated list of Folder items. */
export type FolderPaginator = {
  __typename?: 'FolderPaginator';
  /** A list of Folder items. */
  data: Array<Folder>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type FontDetails = {
  __typename?: 'FontDetails';
  name?: Maybe<Scalars['String']['output']>;
  slant?: Maybe<Scalars['Int']['output']>;
  thickness?: Maybe<Scalars['Int']['output']>;
  width?: Maybe<Scalars['Int']['output']>;
};

export type FontPaginator = {
  __typename?: 'FontPaginator';
  data: Array<CustomFont>;
  paginatorInfo: PaginatorInfo;
};

/** List of available font types. */
export enum FontType {
  /** Fonts uploaded by the user. */
  Custom = 'CUSTOM',
  /** Fonts fetched from Google Fonts API. */
  Google = 'GOOGLE'
}

export type FontVariant = {
  __typename?: 'FontVariant';
  filename: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  selected: Scalars['Boolean']['output'];
};

export type GeographicalInfo = {
  __typename?: 'GeographicalInfo';
  city: Scalars['String']['output'];
  country: Scalars['String']['output'];
};

export type GoogleFont = {
  __typename?: 'GoogleFont';
  category: Scalars['String']['output'];
  dateAdded: Scalars['Date']['output'];
  defaultSort: Scalars['Int']['output'];
  family: Scalars['String']['output'];
  fonts: Array<Maybe<FontDetails>>;
  kind?: Maybe<Scalars['String']['output']>;
  lastModified?: Maybe<Scalars['Date']['output']>;
  popularity: Scalars['Int']['output'];
  size: Scalars['Int']['output'];
  subsets?: Maybe<Scalars['Array']['output']>;
  trending: Scalars['Int']['output'];
  type: FontType;
  variants: Array<FontVariant>;
  version?: Maybe<Scalars['String']['output']>;
};

export type GoogleFontVariansInput = {
  filename: Scalars['String']['input'];
  name: Scalars['String']['input'];
  selected: Scalars['Boolean']['input'];
};

export type GoogleFontsDataInput = {
  family: Scalars['String']['input'];
  variants: Array<GoogleFontVariansInput>;
};

export type Grid = {
  __typename?: 'Grid';
  designPreset: DesignPreset;
  horizontal: Scalars['Int']['output'];
  type: GridDimension;
  vertical: Scalars['Int']['output'];
};

/** List of available grid dimensions. */
export enum GridDimension {
  /** The LG size. */
  Lg = 'LG',
  /** The MD size. */
  Md = 'MD',
  /** The SM size. */
  Sm = 'SM',
  /** The XL size. */
  Xl = 'XL',
  /** The XS size. */
  Xs = 'XS',
  /** The XXL size. */
  Xxl = 'XXL'
}

export type Image = MediaInterface & {
  __typename?: 'Image';
  alt?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  extension?: Maybe<Scalars['String']['output']>;
  filename: Scalars['String']['output'];
  height?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  mimeType: Scalars['String']['output'];
  name: Scalars['String']['output'];
  parent?: Maybe<MediaInterface>;
  parents?: Maybe<Array<MediaInterface>>;
  size: Scalars['Int']['output'];
  tags: Array<Tag>;
  thumbnail?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type: MediaType;
  updatedAt: Scalars['DateTime']['output'];
  webPFilename?: Maybe<Scalars['String']['output']>;
  width?: Maybe<Scalars['Int']['output']>;
};

export type Industry = {
  __typename?: 'Industry';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type Language = {
  __typename?: 'Language';
  code: Scalars['String']['output'];
  country: Scalars['String']['output'];
  default: Scalars['Boolean']['output'];
  designPreset?: Maybe<DesignPreset>;
  disabledAt?: Maybe<Scalars['DateTime']['output']>;
  domainId?: Maybe<Scalars['ID']['output']>;
  enforceUnicode: Scalars['Boolean']['output'];
  flag?: Maybe<Scalars['ID']['output']>;
  hidden: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  language: Scalars['String']['output'];
  name: Scalars['String']['output'];
  nativeName: Scalars['String']['output'];
  order: Scalars['Int']['output'];
  rtl: Scalars['Boolean']['output'];
  slug: Scalars['String']['output'];
  unicodeCharacters?: Maybe<Scalars['String']['output']>;
};

/** List of available language detection modes. */
export enum LanguageDetectionMode {
  /** Browser. */
  Browser = 'BROWSER',
  /** Default. */
  Default = 'DEFAULT',
  /** IP. */
  Ip = 'IP',
  /** Ip browser. */
  IpBrowser = 'IP_BROWSER'
}

export type LanguageInfo = {
  __typename?: 'LanguageInfo';
  canonical?: Maybe<Scalars['String']['output']>;
  code: Scalars['String']['output'];
  country: Scalars['String']['output'];
  current?: Maybe<Scalars['Boolean']['output']>;
  default: Scalars['Boolean']['output'];
  designPreset: DesignPreset;
  disabledAt?: Maybe<Scalars['DateTime']['output']>;
  enforceUnicode?: Maybe<Scalars['Boolean']['output']>;
  flag?: Maybe<Scalars['ID']['output']>;
  hidden: Scalars['Boolean']['output'];
  hreflang?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  language: Scalars['String']['output'];
  name: Scalars['String']['output'];
  nativeName?: Maybe<Scalars['String']['output']>;
  path?: Maybe<Scalars['String']['output']>;
  rtl: Scalars['Boolean']['output'];
  slug: Scalars['String']['output'];
  unicodeCharacters?: Maybe<Scalars['String']['output']>;
};

export type Layout = {
  __typename?: 'Layout';
  id: Scalars['ID']['output'];
};

export type LayoutBlockHash = {
  __typename?: 'LayoutBlockHash';
  hash: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

/** LayoutType enum defines fields for layout types. */
export enum LayoutType {
  /** Page/Collection layout type. */
  Body = 'BODY',
  /** Ecommerce shopping cart layout type. */
  Cart = 'CART',
  /** Category layout type collection. */
  Category = 'CATEGORY',
  /** Page layout type. */
  Footer = 'FOOTER',
  /** Page layout type. */
  Header = 'HEADER',
  /** Overlay layout type. */
  Overlay = 'OVERLAY',
  /** Page layout type. */
  Sidebar = 'SIDEBAR'
}

export type LegalDoc = {
  __typename?: 'LegalDoc';
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  type: LegalDocType;
};

export type LegalDocTranslationInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  languageCode: Scalars['String']['input'];
};

/** List of available types for legal docs. */
export enum LegalDocType {
  /** Cookie Policy. */
  CookiePolicy = 'COOKIE_POLICY',
  /** Privacy Policy. */
  PrivacyPolicy = 'PRIVACY_POLICY',
  /** Refund Policy. */
  RefundPolicy = 'REFUND_POLICY',
  /** Shipping Policy. */
  ShippingPolicy = 'SHIPPING_POLICY',
  /** Terms and conditions. */
  TermsAndConditions = 'TERMS_AND_CONDITIONS'
}

/** List of available variables for legal docs. */
export enum LegalDocVariable {
  /** Company Full Address. */
  CompanyFullAddress = 'COMPANY_FULL_ADDRESS',
  /** Company Name. */
  CompanyName = 'COMPANY_NAME',
  /** Company Contact Email. */
  YourEmail = 'YOUR_EMAIL',
  /** Your Website. */
  YourWebsite = 'YOUR_WEBSITE'
}

export type Limitation = {
  __typename?: 'Limitation';
  count: Scalars['Int']['output'];
  entity: LimitationType;
  limit: Scalars['Int']['output'];
  limitReached: Scalars['Boolean']['output'];
};

/** List of available limitation types. */
export enum LimitationType {
  /** Language type. */
  Language = 'LANGUAGE',
  /** Page type. */
  Page = 'PAGE'
}

export type LoadRemoteMediaInput = {
  type: MediaType;
  url: Scalars['Url']['input'];
};

export type MakeDefaultInput = {
  id: Scalars['ID']['input'];
};

export type MediaInterface = {
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  extension?: Maybe<Scalars['String']['output']>;
  filename: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  mimeType: Scalars['String']['output'];
  name: Scalars['String']['output'];
  parent?: Maybe<MediaInterface>;
  parents?: Maybe<Array<MediaInterface>>;
  size: Scalars['Int']['output'];
  tags: Array<Tag>;
  thumbnail?: Maybe<Scalars['String']['output']>;
  type: MediaType;
  updatedAt: Scalars['DateTime']['output'];
};

/** The MediaOrderByColumn enum specifies the columns by which media can be ordered. */
export enum MediaOrderByColumn {
  /** Order media by creation date. */
  CreatedAt = 'CREATED_AT',
  /** Order media by id. */
  Id = 'ID',
  /** Order media by name. */
  Name = 'NAME',
  /** Order media by size. */
  Size = 'SIZE'
}

export type MediaPaginator = {
  __typename?: 'MediaPaginator';
  data: Array<MediaUnion>;
  paginatorInfo: PaginatorInfo;
};

/** List of available media types. */
export enum MediaType {
  /** Type of media audio. */
  Audio = 'AUDIO',
  /** Type of media document. */
  Document = 'DOCUMENT',
  /** Type of media image. */
  Image = 'IMAGE',
  /** Type of media vector. */
  Vector = 'VECTOR',
  /** Type of media video. */
  Video = 'VIDEO'
}

export type MediaUnion = Audio | Document | Folder | Image | Vector | Video;

export type MoveMediaInput = {
  ids: Array<Scalars['ID']['input']>;
  parentId?: InputMaybe<Scalars['ID']['input']>;
};

export type MovePageInput = {
  id: Scalars['ID']['input'];
  parentId?: InputMaybe<Scalars['ID']['input']>;
  previousId?: InputMaybe<Scalars['ID']['input']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addCustomFontsToPreset: Array<PresetFont>;
  addGoogleFontsToPreset: Array<PresetFont>;
  attachTagsToMedia: MediaUnion;
  createBreakpoint: Breakpoint;
  createColor: Color;
  createComplexColorSet: ColorSet;
  createDesignPreset: DesignPreset;
  createFolder: Folder;
  createLanguage: Language;
  createMedia: MediaUnion;
  /** The mutation is intended to create new Overlay. */
  createOverlay: Overlay;
  createPage: Array<Page>;
  createPageTemplate?: Maybe<PageTemplate>;
  createPrimitiveColorSet: ColorSet;
  createRedirection: Redirection;
  createUiElement: UiElement;
  deleteBlock: Scalars['Boolean']['output'];
  deleteBreakpoint: Scalars['Boolean']['output'];
  deleteColor: Scalars['Boolean']['output'];
  deleteColorSet: Scalars['Boolean']['output'];
  deleteCssVariable: Scalars['Boolean']['output'];
  deleteCustomFont: Scalars['Boolean']['output'];
  deleteCustomFontVariants: Scalars['Boolean']['output'];
  deleteDesignPreset: Scalars['Boolean']['output'];
  deleteLanguage: Scalars['Boolean']['output'];
  deleteMedia: Scalars['Boolean']['output'];
  /** The mutation is intended to delete Overlay. */
  deleteOverlay: Scalars['Boolean']['output'];
  deletePage: Array<Page>;
  deleteRedirection: Scalars['Boolean']['output'];
  deleteUiElement: Scalars['Boolean']['output'];
  detachTagsFromMedia: Scalars['Int']['output'];
  duplicateColorSet: ColorSet;
  duplicateDesignPreset: DesignPreset;
  duplicateMedia: Array<MediaUnion>;
  duplicateUiElement: UiElement;
  loadRemoteMedia: UploadedMedia;
  makeDefault: Language;
  moveMedia: Array<Maybe<MediaUnion>>;
  movePage: Array<Page>;
  orderLanguages: Array<Language>;
  removeFontFromPreset: Scalars['Boolean']['output'];
  removePageTemplate?: Maybe<Scalars['Boolean']['output']>;
  renameBlock: Scalars['Array']['output'];
  restoreOrCreateColor: Color;
  setPagePassword?: Maybe<Page>;
  /** The mutation is intended to create or update Script. */
  setScripts: Array<ScriptTranslations>;
  switchFontVariants: Array<PresetFont>;
  updateBlock: Block;
  updateBreakpoint: Breakpoint;
  updateColor: Color;
  updateColorSet: ColorSet;
  updateCssVariables: Array<CssVariable>;
  updateDesignPreset: DesignPreset;
  updateFolder: Folder;
  updateGrid: Grid;
  updateLanguage: Language;
  updateLegalDoc?: Maybe<LegalDoc>;
  updateMedia: MediaUnion;
  /** The mutation is intended to update Overlay. */
  updateOverlay: Overlay;
  updatePage?: Maybe<Page>;
  updatePageTemplate?: Maybe<PageTemplate>;
  updateRedirection: Redirection;
  updateUiElement: UiElement;
  uploadCustomFont: CustomFont;
  uploadMedia: UploadedMedia;
  upsertTranslation: TranslationItem;
};


export type MutationAddCustomFontsToPresetArgs = {
  input: AddCustomFontsInput;
};


export type MutationAddGoogleFontsToPresetArgs = {
  input: AddGoogleFontsInput;
};


export type MutationAttachTagsToMediaArgs = {
  input: AttachTagsToMedia;
};


export type MutationCreateBreakpointArgs = {
  input: CreateBreakpointInput;
};


export type MutationCreateColorArgs = {
  input: CreateColorInput;
};


export type MutationCreateComplexColorSetArgs = {
  input: CreateColorSetInput;
};


export type MutationCreateDesignPresetArgs = {
  input: CreateDesignPresetInput;
};


export type MutationCreateFolderArgs = {
  input: CreateFolderInput;
};


export type MutationCreateLanguageArgs = {
  input: CreateLanguageInput;
};


export type MutationCreateMediaArgs = {
  input: CreateMediaInput;
};


export type MutationCreateOverlayArgs = {
  input: CreateOverlayInput;
};


export type MutationCreatePageArgs = {
  input: CreatePageInput;
};


export type MutationCreatePageTemplateArgs = {
  input: CreatePageTemplateInput;
};


export type MutationCreatePrimitiveColorSetArgs = {
  input: CreateColorSetInput;
};


export type MutationCreateRedirectionArgs = {
  input: CreateRedirectionInput;
};


export type MutationCreateUiElementArgs = {
  input: CreateUiElementInput;
};


export type MutationDeleteBlockArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteBreakpointArgs = {
  input: DeleteBreakpointInput;
};


export type MutationDeleteColorArgs = {
  input: DeleteColorInput;
};


export type MutationDeleteColorSetArgs = {
  input: DeleteColorSetInput;
};


export type MutationDeleteCssVariableArgs = {
  input: DeleteCssVariableInput;
};


export type MutationDeleteCustomFontArgs = {
  input: DeleteCustomFontInput;
};


export type MutationDeleteCustomFontVariantsArgs = {
  input: DeleteCustomFontVariantsInput;
};


export type MutationDeleteDesignPresetArgs = {
  input: DeleteDesignPresetInput;
};


export type MutationDeleteLanguageArgs = {
  input: DeleteLanguageInput;
};


export type MutationDeleteMediaArgs = {
  input: DeleteMediaInput;
};


export type MutationDeleteOverlayArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeletePageArgs = {
  input: DeletePageInput;
};


export type MutationDeleteRedirectionArgs = {
  input: DeleteRedirectionInput;
};


export type MutationDeleteUiElementArgs = {
  input: DeleteUiElementInput;
};


export type MutationDetachTagsFromMediaArgs = {
  input: DetachTagsFromMedia;
};


export type MutationDuplicateColorSetArgs = {
  input: DuplicateColorSetInput;
};


export type MutationDuplicateDesignPresetArgs = {
  input: DuplicateDesignPresetInput;
};


export type MutationDuplicateMediaArgs = {
  input: DuplicateMediaInput;
};


export type MutationDuplicateUiElementArgs = {
  input: DuplicateUiElementInput;
};


export type MutationLoadRemoteMediaArgs = {
  input: LoadRemoteMediaInput;
};


export type MutationMakeDefaultArgs = {
  input: MakeDefaultInput;
};


export type MutationMoveMediaArgs = {
  input: MoveMediaInput;
};


export type MutationMovePageArgs = {
  input: Array<MovePageInput>;
};


export type MutationOrderLanguagesArgs = {
  input: OrderLanguagesInput;
};


export type MutationRemoveFontFromPresetArgs = {
  input: RemoveFontInput;
};


export type MutationRemovePageTemplateArgs = {
  id: Scalars['ID']['input'];
};


export type MutationRenameBlockArgs = {
  input: RenameBlockInput;
};


export type MutationRestoreOrCreateColorArgs = {
  input: RestoreOrCreateColorInput;
};


export type MutationSetPagePasswordArgs = {
  input: PasswordProtectionInput;
};


export type MutationSetScriptsArgs = {
  input: SetScriptInput;
};


export type MutationSwitchFontVariantsArgs = {
  input: SwitchFontVariantsInput;
};


export type MutationUpdateBlockArgs = {
  input: UpdateBlockInput;
};


export type MutationUpdateBreakpointArgs = {
  input: UpdateBreakpointInput;
};


export type MutationUpdateColorArgs = {
  input: UpdateColorInput;
};


export type MutationUpdateColorSetArgs = {
  input: UpdateColorSetInput;
};


export type MutationUpdateCssVariablesArgs = {
  input: UpdateCssVariablesInput;
};


export type MutationUpdateDesignPresetArgs = {
  input: UpdateDesignPresetInput;
};


export type MutationUpdateFolderArgs = {
  input: UpdateFolderInput;
};


export type MutationUpdateGridArgs = {
  input: UpdateGridInput;
};


export type MutationUpdateLanguageArgs = {
  input: UpdateLanguageInput;
};


export type MutationUpdateLegalDocArgs = {
  input: UpdateLegalDocInput;
};


export type MutationUpdateMediaArgs = {
  input: UpdateMediaInput;
};


export type MutationUpdateOverlayArgs = {
  input: UpdateOverlayInput;
};


export type MutationUpdatePageArgs = {
  input: UpdatePageInput;
};


export type MutationUpdatePageTemplateArgs = {
  input: UpdatePageTemplateInput;
};


export type MutationUpdateRedirectionArgs = {
  input: UpdateRedirectionInput;
};


export type MutationUpdateUiElementArgs = {
  input: UpdateUiElementInput;
};


export type MutationUploadCustomFontArgs = {
  input: UploadCustomFontInput;
};


export type MutationUploadMediaArgs = {
  input: UploadMediaInput;
};


export type MutationUpsertTranslationArgs = {
  input: UpsertTranslationInput;
};

/** Allows ordering a list of records. */
export type OrderByClause = {
  /** The column that is used for ordering. */
  column: Scalars['String']['input'];
  /** The direction that is used for ordering. */
  order: SortOrder;
};

/** Aggregate functions when ordering by a relation without specifying a column. */
export enum OrderByRelationAggregateFunction {
  /** Amount of items. */
  Count = 'COUNT'
}

/** Aggregate functions when ordering by a relation that may specify a column. */
export enum OrderByRelationWithColumnAggregateFunction {
  /** Average. */
  Avg = 'AVG',
  /** Amount of items. */
  Count = 'COUNT',
  /** Maximum. */
  Max = 'MAX',
  /** Minimum. */
  Min = 'MIN',
  /** Sum. */
  Sum = 'SUM'
}

export type OrderLanguagesInput = {
  ordering: Scalars['Array']['input'];
};

export type Overlay = {
  __typename?: 'Overlay';
  alias: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  layout: Layout;
  name: Scalars['String']['output'];
  params?: Maybe<Scalars['Array']['output']>;
  triggers?: Maybe<Array<Trigger>>;
};

/** A paginated list of Overlay items. */
export type OverlayPaginator = {
  __typename?: 'OverlayPaginator';
  /** A list of Overlay items. */
  data: Array<Overlay>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type Page = {
  __typename?: 'Page';
  alias: Scalars['String']['output'];
  attributeFamilyGroupType?: Maybe<Scalars['String']['output']>;
  attributeFamilyId?: Maybe<Scalars['Int']['output']>;
  categoryId?: Maybe<Scalars['Int']['output']>;
  children?: Maybe<Array<Page>>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  designPreset: DesignPreset;
  disabledAt?: Maybe<Scalars['DateTime']['output']>;
  group: PageGroup;
  hasChildren: Scalars['Boolean']['output'];
  homepage: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  layoutStructure: PageLayoutStructure;
  name: Scalars['String']['output'];
  parentId?: Maybe<Scalars['ID']['output']>;
  password?: Maybe<Scalars['String']['output']>;
  passwordProtection?: Maybe<Scalars['Boolean']['output']>;
  path: Scalars['String']['output'];
  previousId?: Maybe<Scalars['ID']['output']>;
  seo?: Maybe<Seo>;
  social?: Maybe<Social>;
  status: PageStatus;
  target: PageTarget;
  thumbnail?: Maybe<Scalars['String']['output']>;
  type: PageType;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  variantsStyles?: Maybe<Scalars['Array']['output']>;
  visibility: PageVisibility;
};

/** The PageGroup enum specifies the group of the page. */
export enum PageGroup {
  /** Special type of system pages designed particularly for attribute family. */
  AttributeFamily = 'ATTRIBUTE_FAMILY',
  /** Special type of system pages designed particularly for category. */
  Category = 'CATEGORY',
  /** Special type of system pages designed particularly for collection. */
  Collection = 'COLLECTION',
  /** Main pages are the general pages of the website. */
  Main = 'MAIN',
  /** System pages are the pages that have special meaning for the website. */
  System = 'SYSTEM',
  /** Special type of system pages designed particularly for e-commerce. */
  Uccommerce = 'UCCOMMERCE'
}

/**
 * Available types of page layout structures. the value works as follows
 *     `A-B-C`, which in this formula:
 *     A: shows the availability of header, `0` means no header and `1` means header
 *     B: shows the availability of footer, `0` means no footer and `1` means footer
 *     C: shows the availability, side and the style of sidebar
 */
export enum PageLayoutStructure {
  /** Page layout with header, footer and full left sidebar. */
  HeaderFooterLeftFullSidebar = 'HEADER_FOOTER_LEFT_FULL_SIDEBAR',
  /** Page layout with header, footer and normal left sidebar. */
  HeaderFooterLeftNormalSidebar = 'HEADER_FOOTER_LEFT_NORMAL_SIDEBAR',
  /** Page layout with no header, no footer and no sidebar. */
  HeaderFooterNoSidebar = 'HEADER_FOOTER_NO_SIDEBAR',
  /** Page layout with header, footer and full right sidebar. */
  HeaderFooterRightFullSidebar = 'HEADER_FOOTER_RIGHT_FULL_SIDEBAR',
  /** Page layout with header, footer and normal right sidebar. */
  HeaderFooterRightNormalSidebar = 'HEADER_FOOTER_RIGHT_NORMAL_SIDEBAR',
  /** Page layout with header, no footer and full left sidebar. */
  HeaderNoFooterLeftFullSidebar = 'HEADER_NO_FOOTER_LEFT_FULL_SIDEBAR',
  /** Page layout with header, no footer and normal left sidebar. */
  HeaderNoFooterLeftNormalSidebar = 'HEADER_NO_FOOTER_LEFT_NORMAL_SIDEBAR',
  /** Page layout with header, no footer and no sidebar. */
  HeaderNoFooterNoSidebar = 'HEADER_NO_FOOTER_NO_SIDEBAR',
  /** Page layout with header, no footer and full right sidebar. */
  HeaderNoFooterRightFullSidebar = 'HEADER_NO_FOOTER_RIGHT_FULL_SIDEBAR',
  /** Page layout with header, no footer and normal right sidebar. */
  HeaderNoFooterRightNormalSidebar = 'HEADER_NO_FOOTER_RIGHT_NORMAL_SIDEBAR',
  /** Page layout with no header, footer and full left sidebar. */
  NoHeaderFooterLeftFullSidebar = 'NO_HEADER_FOOTER_LEFT_FULL_SIDEBAR',
  /** Page layout with no header, footer and normal left sidebar. */
  NoHeaderFooterLeftNormalSidebar = 'NO_HEADER_FOOTER_LEFT_NORMAL_SIDEBAR',
  /** Page layout with no header, footer and normal left sidebar. */
  NoHeaderFooterNoSidebar = 'NO_HEADER_FOOTER_NO_SIDEBAR',
  /** Page layout with no header, footer and full right sidebar. */
  NoHeaderFooterRightFullSidebar = 'NO_HEADER_FOOTER_RIGHT_FULL_SIDEBAR',
  /** Page layout with no header, footer and normal right sidebar. */
  NoHeaderFooterRightNormalSidebar = 'NO_HEADER_FOOTER_RIGHT_NORMAL_SIDEBAR',
  /** Page layout with no header, no footer and full left sidebar. */
  NoHeaderNoFooterLeftFullSidebar = 'NO_HEADER_NO_FOOTER_LEFT_FULL_SIDEBAR',
  /** Page layout with no header, no footer and no sidebar. */
  NoHeaderNoFooterNoSidebar = 'NO_HEADER_NO_FOOTER_NO_SIDEBAR',
  /** Page layout with no header, no footer and full right sidebar. */
  NoHeaderNoFooterRightFullSidebar = 'NO_HEADER_NO_FOOTER_RIGHT_FULL_SIDEBAR'
}

export type PagePaginator = {
  __typename?: 'PagePaginator';
  data: Array<Page>;
  paginatorInfo: PaginatorInfo;
};

/** Types of page status. */
export enum PageStatus {
  /** Type of published page. */
  Published = 'PUBLISHED',
  /** Type of unpublished page. */
  Unpublished = 'UNPUBLISHED'
}

/** The PageTarget enum specifies where to open the linked page. */
export enum PageTarget {
  /** Opens the linked page in a new window or tab. */
  Blank = 'BLANK',
  /** Opens the linked page in the same frame as it was clicked. */
  Self = 'SELF'
}

export type PageTemplate = {
  __typename?: 'PageTemplate';
  creatorId?: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  projectId: Scalars['ID']['output'];
  thumbnail?: Maybe<Scalars['Url']['output']>;
  title: Scalars['String']['output'];
};

export type PageTemplatePaginator = {
  __typename?: 'PageTemplatePaginator';
  data: Array<PageTemplate>;
  paginatorInfo: PaginatorInfo;
};

/** Available types of page. */
export enum PageType {
  /** Blank page with empty content. */
  Blank = 'BLANK'
}

/** Types of page status. */
export enum PageVisibility {
  /** Visibility status that only allows logged in users to view page. */
  LoggedInUsersOnly = 'LOGGED_IN_USERS_ONLY',
  /** Visibility status that allows everyone to view page. */
  Public = 'PUBLIC'
}

/** The PagesOrderByColumn enum specifies the columns by which pages can be ordered. */
export enum PagesOrderByColumn {
  /** Order pages by creation date. */
  CreatedAt = 'CREATED_AT',
  /** Order pages by name. */
  Name = 'NAME',
  /** Order pages by defined order. */
  Order = 'ORDER',
  /** Order pages by parent_id. */
  ParentId = 'PARENT_ID'
}

/** Information about pagination using a fully featured paginator. */
export type PaginatorInfo = {
  __typename?: 'PaginatorInfo';
  /** Number of items in the current page. */
  count: Scalars['Int']['output'];
  /** Index of the current page. */
  currentPage: Scalars['Int']['output'];
  /** Index of the first item in the current page. */
  firstItem?: Maybe<Scalars['Int']['output']>;
  /** Are there more pages after this one? */
  hasMorePages: Scalars['Boolean']['output'];
  /** Index of the last item in the current page. */
  lastItem?: Maybe<Scalars['Int']['output']>;
  /** Index of the last available page. */
  lastPage: Scalars['Int']['output'];
  /** Number of items per page. */
  perPage: Scalars['Int']['output'];
  /** Number of total available items. */
  total: Scalars['Int']['output'];
};

export type PasswordProtectionInput = {
  id: Scalars['ID']['input'];
  password?: InputMaybe<Scalars['String']['input']>;
  passwordProtection?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PresetFont = {
  __typename?: 'PresetFont';
  designPreset: DesignPreset;
  family: Scalars['String']['output'];
  type: FontType;
  variants: Array<FontVariant>;
};

export type ProjectAddress = {
  __typename?: 'ProjectAddress';
  address?: Maybe<Scalars['String']['output']>;
  businessLegalName?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  zipCode?: Maybe<Scalars['String']['output']>;
};

export type ProjectConfiguration = {
  __typename?: 'ProjectConfiguration';
  address: ProjectAddress;
  darkModeLogo?: Maybe<Scalars['ID']['output']>;
  favicon?: Maybe<Scalars['ID']['output']>;
  isExpired: Scalars['Boolean']['output'];
  isOffline: Scalars['Boolean']['output'];
  languageDetectionMode: LanguageDetectionMode;
  logo?: Maybe<Scalars['ID']['output']>;
  mobileDarkModeLogo?: Maybe<Scalars['ID']['output']>;
  mobileLogo?: Maybe<Scalars['ID']['output']>;
  permissions: Array<Scalars['String']['output']>;
  previewUrl: Scalars['String']['output'];
  projectId: Scalars['ID']['output'];
  projectName: Scalars['String']['output'];
  screenshotUrl?: Maybe<Scalars['String']['output']>;
  scripts?: Maybe<Script>;
  socials: Array<Socials>;
  status: ProjectStatus;
};

/** List of available statuses for project. */
export enum ProjectStatus {
  /** Project is active. */
  Active = 'ACTIVE',
  /** Project is in demo. */
  Demo = 'DEMO',
  /** Project is in pending. */
  Pending = 'PENDING',
  /** Project is in trial. */
  Trial = 'TRIAL'
}

export type Query = {
  __typename?: 'Query';
  allMediaFlatten: MediaPaginator;
  block?: Maybe<Block>;
  blockCategories: Array<BlockCategory>;
  blocks?: Maybe<BlockPaginator>;
  breakpoints: Array<Breakpoint>;
  colorSets: Array<ColorSet>;
  configurations: Configuration;
  customFonts: FontPaginator;
  defaultUiElement: DefaultUiElement;
  designPreset?: Maybe<DesignPreset>;
  designPresets: Array<DesignPreset>;
  entityCountLimitation?: Maybe<Array<Limitation>>;
  folder?: Maybe<Folder>;
  folders: FolderPaginator;
  googleFonts: Array<GoogleFont>;
  language: Language;
  languages: Array<Language>;
  legalDoc: LegalDoc;
  legalDocs?: Maybe<Array<LegalDoc>>;
  media?: Maybe<MediaUnion>;
  mediaByIds: Array<MediaUnion>;
  mediaWithFolders: MediaPaginator;
  /** This query provides the Overlay filtered by id or by alias with its translations. */
  overlay?: Maybe<Overlay>;
  /** This query provides the Overlays with its translations. */
  overlays: OverlayPaginator;
  page?: Maybe<Page>;
  pageByAlias?: Maybe<Page>;
  /** This query provides the Overlays with its translations for specified entity. */
  pageOverlays: Array<Overlay>;
  pageTemplate?: Maybe<PageTemplate>;
  pageTemplates?: Maybe<PageTemplatePaginator>;
  pages?: Maybe<PagePaginator>;
  redirections: RedirectionPaginator;
  /** This query provides the Script and its translations. */
  script?: Maybe<Array<Script>>;
  translations: TranslationPagination;
  unsplashImages?: Maybe<UnsplashPhotoPaginator>;
};


export type QueryAllMediaFlattenArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  parentId?: InputMaybe<Scalars['ID']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  type: MediaType;
};


export type QueryBlockArgs = {
  id: Scalars['ID']['input'];
};


export type QueryBlocksArgs = {
  categoryId?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  global?: InputMaybe<Scalars['Boolean']['input']>;
  orderBy?: InputMaybe<Array<QueryBlocksOrderByOrderByClause>>;
  page?: InputMaybe<Scalars['Int']['input']>;
  projectId?: InputMaybe<Scalars['ID']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryColorSetsArgs = {
  designPresetId: Scalars['ID']['input'];
};


export type QueryCustomFontsArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<QueryCustomFontsOrderByOrderByClause>>;
  page?: InputMaybe<Scalars['Int']['input']>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
};


export type QueryDefaultUiElementArgs = {
  type: UiElementType;
};


export type QueryDesignPresetArgs = {
  id: Scalars['ID']['input'];
};


export type QueryEntityCountLimitationArgs = {
  entities: Array<LimitationType>;
};


export type QueryFolderArgs = {
  id: Scalars['ID']['input'];
};


export type QueryFoldersArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<QueryFoldersOrderByOrderByClause>>;
  page?: InputMaybe<Scalars['Int']['input']>;
  parentId?: InputMaybe<Scalars['ID']['input']>;
  type: MediaType;
};


export type QueryLanguageArgs = {
  id: Scalars['ID']['input'];
};


export type QueryLegalDocArgs = {
  id: Scalars['ID']['input'];
};


export type QueryMediaArgs = {
  id: Scalars['ID']['input'];
  type: MediaType;
};


export type QueryMediaByIdsArgs = {
  ids: Array<Scalars['ID']['input']>;
};


export type QueryMediaWithFoldersArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<QueryMediaWithFoldersOrderByOrderByClause>>;
  page?: InputMaybe<Scalars['Int']['input']>;
  parentId?: InputMaybe<Scalars['ID']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  type: MediaType;
};


export type QueryOverlayArgs = {
  alias?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryOverlaysArgs = {
  first: Scalars['Int']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryPageArgs = {
  id: Scalars['ID']['input'];
};


export type QueryPageByAliasArgs = {
  alias: Scalars['String']['input'];
};


export type QueryPageOverlaysArgs = {
  entityId?: InputMaybe<Scalars['ID']['input']>;
  entityType: TriggerEntityType;
  pageId?: InputMaybe<Scalars['ID']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryPageTemplateArgs = {
  id: Scalars['ID']['input'];
};


export type QueryPageTemplatesArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryPagesArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  group?: InputMaybe<PageGroup>;
  orderBy?: InputMaybe<Array<QueryPagesOrderByOrderByClause>>;
  page?: InputMaybe<Scalars['Int']['input']>;
  parentId?: InputMaybe<Scalars['ID']['input']>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
};


export type QueryRedirectionsArgs = {
  first: Scalars['Int']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
};


export type QueryTranslationsArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  languageCode: Scalars['String']['input'];
  orderBy?: InputMaybe<TranslationsOrderBy>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryUnsplashImagesArgs = {
  collections?: InputMaybe<Array<Scalars['String']['input']>>;
  first: Scalars['Int']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};

/** Order by clause for Query.blocks.orderBy. */
export type QueryBlocksOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: BlocksOrderByColumn;
  /** The direction that is used for ordering. */
  order: SortOrder;
};

/** Allowed column names for Query.customFonts.orderBy. */
export enum QueryCustomFontsOrderByColumn {
  CreatedAt = 'CREATED_AT',
  Family = 'FAMILY'
}

/** Order by clause for Query.customFonts.orderBy. */
export type QueryCustomFontsOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: QueryCustomFontsOrderByColumn;
  /** The direction that is used for ordering. */
  order: SortOrder;
};

/** Allowed column names for Query.folders.orderBy. */
export enum QueryFoldersOrderByColumn {
  CreatedAt = 'CREATED_AT',
  Name = 'NAME',
  Size = 'SIZE'
}

/** Order by clause for Query.folders.orderBy. */
export type QueryFoldersOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: QueryFoldersOrderByColumn;
  /** The direction that is used for ordering. */
  order: SortOrder;
};

/** Order by clause for Query.mediaWithFolders.orderBy. */
export type QueryMediaWithFoldersOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: MediaOrderByColumn;
  /** The direction that is used for ordering. */
  order: SortOrder;
};

/** Order by clause for Query.pages.orderBy. */
export type QueryPagesOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: PagesOrderByColumn;
  /** The direction that is used for ordering. */
  order: SortOrder;
};

export type Redirection = {
  __typename?: 'Redirection';
  from: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  status: RedirectionStatus;
  to: Scalars['Url']['output'];
  type: RedirectionType;
};

/** A paginated list of Redirection items. */
export type RedirectionPaginator = {
  __typename?: 'RedirectionPaginator';
  /** A list of Redirection items. */
  data: Array<Redirection>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

/** List of available redirection statues. */
export enum RedirectionStatus {
  /** Published status of redirection. */
  Published = 'PUBLISHED',
  /** Unpublished status of redirection. */
  Unpublished = 'UNPUBLISHED'
}

/** List of available redirection types. */
export enum RedirectionType {
  /** Found type of redirection. */
  Found = 'FOUND',
  /** Permanent type of redirection. */
  Permanent = 'PERMANENT',
  /** Temporary type of redirection. */
  Temporary = 'TEMPORARY'
}

export type RemoveFontInput = {
  designPresetId: Scalars['ID']['input'];
  family: Scalars['String']['input'];
  type: FontType;
};

export type RenameBlockInput = {
  hash: Scalars['String']['input'];
  layoutId: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type ResetDesignPresetInput = {
  designPresetId: Scalars['ID']['input'];
};

export type RestoreOrCreateColorInput = {
  alias?: InputMaybe<Scalars['String']['input']>;
  designPresetId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  setId: Scalars['ID']['input'];
};

export type Script = {
  __typename?: 'Script';
  content: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  position: ScriptPosition;
  translations: Array<ScriptTranslations>;
};

/** List of available positions of project scripts. */
export enum ScriptPosition {
  /** Bottom of body tag. */
  BottomBody = 'BOTTOM_BODY',
  /** Bottom of head tag. */
  BottomHead = 'BOTTOM_HEAD',
  /** Top of body tag. */
  TopBody = 'TOP_BODY',
  /** Top of head tag. */
  TopHead = 'TOP_HEAD'
}

export type ScriptTranslationInput = {
  content: Scalars['String']['input'];
  languageCode: Scalars['String']['input'];
  position: ScriptPosition;
};

export type ScriptTranslations = {
  __typename?: 'ScriptTranslations';
  content: Scalars['String']['output'];
  languageCode: Scalars['String']['output'];
  position: ScriptPosition;
};

/** List of available types of project scripts. */
export enum ScriptType {
  /** External type of script. */
  External = 'EXTERNAL',
  /** Internal type of script. */
  Internal = 'INTERNAL'
}

export type Seo = {
  __typename?: 'Seo';
  description: Scalars['String']['output'];
  robot: SeoRobot;
  schema: SeoSchemaMarkup;
  sitemap: Scalars['Boolean']['output'];
  title: Scalars['String']['output'];
};

export type SeoInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  robot?: InputMaybe<SeoRobot>;
  schema?: InputMaybe<SeoSchemaMarkup>;
  sitemap?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** Options for the seo robot setting. */
export enum SeoRobot {
  /** Indexing and following the page. */
  IndexFollow = 'INDEX_FOLLOW',
  /** Indexing without following the page. */
  IndexNofollow = 'INDEX_NOFOLLOW',
  /** Following without indexing the page. */
  NoindexFollow = 'NOINDEX_FOLLOW',
  /** Neither indexing nor following the page. */
  NoindexNofollow = 'NOINDEX_NOFOLLOW'
}

/** Values of the dropdown in the SEO schema markup. */
export enum SeoSchemaMarkup {
  /** ARTICLES schema markup. */
  Articles = 'ARTICLES',
  /** EVENTS schema markup. */
  Events = 'EVENTS',
  /** LOCAL BUSINESSES schema markup. */
  LocalBusinesses = 'LOCAL_BUSINESSES',
  /** MEDICAL CONDITIONS schema markup. */
  MedicalConditions = 'MEDICAL_CONDITIONS',
  /** ORGANIZATIONS schema markup. */
  Organizations = 'ORGANIZATIONS',
  /** PEOPLE schema markup. */
  People = 'PEOPLE',
  /** PRODUCTS schema markup. */
  Products = 'PRODUCTS',
  /** RECIPES schema markup. */
  Recipes = 'RECIPES',
  /** REVIEWS schema markup. */
  Reviews = 'REVIEWS'
}

export type SetScriptInput = {
  translations: Array<ScriptTranslationInput>;
};

export type Setting = {
  __typename?: 'Setting';
  name: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type Social = {
  __typename?: 'Social';
  description?: Maybe<Scalars['String']['output']>;
  image?: Maybe<Scalars['ID']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type SocialInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type Socials = {
  __typename?: 'Socials';
  name: Scalars['String']['output'];
  order: Scalars['Int']['output'];
  url: Scalars['String']['output'];
};

/** Directions for ordering a list of records. */
export enum SortOrder {
  /** Sort records in ascending order. */
  Asc = 'ASC',
  /** Sort records in descending order. */
  Desc = 'DESC'
}

export type SwitchFontVariantsInput = {
  designPresetId: Scalars['ID']['input'];
  ids: Array<Scalars['ID']['input']>;
};

export type Tag = {
  __typename?: 'Tag';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  media: Array<MediaUnion>;
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type TranslationItem = {
  __typename?: 'TranslationItem';
  defaultValue: Scalars['String']['output'];
  editorName: Scalars['String']['output'];
  id?: Maybe<Scalars['ID']['output']>;
  languageCode: Scalars['String']['output'];
  params?: Maybe<Scalars['Array']['output']>;
  translationKey: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  value: Scalars['String']['output'];
};

/** List of available translation column types. */
export enum TranslationOrderByColumnEnum {
  /** Column key. */
  Key = 'KEY',
  /** Column updated_at. */
  UpdatedAt = 'UPDATED_AT'
}

/** List of available translation order types. */
export enum TranslationOrderByOrderEnum {
  /** Order by asc. */
  Asc = 'ASC',
  /** Order by desc. */
  Desc = 'DESC'
}

export type TranslationPagination = {
  __typename?: 'TranslationPagination';
  data: Array<TranslationItem>;
  total: Scalars['Int']['output'];
};

export type TranslationsOrderBy = {
  column: TranslationOrderByColumnEnum;
  order: TranslationOrderByOrderEnum;
};

/** Specify if you want to include or exclude trashed results from a query. */
export enum Trashed {
  /** Only return trashed results. */
  Only = 'ONLY',
  /** Return both trashed and non-trashed results. */
  With = 'WITH',
  /** Only return non-trashed results. */
  Without = 'WITHOUT'
}

export type Trigger = {
  __typename?: 'Trigger';
  conditionOperator?: Maybe<Scalars['String']['output']>;
  conditions?: Maybe<Array<Maybe<Condition>>>;
  triggerGroups?: Maybe<Array<Maybe<TriggerGroup>>>;
};

/** Available types of trigger entity. */
export enum TriggerEntityType {
  /** Special case to specify for all entity types. */
  All = 'ALL',
  /** Collection category type. */
  CollectionCategory = 'COLLECTION_CATEGORY',
  /** Collection item type. */
  CollectionItem = 'COLLECTION_ITEM',
  /** Main page type. */
  Main = 'MAIN',
  /** Product type. */
  Product = 'PRODUCT',
  /** Product category type. */
  ProductCategory = 'PRODUCT_CATEGORY',
  /** System page type. */
  System = 'SYSTEM',
  /** E-Commerce page type. */
  Uccommerce = 'UCCOMMERCE'
}

export type TriggerGroup = {
  __typename?: 'TriggerGroup';
  params: TriggerGroupParam;
  type: Scalars['String']['output'];
};

export type TriggerGroupInput = {
  params: TriggerGroupParamInput;
  type: Scalars['String']['input'];
};

export type TriggerGroupParam = {
  __typename?: 'TriggerGroupParam';
  operator: TriggerOperator;
  type: TriggerEntityType;
  values: Scalars['Array']['output'];
};

export type TriggerGroupParamInput = {
  operator: TriggerOperator;
  type: TriggerEntityType;
  values: Scalars['Array']['input'];
};

export type TriggerInput = {
  conditionOperator?: InputMaybe<Scalars['String']['input']>;
  conditions?: InputMaybe<Array<InputMaybe<ConditionInput>>>;
  triggerGroups?: InputMaybe<Array<InputMaybe<TriggerGroupInput>>>;
};

/** TriggerOperator enum defines operator types for triggers. */
export enum TriggerOperator {
  /** IS operator type. */
  Is = 'IS',
  /** IS_NOT operator type. */
  IsNot = 'IS_NOT'
}

export type UiElement = {
  __typename?: 'UiElement';
  content: Scalars['Array']['output'];
  darkMode: Scalars['Boolean']['output'];
  designPreset: DesignPreset;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  type: UiElementType;
};

/** The UiElementType enum specifies Different UI Element Types */
export enum UiElementType {
  /** Button UI Element type. */
  Button = 'BUTTON',
  /** Checkbox list Element type. */
  CheckboxList = 'CHECKBOX_LIST',
  /** Color Swatch Element type. */
  ColorSwatch = 'COLOR_SWATCH',
  /** Date Time Element type. */
  Datetime = 'DATETIME',
  /** Dropdown Element type. */
  Dropdown = 'DROPDOWN',
  /** Follow Us Element type. */
  FollowUs = 'FOLLOW_US',
  /** Form Item Element type. */
  FormItem = 'FORM_ITEM',
  /** Gallery UI Element type. */
  Gallery = 'GALLERY',
  /** Image UI Element type. */
  Image = 'IMAGE',
  /** Image Swatch Element type. */
  ImageSwatch = 'IMAGE_SWATCH',
  /** Input Element type. */
  Input = 'INPUT',
  /** Phone Input Element type. */
  PhoneInput = 'PHONE_INPUT',
  /** Radio button list Element type. */
  RadioButtonList = 'RADIO_BUTTON_LIST',
  /** Tag UI Element type. */
  Tag = 'TAG',
  /** Text Area Element type. */
  TextArea = 'TEXT_AREA',
  /** Text Swatch Element type. */
  TextSwatch = 'TEXT_SWATCH'
}

export type UnsplashPhoto = {
  __typename?: 'UnsplashPhoto';
  altDescription?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  height?: Maybe<Scalars['Int']['output']>;
  id: Scalars['String']['output'];
  likes?: Maybe<Scalars['Int']['output']>;
  profileImageSmall?: Maybe<Scalars['String']['output']>;
  regularUrl?: Maybe<Scalars['Url']['output']>;
  tags?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  thumbUrl?: Maybe<Scalars['Url']['output']>;
  twitterUsername?: Maybe<Scalars['String']['output']>;
  userProfileLink?: Maybe<Scalars['Url']['output']>;
  username?: Maybe<Scalars['String']['output']>;
  width?: Maybe<Scalars['Int']['output']>;
};

export type UnsplashPhotoPaginator = {
  __typename?: 'UnsplashPhotoPaginator';
  data: Array<UnsplashPhoto>;
  paginatorInfo: PaginatorInfo;
};

export type UpdateBlockInput = {
  category?: InputMaybe<Scalars['ID']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  industries?: InputMaybe<Array<Scalars['ID']['input']>>;
  thumbnail?: InputMaybe<Scalars['ID']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateBreakpointInput = {
  default?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  selected?: InputMaybe<Scalars['Boolean']['input']>;
  width?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateColorInput = {
  designPresetId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  setId?: InputMaybe<Scalars['ID']['input']>;
  type?: InputMaybe<ColorType>;
  value?: InputMaybe<Scalars['Array']['input']>;
};

export type UpdateColorSetInput = {
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type UpdateCssVariablesInput = {
  data: Array<CssVariableDataInput>;
  designPresetId: Scalars['ID']['input'];
};

export type UpdateDesignPresetInput = {
  colorPreferences?: InputMaybe<ColorPreferencesInput>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  default?: InputMaybe<Scalars['Boolean']['input']>;
  designPresetId: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateFolderInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  parentId?: InputMaybe<Scalars['ID']['input']>;
  type?: InputMaybe<MediaType>;
};

export type UpdateGridInput = {
  designPresetId: Scalars['ID']['input'];
  horizontal: Scalars['Int']['input'];
  type: GridDimension;
  vertical: Scalars['Int']['input'];
};

export type UpdateLanguageInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  designPresetId?: InputMaybe<Scalars['ID']['input']>;
  domainId?: InputMaybe<Scalars['ID']['input']>;
  enforceUnicode?: InputMaybe<Scalars['Boolean']['input']>;
  flag?: InputMaybe<Scalars['ID']['input']>;
  hidden?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  language?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  nativeName?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  rtl?: InputMaybe<Scalars['Boolean']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  unicodeCharacters?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateLegalDocInput = {
  description: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  translations: Array<LegalDocTranslationInput>;
};

export type UpdateMediaInput = {
  alt?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  parentId?: InputMaybe<Scalars['ID']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type: MediaType;
};

export type UpdateOverlayInput = {
  alias?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  params?: InputMaybe<Scalars['Array']['input']>;
  triggers?: InputMaybe<Array<TriggerInput>>;
};

export type UpdatePageInput = {
  alias?: InputMaybe<Scalars['String']['input']>;
  designPresetId?: InputMaybe<Scalars['ID']['input']>;
  homepage?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  layoutStructure?: InputMaybe<PageLayoutStructure>;
  name?: InputMaybe<Scalars['String']['input']>;
  parentId?: InputMaybe<Scalars['ID']['input']>;
  seo?: InputMaybe<SeoInput>;
  social?: InputMaybe<SocialInput>;
  status?: InputMaybe<PageStatus>;
  target?: InputMaybe<PageTarget>;
  variantsStyles?: InputMaybe<Scalars['Array']['input']>;
  visibility?: InputMaybe<PageVisibility>;
};

export type UpdatePageTemplateInput = {
  id: Scalars['ID']['input'];
  thumbnail?: InputMaybe<Scalars['ID']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateRedirectionInput = {
  from?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  status?: InputMaybe<RedirectionStatus>;
  to?: InputMaybe<Scalars['Url']['input']>;
  type?: InputMaybe<RedirectionType>;
};

export type UpdateUiElementInput = {
  content?: InputMaybe<Scalars['Array']['input']>;
  darkMode?: InputMaybe<Scalars['Boolean']['input']>;
  designPresetId: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  uiElementId: Scalars['ID']['input'];
};

export type UploadCustomFontInput = {
  file: Scalars['Upload']['input'];
};

export type UploadMediaInput = {
  file: Scalars['Upload']['input'];
  type: MediaType;
};

export type UploadedMedia = {
  __typename?: 'UploadedMedia';
  name: Scalars['String']['output'];
  thumbnail?: Maybe<Scalars['String']['output']>;
};

export type UpsertTranslationInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  languageCode: Scalars['String']['input'];
  params?: InputMaybe<Scalars['Array']['input']>;
  translationKey: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type Vector = MediaInterface & {
  __typename?: 'Vector';
  alt?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  extension?: Maybe<Scalars['String']['output']>;
  filename: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  mimeType: Scalars['String']['output'];
  name: Scalars['String']['output'];
  parent?: Maybe<MediaInterface>;
  parents?: Maybe<Array<MediaInterface>>;
  size: Scalars['Int']['output'];
  tags: Array<Tag>;
  thumbnail?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type: MediaType;
  updatedAt: Scalars['DateTime']['output'];
};

export type Video = MediaInterface & {
  __typename?: 'Video';
  alt?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  duration: Scalars['Float']['output'];
  extension?: Maybe<Scalars['String']['output']>;
  filename: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  mimeType: Scalars['String']['output'];
  name: Scalars['String']['output'];
  parent?: Maybe<MediaInterface>;
  parents?: Maybe<Array<MediaInterface>>;
  size: Scalars['Int']['output'];
  tags: Array<Tag>;
  thumbnail?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type: MediaType;
  updatedAt: Scalars['DateTime']['output'];
  webPThumbnail?: Maybe<Scalars['String']['output']>;
};



export type ResolverTypeWrapper<T> = Promise<T> | T;


export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> = ResolverFn<TResult, TParent, TContext, TArgs> | ResolverWithResolve<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (obj: T, context: TContext, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;



/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  AddCustomFontsInput: ResolverTypeWrapper<any>;
  AddGoogleFontsInput: ResolverTypeWrapper<any>;
  Array: ResolverTypeWrapper<any>;
  AttachTagsToMedia: ResolverTypeWrapper<any>;
  Audio: ResolverTypeWrapper<any>;
  Block: ResolverTypeWrapper<any>;
  BlockCategory: ResolverTypeWrapper<any>;
  BlockPaginator: ResolverTypeWrapper<any>;
  BlocksOrderByColumn: ResolverTypeWrapper<any>;
  Boolean: ResolverTypeWrapper<any>;
  Breakpoint: ResolverTypeWrapper<any>;
  Color: ResolverTypeWrapper<any>;
  ColorPreferences: ResolverTypeWrapper<any>;
  ColorPreferencesInput: ResolverTypeWrapper<any>;
  ColorPreferencesMode: ResolverTypeWrapper<any>;
  ColorSet: ResolverTypeWrapper<any>;
  ColorSetType: ResolverTypeWrapper<any>;
  ColorType: ResolverTypeWrapper<any>;
  Condition: ResolverTypeWrapper<any>;
  ConditionInput: ResolverTypeWrapper<any>;
  Configuration: ResolverTypeWrapper<any>;
  CreateBreakpointInput: ResolverTypeWrapper<any>;
  CreateColorInput: ResolverTypeWrapper<any>;
  CreateColorSetInput: ResolverTypeWrapper<any>;
  CreateDesignPresetInput: ResolverTypeWrapper<any>;
  CreateFolderInput: ResolverTypeWrapper<any>;
  CreateLanguageInput: ResolverTypeWrapper<any>;
  CreateMediaInput: ResolverTypeWrapper<any>;
  CreateOverlayInput: ResolverTypeWrapper<any>;
  CreatePageInput: ResolverTypeWrapper<any>;
  CreatePageTemplateInput: ResolverTypeWrapper<any>;
  CreateRedirectionInput: ResolverTypeWrapper<any>;
  CreateUiElementInput: ResolverTypeWrapper<any>;
  CssVariable: ResolverTypeWrapper<any>;
  CssVariableCategory: ResolverTypeWrapper<any>;
  CssVariableDataInput: ResolverTypeWrapper<any>;
  CustomFont: ResolverTypeWrapper<any>;
  CustomFontsVariantInput: ResolverTypeWrapper<any>;
  Date: ResolverTypeWrapper<any>;
  DateTime: ResolverTypeWrapper<any>;
  DateTimeTz: ResolverTypeWrapper<any>;
  DefaultUiElement: ResolverTypeWrapper<any>;
  DeleteBreakpointInput: ResolverTypeWrapper<any>;
  DeleteColorInput: ResolverTypeWrapper<any>;
  DeleteColorSetInput: ResolverTypeWrapper<any>;
  DeleteCssVariableInput: ResolverTypeWrapper<any>;
  DeleteCustomFontInput: ResolverTypeWrapper<any>;
  DeleteCustomFontVariantsInput: ResolverTypeWrapper<any>;
  DeleteDesignPresetInput: ResolverTypeWrapper<any>;
  DeleteLanguageInput: ResolverTypeWrapper<any>;
  DeleteMediaInput: ResolverTypeWrapper<any>;
  DeletePageInput: ResolverTypeWrapper<any>;
  DeleteRedirectionInput: ResolverTypeWrapper<any>;
  DeleteUiElementInput: ResolverTypeWrapper<any>;
  DesignPreset: ResolverTypeWrapper<any>;
  DetachTagsFromMedia: ResolverTypeWrapper<any>;
  Document: ResolverTypeWrapper<any>;
  DuplicateColorSetInput: ResolverTypeWrapper<any>;
  DuplicateDesignPresetInput: ResolverTypeWrapper<any>;
  DuplicateMediaInput: ResolverTypeWrapper<any>;
  DuplicateUiElementInput: ResolverTypeWrapper<any>;
  Float: ResolverTypeWrapper<any>;
  Folder: ResolverTypeWrapper<any>;
  FolderPaginator: ResolverTypeWrapper<any>;
  FontDetails: ResolverTypeWrapper<any>;
  FontPaginator: ResolverTypeWrapper<any>;
  FontType: ResolverTypeWrapper<any>;
  FontVariant: ResolverTypeWrapper<any>;
  GeographicalInfo: ResolverTypeWrapper<any>;
  GoogleFont: ResolverTypeWrapper<any>;
  GoogleFontVariansInput: ResolverTypeWrapper<any>;
  GoogleFontsDataInput: ResolverTypeWrapper<any>;
  Grid: ResolverTypeWrapper<any>;
  GridDimension: ResolverTypeWrapper<any>;
  ID: ResolverTypeWrapper<any>;
  Image: ResolverTypeWrapper<any>;
  Industry: ResolverTypeWrapper<any>;
  Int: ResolverTypeWrapper<any>;
  Language: ResolverTypeWrapper<any>;
  LanguageDetectionMode: ResolverTypeWrapper<any>;
  LanguageInfo: ResolverTypeWrapper<any>;
  Layout: ResolverTypeWrapper<any>;
  LayoutBlockHash: ResolverTypeWrapper<any>;
  LayoutType: ResolverTypeWrapper<any>;
  LegalDoc: ResolverTypeWrapper<any>;
  LegalDocTranslationInput: ResolverTypeWrapper<any>;
  LegalDocType: ResolverTypeWrapper<any>;
  LegalDocVariable: ResolverTypeWrapper<any>;
  Limitation: ResolverTypeWrapper<any>;
  LimitationType: ResolverTypeWrapper<any>;
  LoadRemoteMediaInput: ResolverTypeWrapper<any>;
  MakeDefaultInput: ResolverTypeWrapper<any>;
  MediaInterface: ResolverTypeWrapper<any>;
  MediaOrderByColumn: ResolverTypeWrapper<any>;
  MediaPaginator: ResolverTypeWrapper<any>;
  MediaType: ResolverTypeWrapper<any>;
  MediaUnion: ResolverTypeWrapper<any>;
  Mixed: ResolverTypeWrapper<any>;
  MoveMediaInput: ResolverTypeWrapper<any>;
  MovePageInput: ResolverTypeWrapper<any>;
  Mutation: ResolverTypeWrapper<{}>;
  OrderByClause: ResolverTypeWrapper<any>;
  OrderByRelationAggregateFunction: ResolverTypeWrapper<any>;
  OrderByRelationWithColumnAggregateFunction: ResolverTypeWrapper<any>;
  OrderLanguagesInput: ResolverTypeWrapper<any>;
  Overlay: ResolverTypeWrapper<any>;
  OverlayPaginator: ResolverTypeWrapper<any>;
  Page: ResolverTypeWrapper<any>;
  PageGroup: ResolverTypeWrapper<any>;
  PageLayoutStructure: ResolverTypeWrapper<any>;
  PagePaginator: ResolverTypeWrapper<any>;
  PageStatus: ResolverTypeWrapper<any>;
  PageTarget: ResolverTypeWrapper<any>;
  PageTemplate: ResolverTypeWrapper<any>;
  PageTemplatePaginator: ResolverTypeWrapper<any>;
  PageType: ResolverTypeWrapper<any>;
  PageVisibility: ResolverTypeWrapper<any>;
  PagesOrderByColumn: ResolverTypeWrapper<any>;
  PaginatorInfo: ResolverTypeWrapper<any>;
  PasswordProtectionInput: ResolverTypeWrapper<any>;
  PresetFont: ResolverTypeWrapper<any>;
  ProjectAddress: ResolverTypeWrapper<any>;
  ProjectConfiguration: ResolverTypeWrapper<any>;
  ProjectStatus: ResolverTypeWrapper<any>;
  Query: ResolverTypeWrapper<{}>;
  QueryBlocksOrderByOrderByClause: ResolverTypeWrapper<any>;
  QueryCustomFontsOrderByColumn: ResolverTypeWrapper<any>;
  QueryCustomFontsOrderByOrderByClause: ResolverTypeWrapper<any>;
  QueryFoldersOrderByColumn: ResolverTypeWrapper<any>;
  QueryFoldersOrderByOrderByClause: ResolverTypeWrapper<any>;
  QueryMediaWithFoldersOrderByOrderByClause: ResolverTypeWrapper<any>;
  QueryPagesOrderByOrderByClause: ResolverTypeWrapper<any>;
  Redirection: ResolverTypeWrapper<any>;
  RedirectionPaginator: ResolverTypeWrapper<any>;
  RedirectionStatus: ResolverTypeWrapper<any>;
  RedirectionType: ResolverTypeWrapper<any>;
  RemoveFontInput: ResolverTypeWrapper<any>;
  RenameBlockInput: ResolverTypeWrapper<any>;
  ResetDesignPresetInput: ResolverTypeWrapper<any>;
  RestoreOrCreateColorInput: ResolverTypeWrapper<any>;
  Script: ResolverTypeWrapper<any>;
  ScriptPosition: ResolverTypeWrapper<any>;
  ScriptTranslationInput: ResolverTypeWrapper<any>;
  ScriptTranslations: ResolverTypeWrapper<any>;
  ScriptType: ResolverTypeWrapper<any>;
  Seo: ResolverTypeWrapper<any>;
  SeoInput: ResolverTypeWrapper<any>;
  SeoRobot: ResolverTypeWrapper<any>;
  SeoSchemaMarkup: ResolverTypeWrapper<any>;
  SetScriptInput: ResolverTypeWrapper<any>;
  Setting: ResolverTypeWrapper<any>;
  Social: ResolverTypeWrapper<any>;
  SocialInput: ResolverTypeWrapper<any>;
  Socials: ResolverTypeWrapper<any>;
  SortOrder: ResolverTypeWrapper<any>;
  String: ResolverTypeWrapper<any>;
  SwitchFontVariantsInput: ResolverTypeWrapper<any>;
  Tag: ResolverTypeWrapper<any>;
  TranslationItem: ResolverTypeWrapper<any>;
  TranslationOrderByColumnEnum: ResolverTypeWrapper<any>;
  TranslationOrderByOrderEnum: ResolverTypeWrapper<any>;
  TranslationPagination: ResolverTypeWrapper<any>;
  TranslationsOrderBy: ResolverTypeWrapper<any>;
  Trashed: ResolverTypeWrapper<any>;
  Trigger: ResolverTypeWrapper<any>;
  TriggerEntityType: ResolverTypeWrapper<any>;
  TriggerGroup: ResolverTypeWrapper<any>;
  TriggerGroupInput: ResolverTypeWrapper<any>;
  TriggerGroupParam: ResolverTypeWrapper<any>;
  TriggerGroupParamInput: ResolverTypeWrapper<any>;
  TriggerInput: ResolverTypeWrapper<any>;
  TriggerOperator: ResolverTypeWrapper<any>;
  UiElement: ResolverTypeWrapper<any>;
  UiElementType: ResolverTypeWrapper<any>;
  UnsplashPhoto: ResolverTypeWrapper<any>;
  UnsplashPhotoPaginator: ResolverTypeWrapper<any>;
  UpdateBlockInput: ResolverTypeWrapper<any>;
  UpdateBreakpointInput: ResolverTypeWrapper<any>;
  UpdateColorInput: ResolverTypeWrapper<any>;
  UpdateColorSetInput: ResolverTypeWrapper<any>;
  UpdateCssVariablesInput: ResolverTypeWrapper<any>;
  UpdateDesignPresetInput: ResolverTypeWrapper<any>;
  UpdateFolderInput: ResolverTypeWrapper<any>;
  UpdateGridInput: ResolverTypeWrapper<any>;
  UpdateLanguageInput: ResolverTypeWrapper<any>;
  UpdateLegalDocInput: ResolverTypeWrapper<any>;
  UpdateMediaInput: ResolverTypeWrapper<any>;
  UpdateOverlayInput: ResolverTypeWrapper<any>;
  UpdatePageInput: ResolverTypeWrapper<any>;
  UpdatePageTemplateInput: ResolverTypeWrapper<any>;
  UpdateRedirectionInput: ResolverTypeWrapper<any>;
  UpdateUiElementInput: ResolverTypeWrapper<any>;
  Upload: ResolverTypeWrapper<any>;
  UploadCustomFontInput: ResolverTypeWrapper<any>;
  UploadMediaInput: ResolverTypeWrapper<any>;
  UploadedMedia: ResolverTypeWrapper<any>;
  UpsertTranslationInput: ResolverTypeWrapper<any>;
  Url: ResolverTypeWrapper<any>;
  Vector: ResolverTypeWrapper<any>;
  Video: ResolverTypeWrapper<any>;
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  AddCustomFontsInput: any;
  AddGoogleFontsInput: any;
  Array: any;
  AttachTagsToMedia: any;
  Audio: any;
  Block: any;
  BlockCategory: any;
  BlockPaginator: any;
  Boolean: any;
  Breakpoint: any;
  Color: any;
  ColorPreferences: any;
  ColorPreferencesInput: any;
  ColorSet: any;
  Condition: any;
  ConditionInput: any;
  Configuration: any;
  CreateBreakpointInput: any;
  CreateColorInput: any;
  CreateColorSetInput: any;
  CreateDesignPresetInput: any;
  CreateFolderInput: any;
  CreateLanguageInput: any;
  CreateMediaInput: any;
  CreateOverlayInput: any;
  CreatePageInput: any;
  CreatePageTemplateInput: any;
  CreateRedirectionInput: any;
  CreateUiElementInput: any;
  CssVariable: any;
  CssVariableDataInput: any;
  CustomFont: any;
  CustomFontsVariantInput: any;
  Date: any;
  DateTime: any;
  DateTimeTz: any;
  DefaultUiElement: any;
  DeleteBreakpointInput: any;
  DeleteColorInput: any;
  DeleteColorSetInput: any;
  DeleteCssVariableInput: any;
  DeleteCustomFontInput: any;
  DeleteCustomFontVariantsInput: any;
  DeleteDesignPresetInput: any;
  DeleteLanguageInput: any;
  DeleteMediaInput: any;
  DeletePageInput: any;
  DeleteRedirectionInput: any;
  DeleteUiElementInput: any;
  DesignPreset: any;
  DetachTagsFromMedia: any;
  Document: any;
  DuplicateColorSetInput: any;
  DuplicateDesignPresetInput: any;
  DuplicateMediaInput: any;
  DuplicateUiElementInput: any;
  Float: any;
  Folder: any;
  FolderPaginator: any;
  FontDetails: any;
  FontPaginator: any;
  FontVariant: any;
  GeographicalInfo: any;
  GoogleFont: any;
  GoogleFontVariansInput: any;
  GoogleFontsDataInput: any;
  Grid: any;
  ID: any;
  Image: any;
  Industry: any;
  Int: any;
  Language: any;
  LanguageInfo: any;
  Layout: any;
  LayoutBlockHash: any;
  LegalDoc: any;
  LegalDocTranslationInput: any;
  Limitation: any;
  LoadRemoteMediaInput: any;
  MakeDefaultInput: any;
  MediaInterface: any;
  MediaPaginator: any;
  MediaUnion: any;
  Mixed: any;
  MoveMediaInput: any;
  MovePageInput: any;
  Mutation: {};
  OrderByClause: any;
  OrderLanguagesInput: any;
  Overlay: any;
  OverlayPaginator: any;
  Page: any;
  PagePaginator: any;
  PageTemplate: any;
  PageTemplatePaginator: any;
  PaginatorInfo: any;
  PasswordProtectionInput: any;
  PresetFont: any;
  ProjectAddress: any;
  ProjectConfiguration: any;
  Query: {};
  QueryBlocksOrderByOrderByClause: any;
  QueryCustomFontsOrderByOrderByClause: any;
  QueryFoldersOrderByOrderByClause: any;
  QueryMediaWithFoldersOrderByOrderByClause: any;
  QueryPagesOrderByOrderByClause: any;
  Redirection: any;
  RedirectionPaginator: any;
  RemoveFontInput: any;
  RenameBlockInput: any;
  ResetDesignPresetInput: any;
  RestoreOrCreateColorInput: any;
  Script: any;
  ScriptTranslationInput: any;
  ScriptTranslations: any;
  Seo: any;
  SeoInput: any;
  SetScriptInput: any;
  Setting: any;
  Social: any;
  SocialInput: any;
  Socials: any;
  String: any;
  SwitchFontVariantsInput: any;
  Tag: any;
  TranslationItem: any;
  TranslationPagination: any;
  TranslationsOrderBy: any;
  Trigger: any;
  TriggerGroup: any;
  TriggerGroupInput: any;
  TriggerGroupParam: any;
  TriggerGroupParamInput: any;
  TriggerInput: any;
  UiElement: any;
  UnsplashPhoto: any;
  UnsplashPhotoPaginator: any;
  UpdateBlockInput: any;
  UpdateBreakpointInput: any;
  UpdateColorInput: any;
  UpdateColorSetInput: any;
  UpdateCssVariablesInput: any;
  UpdateDesignPresetInput: any;
  UpdateFolderInput: any;
  UpdateGridInput: any;
  UpdateLanguageInput: any;
  UpdateLegalDocInput: any;
  UpdateMediaInput: any;
  UpdateOverlayInput: any;
  UpdatePageInput: any;
  UpdatePageTemplateInput: any;
  UpdateRedirectionInput: any;
  UpdateUiElementInput: any;
  Upload: any;
  UploadCustomFontInput: any;
  UploadMediaInput: any;
  UploadedMedia: any;
  UpsertTranslationInput: any;
  Url: any;
  Vector: any;
  Video: any;
};

export interface ArrayScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Array'], any> {
  name: 'Array';
}

export type AudioResolvers<ContextType = any, ParentType extends ResolversParentTypes['Audio'] = ResolversParentTypes['Audio']> = {
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  duration?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  extension?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  filename?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  mimeType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  parent?: Resolver<Maybe<ResolversTypes['MediaInterface']>, ParentType, ContextType>;
  parents?: Resolver<Maybe<Array<ResolversTypes['MediaInterface']>>, ParentType, ContextType>;
  size?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  tags?: Resolver<Array<ResolversTypes['Tag']>, ParentType, ContextType>;
  thumbnail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['MediaType'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BlockResolvers<ContextType = any, ParentType extends ResolversParentTypes['Block'] = ResolversParentTypes['Block']> = {
  category?: Resolver<Maybe<ResolversTypes['BlockCategory']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  global?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  industries?: Resolver<Maybe<Array<ResolversTypes['Industry']>>, ParentType, ContextType>;
  ownerId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  projectId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  schema?: Resolver<Maybe<ResolversTypes['Array']>, ParentType, ContextType>;
  thumbnail?: Resolver<Maybe<ResolversTypes['Url']>, ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BlockCategoryResolvers<ContextType = any, ParentType extends ResolversParentTypes['BlockCategory'] = ResolversParentTypes['BlockCategory']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BlockPaginatorResolvers<ContextType = any, ParentType extends ResolversParentTypes['BlockPaginator'] = ResolversParentTypes['BlockPaginator']> = {
  data?: Resolver<Array<ResolversTypes['Block']>, ParentType, ContextType>;
  paginatorInfo?: Resolver<ResolversTypes['PaginatorInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BreakpointResolvers<ContextType = any, ParentType extends ResolversParentTypes['Breakpoint'] = ResolversParentTypes['Breakpoint']> = {
  default?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  selected?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  width?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ColorResolvers<ContextType = any, ParentType extends ResolversParentTypes['Color'] = ResolversParentTypes['Color']> = {
  alias?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  setId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['ColorType'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['Array'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ColorPreferencesResolvers<ContextType = any, ParentType extends ResolversParentTypes['ColorPreferences'] = ResolversParentTypes['ColorPreferences']> = {
  isActive?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  mode?: Resolver<ResolversTypes['ColorPreferencesMode'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ColorSetResolvers<ContextType = any, ParentType extends ResolversParentTypes['ColorSet'] = ResolversParentTypes['ColorSet']> = {
  colors?: Resolver<Maybe<Array<ResolversTypes['Color']>>, ParentType, ContextType>;
  default?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['ColorSetType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ConditionResolvers<ContextType = any, ParentType extends ResolversParentTypes['Condition'] = ResolversParentTypes['Condition']> = {
  params?: Resolver<ResolversTypes['Array'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ConfigurationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Configuration'] = ResolversParentTypes['Configuration']> = {
  browser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  deviceType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  geographicalInfo?: Resolver<Maybe<ResolversTypes['GeographicalInfo']>, ParentType, ContextType>;
  languageInfo?: Resolver<Maybe<Array<ResolversTypes['LanguageInfo']>>, ParentType, ContextType, Partial<ConfigurationLanguageInfoArgs>>;
  operatingSystem?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  projectConfig?: Resolver<ResolversTypes['ProjectConfiguration'], ParentType, ContextType>;
  settings?: Resolver<Array<ResolversTypes['Setting']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CssVariableResolvers<ContextType = any, ParentType extends ResolversParentTypes['CssVariable'] = ResolversParentTypes['CssVariable']> = {
  active?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  alias?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  category?: Resolver<Maybe<ResolversTypes['CssVariableCategory']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['Array'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomFontResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomFont'] = ResolversParentTypes['CustomFont']> = {
  family?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['FontType'], ParentType, ContextType>;
  variants?: Resolver<Array<ResolversTypes['FontVariant']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface DateScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Date'], any> {
  name: 'Date';
}

export interface DateTimeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['DateTime'], any> {
  name: 'DateTime';
}

export interface DateTimeTzScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['DateTimeTz'], any> {
  name: 'DateTimeTz';
}

export type DefaultUiElementResolvers<ContextType = any, ParentType extends ResolversParentTypes['DefaultUiElement'] = ResolversParentTypes['DefaultUiElement']> = {
  content?: Resolver<ResolversTypes['Array'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['UiElementType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DesignPresetResolvers<ContextType = any, ParentType extends ResolversParentTypes['DesignPreset'] = ResolversParentTypes['DesignPreset']> = {
  allCssVariables?: Resolver<Array<ResolversTypes['CssVariable']>, ParentType, ContextType>;
  colorPreferences?: Resolver<ResolversTypes['ColorPreferences'], ParentType, ContextType>;
  colors?: Resolver<Array<ResolversTypes['Color']>, ParentType, ContextType, Partial<DesignPresetColorsArgs>>;
  cssVariables?: Resolver<Array<ResolversTypes['CssVariable']>, ParentType, ContextType, Partial<DesignPresetCssVariablesArgs>>;
  darkMode?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  default?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  firstUiElements?: Resolver<Array<ResolversTypes['UiElement']>, ParentType, ContextType, RequireFields<DesignPresetFirstUiElementsArgs, 'types'>>;
  fonts?: Resolver<Array<ResolversTypes['PresetFont']>, ParentType, ContextType>;
  grids?: Resolver<Maybe<Array<Maybe<ResolversTypes['Grid']>>>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  languages?: Resolver<Maybe<ResolversTypes['Language']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  uiElement?: Resolver<ResolversTypes['UiElement'], ParentType, ContextType, RequireFields<DesignPresetUiElementArgs, 'id'>>;
  uiElements?: Resolver<Array<ResolversTypes['UiElement']>, ParentType, ContextType, Partial<DesignPresetUiElementsArgs>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DocumentResolvers<ContextType = any, ParentType extends ResolversParentTypes['Document'] = ResolversParentTypes['Document']> = {
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  extension?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  filename?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  mimeType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  parent?: Resolver<Maybe<ResolversTypes['MediaInterface']>, ParentType, ContextType>;
  parents?: Resolver<Maybe<Array<ResolversTypes['MediaInterface']>>, ParentType, ContextType>;
  size?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  tags?: Resolver<Array<ResolversTypes['Tag']>, ParentType, ContextType>;
  thumbnail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['MediaType'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FolderResolvers<ContextType = any, ParentType extends ResolversParentTypes['Folder'] = ResolversParentTypes['Folder']> = {
  children?: Resolver<Maybe<Array<Maybe<ResolversTypes['MediaInterface']>>>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  extension?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  filename?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  mimeType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  parent?: Resolver<Maybe<ResolversTypes['MediaInterface']>, ParentType, ContextType>;
  parents?: Resolver<Maybe<Array<ResolversTypes['MediaInterface']>>, ParentType, ContextType>;
  size?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  tags?: Resolver<Array<ResolversTypes['Tag']>, ParentType, ContextType>;
  thumbnail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['MediaType'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FolderPaginatorResolvers<ContextType = any, ParentType extends ResolversParentTypes['FolderPaginator'] = ResolversParentTypes['FolderPaginator']> = {
  data?: Resolver<Array<ResolversTypes['Folder']>, ParentType, ContextType>;
  paginatorInfo?: Resolver<ResolversTypes['PaginatorInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FontDetailsResolvers<ContextType = any, ParentType extends ResolversParentTypes['FontDetails'] = ResolversParentTypes['FontDetails']> = {
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slant?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  thickness?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  width?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FontPaginatorResolvers<ContextType = any, ParentType extends ResolversParentTypes['FontPaginator'] = ResolversParentTypes['FontPaginator']> = {
  data?: Resolver<Array<ResolversTypes['CustomFont']>, ParentType, ContextType>;
  paginatorInfo?: Resolver<ResolversTypes['PaginatorInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FontVariantResolvers<ContextType = any, ParentType extends ResolversParentTypes['FontVariant'] = ResolversParentTypes['FontVariant']> = {
  filename?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  selected?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GeographicalInfoResolvers<ContextType = any, ParentType extends ResolversParentTypes['GeographicalInfo'] = ResolversParentTypes['GeographicalInfo']> = {
  city?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  country?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GoogleFontResolvers<ContextType = any, ParentType extends ResolversParentTypes['GoogleFont'] = ResolversParentTypes['GoogleFont']> = {
  category?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  dateAdded?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  defaultSort?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  family?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  fonts?: Resolver<Array<Maybe<ResolversTypes['FontDetails']>>, ParentType, ContextType>;
  kind?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastModified?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  popularity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  size?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  subsets?: Resolver<Maybe<ResolversTypes['Array']>, ParentType, ContextType>;
  trending?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['FontType'], ParentType, ContextType>;
  variants?: Resolver<Array<ResolversTypes['FontVariant']>, ParentType, ContextType>;
  version?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GridResolvers<ContextType = any, ParentType extends ResolversParentTypes['Grid'] = ResolversParentTypes['Grid']> = {
  designPreset?: Resolver<ResolversTypes['DesignPreset'], ParentType, ContextType>;
  horizontal?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['GridDimension'], ParentType, ContextType>;
  vertical?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ImageResolvers<ContextType = any, ParentType extends ResolversParentTypes['Image'] = ResolversParentTypes['Image']> = {
  alt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  extension?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  filename?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  height?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  mimeType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  parent?: Resolver<Maybe<ResolversTypes['MediaInterface']>, ParentType, ContextType>;
  parents?: Resolver<Maybe<Array<ResolversTypes['MediaInterface']>>, ParentType, ContextType>;
  size?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  tags?: Resolver<Array<ResolversTypes['Tag']>, ParentType, ContextType>;
  thumbnail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['MediaType'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  webPFilename?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  width?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IndustryResolvers<ContextType = any, ParentType extends ResolversParentTypes['Industry'] = ResolversParentTypes['Industry']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LanguageResolvers<ContextType = any, ParentType extends ResolversParentTypes['Language'] = ResolversParentTypes['Language']> = {
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  country?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  default?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  designPreset?: Resolver<Maybe<ResolversTypes['DesignPreset']>, ParentType, ContextType>;
  disabledAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  domainId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  enforceUnicode?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  flag?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  hidden?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  language?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  nativeName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  order?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  rtl?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  unicodeCharacters?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LanguageInfoResolvers<ContextType = any, ParentType extends ResolversParentTypes['LanguageInfo'] = ResolversParentTypes['LanguageInfo']> = {
  canonical?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  country?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  current?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  default?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  designPreset?: Resolver<ResolversTypes['DesignPreset'], ParentType, ContextType>;
  disabledAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  enforceUnicode?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  flag?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  hidden?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hreflang?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  language?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  nativeName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  path?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rtl?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  unicodeCharacters?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LayoutResolvers<ContextType = any, ParentType extends ResolversParentTypes['Layout'] = ResolversParentTypes['Layout']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LayoutBlockHashResolvers<ContextType = any, ParentType extends ResolversParentTypes['LayoutBlockHash'] = ResolversParentTypes['LayoutBlockHash']> = {
  hash?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LegalDocResolvers<ContextType = any, ParentType extends ResolversParentTypes['LegalDoc'] = ResolversParentTypes['LegalDoc']> = {
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['LegalDocType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LimitationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Limitation'] = ResolversParentTypes['Limitation']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  entity?: Resolver<ResolversTypes['LimitationType'], ParentType, ContextType>;
  limit?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  limitReached?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaInterfaceResolvers<ContextType = any, ParentType extends ResolversParentTypes['MediaInterface'] = ResolversParentTypes['MediaInterface']> = {
  __resolveType: TypeResolveFn<'Audio' | 'Document' | 'Folder' | 'Image' | 'Vector' | 'Video', ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  extension?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  filename?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  mimeType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  parent?: Resolver<Maybe<ResolversTypes['MediaInterface']>, ParentType, ContextType>;
  parents?: Resolver<Maybe<Array<ResolversTypes['MediaInterface']>>, ParentType, ContextType>;
  size?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  tags?: Resolver<Array<ResolversTypes['Tag']>, ParentType, ContextType>;
  thumbnail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['MediaType'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
};

export type MediaPaginatorResolvers<ContextType = any, ParentType extends ResolversParentTypes['MediaPaginator'] = ResolversParentTypes['MediaPaginator']> = {
  data?: Resolver<Array<ResolversTypes['MediaUnion']>, ParentType, ContextType>;
  paginatorInfo?: Resolver<ResolversTypes['PaginatorInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaUnionResolvers<ContextType = any, ParentType extends ResolversParentTypes['MediaUnion'] = ResolversParentTypes['MediaUnion']> = {
  __resolveType: TypeResolveFn<'Audio' | 'Document' | 'Folder' | 'Image' | 'Vector' | 'Video', ParentType, ContextType>;
};

export interface MixedScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Mixed'], any> {
  name: 'Mixed';
}

export type MutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation']> = {
  addCustomFontsToPreset?: Resolver<Array<ResolversTypes['PresetFont']>, ParentType, ContextType, RequireFields<MutationAddCustomFontsToPresetArgs, 'input'>>;
  addGoogleFontsToPreset?: Resolver<Array<ResolversTypes['PresetFont']>, ParentType, ContextType, RequireFields<MutationAddGoogleFontsToPresetArgs, 'input'>>;
  attachTagsToMedia?: Resolver<ResolversTypes['MediaUnion'], ParentType, ContextType, RequireFields<MutationAttachTagsToMediaArgs, 'input'>>;
  createBreakpoint?: Resolver<ResolversTypes['Breakpoint'], ParentType, ContextType, RequireFields<MutationCreateBreakpointArgs, 'input'>>;
  createColor?: Resolver<ResolversTypes['Color'], ParentType, ContextType, RequireFields<MutationCreateColorArgs, 'input'>>;
  createComplexColorSet?: Resolver<ResolversTypes['ColorSet'], ParentType, ContextType, RequireFields<MutationCreateComplexColorSetArgs, 'input'>>;
  createDesignPreset?: Resolver<ResolversTypes['DesignPreset'], ParentType, ContextType, RequireFields<MutationCreateDesignPresetArgs, 'input'>>;
  createFolder?: Resolver<ResolversTypes['Folder'], ParentType, ContextType, RequireFields<MutationCreateFolderArgs, 'input'>>;
  createLanguage?: Resolver<ResolversTypes['Language'], ParentType, ContextType, RequireFields<MutationCreateLanguageArgs, 'input'>>;
  createMedia?: Resolver<ResolversTypes['MediaUnion'], ParentType, ContextType, RequireFields<MutationCreateMediaArgs, 'input'>>;
  createOverlay?: Resolver<ResolversTypes['Overlay'], ParentType, ContextType, RequireFields<MutationCreateOverlayArgs, 'input'>>;
  createPage?: Resolver<Array<ResolversTypes['Page']>, ParentType, ContextType, RequireFields<MutationCreatePageArgs, 'input'>>;
  createPageTemplate?: Resolver<Maybe<ResolversTypes['PageTemplate']>, ParentType, ContextType, RequireFields<MutationCreatePageTemplateArgs, 'input'>>;
  createPrimitiveColorSet?: Resolver<ResolversTypes['ColorSet'], ParentType, ContextType, RequireFields<MutationCreatePrimitiveColorSetArgs, 'input'>>;
  createRedirection?: Resolver<ResolversTypes['Redirection'], ParentType, ContextType, RequireFields<MutationCreateRedirectionArgs, 'input'>>;
  createUiElement?: Resolver<ResolversTypes['UiElement'], ParentType, ContextType, RequireFields<MutationCreateUiElementArgs, 'input'>>;
  deleteBlock?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteBlockArgs, 'id'>>;
  deleteBreakpoint?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteBreakpointArgs, 'input'>>;
  deleteColor?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteColorArgs, 'input'>>;
  deleteColorSet?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteColorSetArgs, 'input'>>;
  deleteCssVariable?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteCssVariableArgs, 'input'>>;
  deleteCustomFont?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteCustomFontArgs, 'input'>>;
  deleteCustomFontVariants?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteCustomFontVariantsArgs, 'input'>>;
  deleteDesignPreset?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteDesignPresetArgs, 'input'>>;
  deleteLanguage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteLanguageArgs, 'input'>>;
  deleteMedia?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteMediaArgs, 'input'>>;
  deleteOverlay?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteOverlayArgs, 'id'>>;
  deletePage?: Resolver<Array<ResolversTypes['Page']>, ParentType, ContextType, RequireFields<MutationDeletePageArgs, 'input'>>;
  deleteRedirection?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteRedirectionArgs, 'input'>>;
  deleteUiElement?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteUiElementArgs, 'input'>>;
  detachTagsFromMedia?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<MutationDetachTagsFromMediaArgs, 'input'>>;
  duplicateColorSet?: Resolver<ResolversTypes['ColorSet'], ParentType, ContextType, RequireFields<MutationDuplicateColorSetArgs, 'input'>>;
  duplicateDesignPreset?: Resolver<ResolversTypes['DesignPreset'], ParentType, ContextType, RequireFields<MutationDuplicateDesignPresetArgs, 'input'>>;
  duplicateMedia?: Resolver<Array<ResolversTypes['MediaUnion']>, ParentType, ContextType, RequireFields<MutationDuplicateMediaArgs, 'input'>>;
  duplicateUiElement?: Resolver<ResolversTypes['UiElement'], ParentType, ContextType, RequireFields<MutationDuplicateUiElementArgs, 'input'>>;
  loadRemoteMedia?: Resolver<ResolversTypes['UploadedMedia'], ParentType, ContextType, RequireFields<MutationLoadRemoteMediaArgs, 'input'>>;
  makeDefault?: Resolver<ResolversTypes['Language'], ParentType, ContextType, RequireFields<MutationMakeDefaultArgs, 'input'>>;
  moveMedia?: Resolver<Array<Maybe<ResolversTypes['MediaUnion']>>, ParentType, ContextType, RequireFields<MutationMoveMediaArgs, 'input'>>;
  movePage?: Resolver<Array<ResolversTypes['Page']>, ParentType, ContextType, RequireFields<MutationMovePageArgs, 'input'>>;
  orderLanguages?: Resolver<Array<ResolversTypes['Language']>, ParentType, ContextType, RequireFields<MutationOrderLanguagesArgs, 'input'>>;
  removeFontFromPreset?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationRemoveFontFromPresetArgs, 'input'>>;
  removePageTemplate?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationRemovePageTemplateArgs, 'id'>>;
  renameBlock?: Resolver<ResolversTypes['Array'], ParentType, ContextType, RequireFields<MutationRenameBlockArgs, 'input'>>;
  restoreOrCreateColor?: Resolver<ResolversTypes['Color'], ParentType, ContextType, RequireFields<MutationRestoreOrCreateColorArgs, 'input'>>;
  setPagePassword?: Resolver<Maybe<ResolversTypes['Page']>, ParentType, ContextType, RequireFields<MutationSetPagePasswordArgs, 'input'>>;
  setScripts?: Resolver<Array<ResolversTypes['ScriptTranslations']>, ParentType, ContextType, RequireFields<MutationSetScriptsArgs, 'input'>>;
  switchFontVariants?: Resolver<Array<ResolversTypes['PresetFont']>, ParentType, ContextType, RequireFields<MutationSwitchFontVariantsArgs, 'input'>>;
  updateBlock?: Resolver<ResolversTypes['Block'], ParentType, ContextType, RequireFields<MutationUpdateBlockArgs, 'input'>>;
  updateBreakpoint?: Resolver<ResolversTypes['Breakpoint'], ParentType, ContextType, RequireFields<MutationUpdateBreakpointArgs, 'input'>>;
  updateColor?: Resolver<ResolversTypes['Color'], ParentType, ContextType, RequireFields<MutationUpdateColorArgs, 'input'>>;
  updateColorSet?: Resolver<ResolversTypes['ColorSet'], ParentType, ContextType, RequireFields<MutationUpdateColorSetArgs, 'input'>>;
  updateCssVariables?: Resolver<Array<ResolversTypes['CssVariable']>, ParentType, ContextType, RequireFields<MutationUpdateCssVariablesArgs, 'input'>>;
  updateDesignPreset?: Resolver<ResolversTypes['DesignPreset'], ParentType, ContextType, RequireFields<MutationUpdateDesignPresetArgs, 'input'>>;
  updateFolder?: Resolver<ResolversTypes['Folder'], ParentType, ContextType, RequireFields<MutationUpdateFolderArgs, 'input'>>;
  updateGrid?: Resolver<ResolversTypes['Grid'], ParentType, ContextType, RequireFields<MutationUpdateGridArgs, 'input'>>;
  updateLanguage?: Resolver<ResolversTypes['Language'], ParentType, ContextType, RequireFields<MutationUpdateLanguageArgs, 'input'>>;
  updateLegalDoc?: Resolver<Maybe<ResolversTypes['LegalDoc']>, ParentType, ContextType, RequireFields<MutationUpdateLegalDocArgs, 'input'>>;
  updateMedia?: Resolver<ResolversTypes['MediaUnion'], ParentType, ContextType, RequireFields<MutationUpdateMediaArgs, 'input'>>;
  updateOverlay?: Resolver<ResolversTypes['Overlay'], ParentType, ContextType, RequireFields<MutationUpdateOverlayArgs, 'input'>>;
  updatePage?: Resolver<Maybe<ResolversTypes['Page']>, ParentType, ContextType, RequireFields<MutationUpdatePageArgs, 'input'>>;
  updatePageTemplate?: Resolver<Maybe<ResolversTypes['PageTemplate']>, ParentType, ContextType, RequireFields<MutationUpdatePageTemplateArgs, 'input'>>;
  updateRedirection?: Resolver<ResolversTypes['Redirection'], ParentType, ContextType, RequireFields<MutationUpdateRedirectionArgs, 'input'>>;
  updateUiElement?: Resolver<ResolversTypes['UiElement'], ParentType, ContextType, RequireFields<MutationUpdateUiElementArgs, 'input'>>;
  uploadCustomFont?: Resolver<ResolversTypes['CustomFont'], ParentType, ContextType, RequireFields<MutationUploadCustomFontArgs, 'input'>>;
  uploadMedia?: Resolver<ResolversTypes['UploadedMedia'], ParentType, ContextType, RequireFields<MutationUploadMediaArgs, 'input'>>;
  upsertTranslation?: Resolver<ResolversTypes['TranslationItem'], ParentType, ContextType, RequireFields<MutationUpsertTranslationArgs, 'input'>>;
};

export type OverlayResolvers<ContextType = any, ParentType extends ResolversParentTypes['Overlay'] = ResolversParentTypes['Overlay']> = {
  alias?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  layout?: Resolver<ResolversTypes['Layout'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  params?: Resolver<Maybe<ResolversTypes['Array']>, ParentType, ContextType>;
  triggers?: Resolver<Maybe<Array<ResolversTypes['Trigger']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OverlayPaginatorResolvers<ContextType = any, ParentType extends ResolversParentTypes['OverlayPaginator'] = ResolversParentTypes['OverlayPaginator']> = {
  data?: Resolver<Array<ResolversTypes['Overlay']>, ParentType, ContextType>;
  paginatorInfo?: Resolver<ResolversTypes['PaginatorInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PageResolvers<ContextType = any, ParentType extends ResolversParentTypes['Page'] = ResolversParentTypes['Page']> = {
  alias?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  attributeFamilyGroupType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  attributeFamilyId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  categoryId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  children?: Resolver<Maybe<Array<ResolversTypes['Page']>>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  designPreset?: Resolver<ResolversTypes['DesignPreset'], ParentType, ContextType>;
  disabledAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  group?: Resolver<ResolversTypes['PageGroup'], ParentType, ContextType>;
  hasChildren?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  homepage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  layoutStructure?: Resolver<ResolversTypes['PageLayoutStructure'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  parentId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  password?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  passwordProtection?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  path?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  previousId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  seo?: Resolver<Maybe<ResolversTypes['Seo']>, ParentType, ContextType>;
  social?: Resolver<Maybe<ResolversTypes['Social']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['PageStatus'], ParentType, ContextType>;
  target?: Resolver<ResolversTypes['PageTarget'], ParentType, ContextType>;
  thumbnail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['PageType'], ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  variantsStyles?: Resolver<Maybe<ResolversTypes['Array']>, ParentType, ContextType>;
  visibility?: Resolver<ResolversTypes['PageVisibility'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PagePaginatorResolvers<ContextType = any, ParentType extends ResolversParentTypes['PagePaginator'] = ResolversParentTypes['PagePaginator']> = {
  data?: Resolver<Array<ResolversTypes['Page']>, ParentType, ContextType>;
  paginatorInfo?: Resolver<ResolversTypes['PaginatorInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PageTemplateResolvers<ContextType = any, ParentType extends ResolversParentTypes['PageTemplate'] = ResolversParentTypes['PageTemplate']> = {
  creatorId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  projectId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  thumbnail?: Resolver<Maybe<ResolversTypes['Url']>, ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PageTemplatePaginatorResolvers<ContextType = any, ParentType extends ResolversParentTypes['PageTemplatePaginator'] = ResolversParentTypes['PageTemplatePaginator']> = {
  data?: Resolver<Array<ResolversTypes['PageTemplate']>, ParentType, ContextType>;
  paginatorInfo?: Resolver<ResolversTypes['PaginatorInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaginatorInfoResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaginatorInfo'] = ResolversParentTypes['PaginatorInfo']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  currentPage?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  firstItem?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  hasMorePages?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  lastItem?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  lastPage?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  perPage?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PresetFontResolvers<ContextType = any, ParentType extends ResolversParentTypes['PresetFont'] = ResolversParentTypes['PresetFont']> = {
  designPreset?: Resolver<ResolversTypes['DesignPreset'], ParentType, ContextType>;
  family?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['FontType'], ParentType, ContextType>;
  variants?: Resolver<Array<ResolversTypes['FontVariant']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProjectAddressResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProjectAddress'] = ResolversParentTypes['ProjectAddress']> = {
  address?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  businessLegalName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  city?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  phoneNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  state?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  zipCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProjectConfigurationResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProjectConfiguration'] = ResolversParentTypes['ProjectConfiguration']> = {
  address?: Resolver<ResolversTypes['ProjectAddress'], ParentType, ContextType>;
  darkModeLogo?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  favicon?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  isExpired?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isOffline?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  languageDetectionMode?: Resolver<ResolversTypes['LanguageDetectionMode'], ParentType, ContextType>;
  logo?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  mobileDarkModeLogo?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  mobileLogo?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  permissions?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  previewUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  projectId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  projectName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  screenshotUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  scripts?: Resolver<Maybe<ResolversTypes['Script']>, ParentType, ContextType>;
  socials?: Resolver<Array<ResolversTypes['Socials']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['ProjectStatus'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QueryResolvers<ContextType = any, ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']> = {
  allMediaFlatten?: Resolver<ResolversTypes['MediaPaginator'], ParentType, ContextType, RequireFields<QueryAllMediaFlattenArgs, 'type'>>;
  block?: Resolver<Maybe<ResolversTypes['Block']>, ParentType, ContextType, RequireFields<QueryBlockArgs, 'id'>>;
  blockCategories?: Resolver<Array<ResolversTypes['BlockCategory']>, ParentType, ContextType>;
  blocks?: Resolver<Maybe<ResolversTypes['BlockPaginator']>, ParentType, ContextType, Partial<QueryBlocksArgs>>;
  breakpoints?: Resolver<Array<ResolversTypes['Breakpoint']>, ParentType, ContextType>;
  colorSets?: Resolver<Array<ResolversTypes['ColorSet']>, ParentType, ContextType, RequireFields<QueryColorSetsArgs, 'designPresetId'>>;
  configurations?: Resolver<ResolversTypes['Configuration'], ParentType, ContextType>;
  customFonts?: Resolver<ResolversTypes['FontPaginator'], ParentType, ContextType, Partial<QueryCustomFontsArgs>>;
  defaultUiElement?: Resolver<ResolversTypes['DefaultUiElement'], ParentType, ContextType, RequireFields<QueryDefaultUiElementArgs, 'type'>>;
  designPreset?: Resolver<Maybe<ResolversTypes['DesignPreset']>, ParentType, ContextType, RequireFields<QueryDesignPresetArgs, 'id'>>;
  designPresets?: Resolver<Array<ResolversTypes['DesignPreset']>, ParentType, ContextType>;
  entityCountLimitation?: Resolver<Maybe<Array<ResolversTypes['Limitation']>>, ParentType, ContextType, RequireFields<QueryEntityCountLimitationArgs, 'entities'>>;
  folder?: Resolver<Maybe<ResolversTypes['Folder']>, ParentType, ContextType, RequireFields<QueryFolderArgs, 'id'>>;
  folders?: Resolver<ResolversTypes['FolderPaginator'], ParentType, ContextType, RequireFields<QueryFoldersArgs, 'first' | 'type'>>;
  googleFonts?: Resolver<Array<ResolversTypes['GoogleFont']>, ParentType, ContextType>;
  language?: Resolver<ResolversTypes['Language'], ParentType, ContextType, RequireFields<QueryLanguageArgs, 'id'>>;
  languages?: Resolver<Array<ResolversTypes['Language']>, ParentType, ContextType>;
  legalDoc?: Resolver<ResolversTypes['LegalDoc'], ParentType, ContextType, RequireFields<QueryLegalDocArgs, 'id'>>;
  legalDocs?: Resolver<Maybe<Array<ResolversTypes['LegalDoc']>>, ParentType, ContextType>;
  media?: Resolver<Maybe<ResolversTypes['MediaUnion']>, ParentType, ContextType, RequireFields<QueryMediaArgs, 'id' | 'type'>>;
  mediaByIds?: Resolver<Array<ResolversTypes['MediaUnion']>, ParentType, ContextType, RequireFields<QueryMediaByIdsArgs, 'ids'>>;
  mediaWithFolders?: Resolver<ResolversTypes['MediaPaginator'], ParentType, ContextType, RequireFields<QueryMediaWithFoldersArgs, 'type'>>;
  overlay?: Resolver<Maybe<ResolversTypes['Overlay']>, ParentType, ContextType, Partial<QueryOverlayArgs>>;
  overlays?: Resolver<ResolversTypes['OverlayPaginator'], ParentType, ContextType, RequireFields<QueryOverlaysArgs, 'first'>>;
  page?: Resolver<Maybe<ResolversTypes['Page']>, ParentType, ContextType, RequireFields<QueryPageArgs, 'id'>>;
  pageByAlias?: Resolver<Maybe<ResolversTypes['Page']>, ParentType, ContextType, RequireFields<QueryPageByAliasArgs, 'alias'>>;
  pageOverlays?: Resolver<Array<ResolversTypes['Overlay']>, ParentType, ContextType, RequireFields<QueryPageOverlaysArgs, 'entityType'>>;
  pageTemplate?: Resolver<Maybe<ResolversTypes['PageTemplate']>, ParentType, ContextType, RequireFields<QueryPageTemplateArgs, 'id'>>;
  pageTemplates?: Resolver<Maybe<ResolversTypes['PageTemplatePaginator']>, ParentType, ContextType, Partial<QueryPageTemplatesArgs>>;
  pages?: Resolver<Maybe<ResolversTypes['PagePaginator']>, ParentType, ContextType, Partial<QueryPagesArgs>>;
  redirections?: Resolver<ResolversTypes['RedirectionPaginator'], ParentType, ContextType, RequireFields<QueryRedirectionsArgs, 'first'>>;
  script?: Resolver<Maybe<Array<ResolversTypes['Script']>>, ParentType, ContextType>;
  translations?: Resolver<ResolversTypes['TranslationPagination'], ParentType, ContextType, RequireFields<QueryTranslationsArgs, 'languageCode'>>;
  unsplashImages?: Resolver<Maybe<ResolversTypes['UnsplashPhotoPaginator']>, ParentType, ContextType, RequireFields<QueryUnsplashImagesArgs, 'first'>>;
};

export type RedirectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['Redirection'] = ResolversParentTypes['Redirection']> = {
  from?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['RedirectionStatus'], ParentType, ContextType>;
  to?: Resolver<ResolversTypes['Url'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['RedirectionType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RedirectionPaginatorResolvers<ContextType = any, ParentType extends ResolversParentTypes['RedirectionPaginator'] = ResolversParentTypes['RedirectionPaginator']> = {
  data?: Resolver<Array<ResolversTypes['Redirection']>, ParentType, ContextType>;
  paginatorInfo?: Resolver<ResolversTypes['PaginatorInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ScriptResolvers<ContextType = any, ParentType extends ResolversParentTypes['Script'] = ResolversParentTypes['Script']> = {
  content?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  position?: Resolver<ResolversTypes['ScriptPosition'], ParentType, ContextType>;
  translations?: Resolver<Array<ResolversTypes['ScriptTranslations']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ScriptTranslationsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ScriptTranslations'] = ResolversParentTypes['ScriptTranslations']> = {
  content?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  languageCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  position?: Resolver<ResolversTypes['ScriptPosition'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SeoResolvers<ContextType = any, ParentType extends ResolversParentTypes['Seo'] = ResolversParentTypes['Seo']> = {
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  robot?: Resolver<ResolversTypes['SeoRobot'], ParentType, ContextType>;
  schema?: Resolver<ResolversTypes['SeoSchemaMarkup'], ParentType, ContextType>;
  sitemap?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SettingResolvers<ContextType = any, ParentType extends ResolversParentTypes['Setting'] = ResolversParentTypes['Setting']> = {
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SocialResolvers<ContextType = any, ParentType extends ResolversParentTypes['Social'] = ResolversParentTypes['Social']> = {
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  image?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SocialsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Socials'] = ResolversParentTypes['Socials']> = {
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  order?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TagResolvers<ContextType = any, ParentType extends ResolversParentTypes['Tag'] = ResolversParentTypes['Tag']> = {
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  media?: Resolver<Array<ResolversTypes['MediaUnion']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TranslationItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['TranslationItem'] = ResolversParentTypes['TranslationItem']> = {
  defaultValue?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  editorName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  languageCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  params?: Resolver<Maybe<ResolversTypes['Array']>, ParentType, ContextType>;
  translationKey?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TranslationPaginationResolvers<ContextType = any, ParentType extends ResolversParentTypes['TranslationPagination'] = ResolversParentTypes['TranslationPagination']> = {
  data?: Resolver<Array<ResolversTypes['TranslationItem']>, ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TriggerResolvers<ContextType = any, ParentType extends ResolversParentTypes['Trigger'] = ResolversParentTypes['Trigger']> = {
  conditionOperator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  conditions?: Resolver<Maybe<Array<Maybe<ResolversTypes['Condition']>>>, ParentType, ContextType>;
  triggerGroups?: Resolver<Maybe<Array<Maybe<ResolversTypes['TriggerGroup']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TriggerGroupResolvers<ContextType = any, ParentType extends ResolversParentTypes['TriggerGroup'] = ResolversParentTypes['TriggerGroup']> = {
  params?: Resolver<ResolversTypes['TriggerGroupParam'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TriggerGroupParamResolvers<ContextType = any, ParentType extends ResolversParentTypes['TriggerGroupParam'] = ResolversParentTypes['TriggerGroupParam']> = {
  operator?: Resolver<ResolversTypes['TriggerOperator'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['TriggerEntityType'], ParentType, ContextType>;
  values?: Resolver<ResolversTypes['Array'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UiElementResolvers<ContextType = any, ParentType extends ResolversParentTypes['UiElement'] = ResolversParentTypes['UiElement']> = {
  content?: Resolver<ResolversTypes['Array'], ParentType, ContextType>;
  darkMode?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  designPreset?: Resolver<ResolversTypes['DesignPreset'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['UiElementType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UnsplashPhotoResolvers<ContextType = any, ParentType extends ResolversParentTypes['UnsplashPhoto'] = ResolversParentTypes['UnsplashPhoto']> = {
  altDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  height?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  likes?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  profileImageSmall?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  regularUrl?: Resolver<Maybe<ResolversTypes['Url']>, ParentType, ContextType>;
  tags?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  thumbUrl?: Resolver<Maybe<ResolversTypes['Url']>, ParentType, ContextType>;
  twitterUsername?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  userProfileLink?: Resolver<Maybe<ResolversTypes['Url']>, ParentType, ContextType>;
  username?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  width?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UnsplashPhotoPaginatorResolvers<ContextType = any, ParentType extends ResolversParentTypes['UnsplashPhotoPaginator'] = ResolversParentTypes['UnsplashPhotoPaginator']> = {
  data?: Resolver<Array<ResolversTypes['UnsplashPhoto']>, ParentType, ContextType>;
  paginatorInfo?: Resolver<ResolversTypes['PaginatorInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface UploadScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Upload'], any> {
  name: 'Upload';
}

export type UploadedMediaResolvers<ContextType = any, ParentType extends ResolversParentTypes['UploadedMedia'] = ResolversParentTypes['UploadedMedia']> = {
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  thumbnail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface UrlScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Url'], any> {
  name: 'Url';
}

export type VectorResolvers<ContextType = any, ParentType extends ResolversParentTypes['Vector'] = ResolversParentTypes['Vector']> = {
  alt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  extension?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  filename?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  mimeType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  parent?: Resolver<Maybe<ResolversTypes['MediaInterface']>, ParentType, ContextType>;
  parents?: Resolver<Maybe<Array<ResolversTypes['MediaInterface']>>, ParentType, ContextType>;
  size?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  tags?: Resolver<Array<ResolversTypes['Tag']>, ParentType, ContextType>;
  thumbnail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['MediaType'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VideoResolvers<ContextType = any, ParentType extends ResolversParentTypes['Video'] = ResolversParentTypes['Video']> = {
  alt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  duration?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  extension?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  filename?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  mimeType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  parent?: Resolver<Maybe<ResolversTypes['MediaInterface']>, ParentType, ContextType>;
  parents?: Resolver<Maybe<Array<ResolversTypes['MediaInterface']>>, ParentType, ContextType>;
  size?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  tags?: Resolver<Array<ResolversTypes['Tag']>, ParentType, ContextType>;
  thumbnail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['MediaType'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  webPThumbnail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Resolvers<ContextType = any> = {
  Array?: GraphQLScalarType;
  Audio?: AudioResolvers<ContextType>;
  Block?: BlockResolvers<ContextType>;
  BlockCategory?: BlockCategoryResolvers<ContextType>;
  BlockPaginator?: BlockPaginatorResolvers<ContextType>;
  Breakpoint?: BreakpointResolvers<ContextType>;
  Color?: ColorResolvers<ContextType>;
  ColorPreferences?: ColorPreferencesResolvers<ContextType>;
  ColorSet?: ColorSetResolvers<ContextType>;
  Condition?: ConditionResolvers<ContextType>;
  Configuration?: ConfigurationResolvers<ContextType>;
  CssVariable?: CssVariableResolvers<ContextType>;
  CustomFont?: CustomFontResolvers<ContextType>;
  Date?: GraphQLScalarType;
  DateTime?: GraphQLScalarType;
  DateTimeTz?: GraphQLScalarType;
  DefaultUiElement?: DefaultUiElementResolvers<ContextType>;
  DesignPreset?: DesignPresetResolvers<ContextType>;
  Document?: DocumentResolvers<ContextType>;
  Folder?: FolderResolvers<ContextType>;
  FolderPaginator?: FolderPaginatorResolvers<ContextType>;
  FontDetails?: FontDetailsResolvers<ContextType>;
  FontPaginator?: FontPaginatorResolvers<ContextType>;
  FontVariant?: FontVariantResolvers<ContextType>;
  GeographicalInfo?: GeographicalInfoResolvers<ContextType>;
  GoogleFont?: GoogleFontResolvers<ContextType>;
  Grid?: GridResolvers<ContextType>;
  Image?: ImageResolvers<ContextType>;
  Industry?: IndustryResolvers<ContextType>;
  Language?: LanguageResolvers<ContextType>;
  LanguageInfo?: LanguageInfoResolvers<ContextType>;
  Layout?: LayoutResolvers<ContextType>;
  LayoutBlockHash?: LayoutBlockHashResolvers<ContextType>;
  LegalDoc?: LegalDocResolvers<ContextType>;
  Limitation?: LimitationResolvers<ContextType>;
  MediaInterface?: MediaInterfaceResolvers<ContextType>;
  MediaPaginator?: MediaPaginatorResolvers<ContextType>;
  MediaUnion?: MediaUnionResolvers<ContextType>;
  Mixed?: GraphQLScalarType;
  Mutation?: MutationResolvers<ContextType>;
  Overlay?: OverlayResolvers<ContextType>;
  OverlayPaginator?: OverlayPaginatorResolvers<ContextType>;
  Page?: PageResolvers<ContextType>;
  PagePaginator?: PagePaginatorResolvers<ContextType>;
  PageTemplate?: PageTemplateResolvers<ContextType>;
  PageTemplatePaginator?: PageTemplatePaginatorResolvers<ContextType>;
  PaginatorInfo?: PaginatorInfoResolvers<ContextType>;
  PresetFont?: PresetFontResolvers<ContextType>;
  ProjectAddress?: ProjectAddressResolvers<ContextType>;
  ProjectConfiguration?: ProjectConfigurationResolvers<ContextType>;
  Query?: QueryResolvers<ContextType>;
  Redirection?: RedirectionResolvers<ContextType>;
  RedirectionPaginator?: RedirectionPaginatorResolvers<ContextType>;
  Script?: ScriptResolvers<ContextType>;
  ScriptTranslations?: ScriptTranslationsResolvers<ContextType>;
  Seo?: SeoResolvers<ContextType>;
  Setting?: SettingResolvers<ContextType>;
  Social?: SocialResolvers<ContextType>;
  Socials?: SocialsResolvers<ContextType>;
  Tag?: TagResolvers<ContextType>;
  TranslationItem?: TranslationItemResolvers<ContextType>;
  TranslationPagination?: TranslationPaginationResolvers<ContextType>;
  Trigger?: TriggerResolvers<ContextType>;
  TriggerGroup?: TriggerGroupResolvers<ContextType>;
  TriggerGroupParam?: TriggerGroupParamResolvers<ContextType>;
  UiElement?: UiElementResolvers<ContextType>;
  UnsplashPhoto?: UnsplashPhotoResolvers<ContextType>;
  UnsplashPhotoPaginator?: UnsplashPhotoPaginatorResolvers<ContextType>;
  Upload?: GraphQLScalarType;
  UploadedMedia?: UploadedMediaResolvers<ContextType>;
  Url?: GraphQLScalarType;
  Vector?: VectorResolvers<ContextType>;
  Video?: VideoResolvers<ContextType>;
};


export type DesignPresetFragmentFragment = { __typename?: 'DesignPreset', id: string, name: string, default: boolean, darkMode: boolean, fonts: Array<{ __typename?: 'PresetFont', type: FontType, family: string, variants: Array<{ __typename?: 'FontVariant', id: string, name: string, selected: boolean, filename: string }> }>, cssVariables: Array<{ __typename?: 'CssVariable', name: string, value: any, active: boolean }>, uiElements: Array<{ __typename?: 'UiElement', id: string, type: UiElementType, name: string, darkMode: boolean }>, colors: Array<{ __typename?: 'Color', id: string, name: string, value: any }> };

export type LanguageFragmentFragment = { __typename?: 'Language', unicodeCharacters?: string | null, enforceUnicode: boolean, nativeName: string, language: string, country: string, default: boolean, hidden: boolean, order: number, flag?: string | null, name: string, code: string, slug: string, id: string, rtl: boolean, domainId?: string | null, disabledAt?: any | null, designPreset?: { __typename?: 'DesignPreset', id: string, name: string, default: boolean, darkMode: boolean, fonts: Array<{ __typename?: 'PresetFont', type: FontType, family: string, variants: Array<{ __typename?: 'FontVariant', id: string, name: string, selected: boolean, filename: string }> }>, cssVariables: Array<{ __typename?: 'CssVariable', name: string, value: any, active: boolean }>, uiElements: Array<{ __typename?: 'UiElement', id: string, type: UiElementType, name: string, darkMode: boolean }>, colors: Array<{ __typename?: 'Color', id: string, name: string, value: any }> } | null };

type MediaFileFragment_Audio_Fragment = { __typename?: 'Audio', id: string, name: string, type: MediaType, thumbnail?: string | null, size: number, createdAt: any, filename: string, tags: Array<{ __typename?: 'Tag', name: string }> };

type MediaFileFragment_Document_Fragment = { __typename?: 'Document', id: string, name: string, type: MediaType, thumbnail?: string | null, size: number, createdAt: any, filename: string, tags: Array<{ __typename?: 'Tag', name: string }> };

type MediaFileFragment_Folder_Fragment = { __typename?: 'Folder', id: string, name: string, type: MediaType, thumbnail?: string | null, size: number, createdAt: any, filename: string, tags: Array<{ __typename?: 'Tag', name: string }> };

type MediaFileFragment_Image_Fragment = { __typename?: 'Image', id: string, name: string, type: MediaType, thumbnail?: string | null, size: number, createdAt: any, filename: string, tags: Array<{ __typename?: 'Tag', name: string }> };

type MediaFileFragment_Vector_Fragment = { __typename?: 'Vector', id: string, name: string, type: MediaType, thumbnail?: string | null, size: number, createdAt: any, filename: string, tags: Array<{ __typename?: 'Tag', name: string }> };

type MediaFileFragment_Video_Fragment = { __typename?: 'Video', id: string, name: string, type: MediaType, thumbnail?: string | null, size: number, createdAt: any, filename: string, tags: Array<{ __typename?: 'Tag', name: string }> };

export type MediaFileFragmentFragment = MediaFileFragment_Audio_Fragment | MediaFileFragment_Document_Fragment | MediaFileFragment_Folder_Fragment | MediaFileFragment_Image_Fragment | MediaFileFragment_Vector_Fragment | MediaFileFragment_Video_Fragment;

export type OrderLanguagesMutationVariables = Exact<{
  input: OrderLanguagesInput;
}>;


export type OrderLanguagesMutation = { __typename?: 'Mutation', orderLanguages: Array<{ __typename?: 'Language', unicodeCharacters?: string | null, enforceUnicode: boolean, nativeName: string, language: string, country: string, default: boolean, hidden: boolean, order: number, flag?: string | null, name: string, code: string, slug: string, id: string, rtl: boolean, domainId?: string | null, disabledAt?: any | null, designPreset?: { __typename?: 'DesignPreset', id: string, name: string, default: boolean, darkMode: boolean, fonts: Array<{ __typename?: 'PresetFont', type: FontType, family: string, variants: Array<{ __typename?: 'FontVariant', id: string, name: string, selected: boolean, filename: string }> }>, cssVariables: Array<{ __typename?: 'CssVariable', name: string, value: any, active: boolean }>, uiElements: Array<{ __typename?: 'UiElement', id: string, type: UiElementType, name: string, darkMode: boolean }>, colors: Array<{ __typename?: 'Color', id: string, name: string, value: any }> } | null }> };

export type CreateLanguageMutationVariables = Exact<{
  input: CreateLanguageInput;
}>;


export type CreateLanguageMutation = { __typename?: 'Mutation', createLanguage: { __typename?: 'Language', unicodeCharacters?: string | null, enforceUnicode: boolean, nativeName: string, language: string, country: string, default: boolean, hidden: boolean, order: number, flag?: string | null, name: string, code: string, slug: string, id: string, rtl: boolean, domainId?: string | null, disabledAt?: any | null, designPreset?: { __typename?: 'DesignPreset', id: string, name: string, default: boolean, darkMode: boolean, fonts: Array<{ __typename?: 'PresetFont', type: FontType, family: string, variants: Array<{ __typename?: 'FontVariant', id: string, name: string, selected: boolean, filename: string }> }>, cssVariables: Array<{ __typename?: 'CssVariable', name: string, value: any, active: boolean }>, uiElements: Array<{ __typename?: 'UiElement', id: string, type: UiElementType, name: string, darkMode: boolean }>, colors: Array<{ __typename?: 'Color', id: string, name: string, value: any }> } | null } };

export type UpdateLanguageMutationVariables = Exact<{
  input: UpdateLanguageInput;
}>;


export type UpdateLanguageMutation = { __typename?: 'Mutation', updateLanguage: { __typename?: 'Language', unicodeCharacters?: string | null, enforceUnicode: boolean, nativeName: string, language: string, country: string, default: boolean, hidden: boolean, order: number, flag?: string | null, name: string, code: string, slug: string, id: string, rtl: boolean, domainId?: string | null, disabledAt?: any | null, designPreset?: { __typename?: 'DesignPreset', id: string, name: string, default: boolean, darkMode: boolean, fonts: Array<{ __typename?: 'PresetFont', type: FontType, family: string, variants: Array<{ __typename?: 'FontVariant', id: string, name: string, selected: boolean, filename: string }> }>, cssVariables: Array<{ __typename?: 'CssVariable', name: string, value: any, active: boolean }>, uiElements: Array<{ __typename?: 'UiElement', id: string, type: UiElementType, name: string, darkMode: boolean }>, colors: Array<{ __typename?: 'Color', id: string, name: string, value: any }> } | null } };

export type DeleteLanguageMutationVariables = Exact<{
  input: DeleteLanguageInput;
}>;


export type DeleteLanguageMutation = { __typename?: 'Mutation', deleteLanguage: boolean };

export type MakeDefaultMutationVariables = Exact<{
  input: MakeDefaultInput;
}>;


export type MakeDefaultMutation = { __typename?: 'Mutation', makeDefault: { __typename?: 'Language', unicodeCharacters?: string | null, enforceUnicode: boolean, nativeName: string, language: string, country: string, default: boolean, hidden: boolean, order: number, flag?: string | null, name: string, code: string, slug: string, id: string, rtl: boolean, domainId?: string | null, disabledAt?: any | null, designPreset?: { __typename?: 'DesignPreset', id: string, name: string, default: boolean, darkMode: boolean, fonts: Array<{ __typename?: 'PresetFont', type: FontType, family: string, variants: Array<{ __typename?: 'FontVariant', id: string, name: string, selected: boolean, filename: string }> }>, cssVariables: Array<{ __typename?: 'CssVariable', name: string, value: any, active: boolean }>, uiElements: Array<{ __typename?: 'UiElement', id: string, type: UiElementType, name: string, darkMode: boolean }>, colors: Array<{ __typename?: 'Color', id: string, name: string, value: any }> } | null } };

export type UpdateLegalDocMutationVariables = Exact<{
  input: UpdateLegalDocInput;
}>;


export type UpdateLegalDocMutation = { __typename?: 'Mutation', updateLegalDoc?: { __typename?: 'LegalDoc', id: string, type: LegalDocType, description: string } | null };

export type SetScriptsMutationVariables = Exact<{
  input: SetScriptInput;
}>;


export type SetScriptsMutation = { __typename?: 'Mutation', setScripts: Array<{ __typename?: 'ScriptTranslations', content: string, languageCode: string, position: ScriptPosition }> };

export type ConfigurationsQueryVariables = Exact<{ [key: string]: never; }>;


export type ConfigurationsQuery = { __typename?: 'Query', configurations: { __typename?: 'Configuration', settings: Array<{ __typename?: 'Setting', name: string, value: string }> } };

export type LanguagesQueryVariables = Exact<{ [key: string]: never; }>;


export type LanguagesQuery = { __typename?: 'Query', languages: Array<{ __typename?: 'Language', unicodeCharacters?: string | null, enforceUnicode: boolean, nativeName: string, language: string, country: string, default: boolean, hidden: boolean, order: number, flag?: string | null, name: string, code: string, slug: string, id: string, rtl: boolean, domainId?: string | null, disabledAt?: any | null, designPreset?: { __typename?: 'DesignPreset', id: string, name: string, default: boolean, darkMode: boolean, fonts: Array<{ __typename?: 'PresetFont', type: FontType, family: string, variants: Array<{ __typename?: 'FontVariant', id: string, name: string, selected: boolean, filename: string }> }>, cssVariables: Array<{ __typename?: 'CssVariable', name: string, value: any, active: boolean }>, uiElements: Array<{ __typename?: 'UiElement', id: string, type: UiElementType, name: string, darkMode: boolean }>, colors: Array<{ __typename?: 'Color', id: string, name: string, value: any }> } | null }> };

export type MediaByIdsQueryVariables = Exact<{
  ids: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;


export type MediaByIdsQuery = { __typename?: 'Query', mediaByIds: Array<{ __typename?: 'Audio', id: string, name: string, type: MediaType, thumbnail?: string | null, size: number, createdAt: any, filename: string, tags: Array<{ __typename?: 'Tag', name: string }> } | { __typename?: 'Document', id: string, name: string, type: MediaType, thumbnail?: string | null, size: number, createdAt: any, filename: string, tags: Array<{ __typename?: 'Tag', name: string }> } | { __typename?: 'Folder', id: string, name: string, type: MediaType, thumbnail?: string | null, size: number, createdAt: any, filename: string, tags: Array<{ __typename?: 'Tag', name: string }> } | { __typename?: 'Image', id: string, name: string, type: MediaType, thumbnail?: string | null, size: number, createdAt: any, filename: string, tags: Array<{ __typename?: 'Tag', name: string }> } | { __typename?: 'Vector', id: string, name: string, type: MediaType, thumbnail?: string | null, size: number, createdAt: any, filename: string, tags: Array<{ __typename?: 'Tag', name: string }> } | { __typename?: 'Video', id: string, name: string, type: MediaType, thumbnail?: string | null, size: number, createdAt: any, filename: string, tags: Array<{ __typename?: 'Tag', name: string }> }> };

export type LanguageQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type LanguageQuery = { __typename?: 'Query', language: { __typename?: 'Language', unicodeCharacters?: string | null, enforceUnicode: boolean, nativeName: string, language: string, country: string, default: boolean, hidden: boolean, order: number, flag?: string | null, name: string, code: string, slug: string, id: string, rtl: boolean, domainId?: string | null, disabledAt?: any | null, designPreset?: { __typename?: 'DesignPreset', id: string, name: string, default: boolean, darkMode: boolean, fonts: Array<{ __typename?: 'PresetFont', type: FontType, family: string, variants: Array<{ __typename?: 'FontVariant', id: string, name: string, selected: boolean, filename: string }> }>, cssVariables: Array<{ __typename?: 'CssVariable', name: string, value: any, active: boolean }>, uiElements: Array<{ __typename?: 'UiElement', id: string, type: UiElementType, name: string, darkMode: boolean }>, colors: Array<{ __typename?: 'Color', id: string, name: string, value: any }> } | null } };

export type DesignPresetsQueryVariables = Exact<{ [key: string]: never; }>;


export type DesignPresetsQuery = { __typename?: 'Query', designPresets: Array<{ __typename?: 'DesignPreset', id: string, name: string, default: boolean, darkMode: boolean, fonts: Array<{ __typename?: 'PresetFont', type: FontType, family: string, variants: Array<{ __typename?: 'FontVariant', id: string, name: string, selected: boolean, filename: string }> }>, cssVariables: Array<{ __typename?: 'CssVariable', name: string, value: any, active: boolean }>, uiElements: Array<{ __typename?: 'UiElement', id: string, type: UiElementType, name: string, darkMode: boolean }>, colors: Array<{ __typename?: 'Color', id: string, name: string, value: any }> }> };

export type LegalDocsQueryVariables = Exact<{ [key: string]: never; }>;


export type LegalDocsQuery = { __typename?: 'Query', legalDocs?: Array<{ __typename?: 'LegalDoc', id: string, type: LegalDocType }> | null };

export type LegalDocQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type LegalDocQuery = { __typename?: 'Query', legalDoc: { __typename?: 'LegalDoc', id: string, type: LegalDocType, description: string } };

export type ScriptQueryVariables = Exact<{ [key: string]: never; }>;


export type ScriptQuery = { __typename?: 'Query', script?: Array<{ __typename?: 'Script', position: ScriptPosition, content: string, translations: Array<{ __typename?: 'ScriptTranslations', languageCode: string, position: ScriptPosition, content: string }> }> | null };

export const DesignPresetFragmentFragmentDoc = gql`
    fragment designPresetFragment on DesignPreset {
  id
  name
  default
  darkMode
  fonts {
    type
    family
    variants {
      id
      name
      selected
      filename
    }
  }
  cssVariables {
    name
    value
    active
  }
  uiElements(types: [BUTTON]) {
    id
    type
    name
    darkMode
  }
  colors {
    id
    name
    value
  }
}
    `;
export const LanguageFragmentFragmentDoc = gql`
    fragment languageFragment on Language {
  unicodeCharacters
  enforceUnicode
  nativeName
  language
  country
  default
  hidden
  order
  flag
  name
  code
  slug
  id
  rtl
  domainId
  disabledAt
  designPreset {
    ...designPresetFragment
  }
}
    ${DesignPresetFragmentFragmentDoc}`;
export const MediaFileFragmentFragmentDoc = gql`
    fragment MediaFileFragment on MediaInterface {
  id
  name
  type
  thumbnail
  size
  createdAt
  filename
  tags {
    name
  }
}
    `;
export const OrderLanguagesDocument = gql`
    mutation orderLanguages($input: OrderLanguagesInput!) {
  orderLanguages(input: $input) {
    ...languageFragment
  }
}
    ${LanguageFragmentFragmentDoc}`;
export type OrderLanguagesMutationFn = Apollo.MutationFunction<OrderLanguagesMutation, OrderLanguagesMutationVariables>;

/**
 * __useOrderLanguagesMutation__
 *
 * To run a mutation, you first call `useOrderLanguagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrderLanguagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderLanguagesMutation, { data, loading, error }] = useOrderLanguagesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOrderLanguagesMutation(baseOptions?: Apollo.MutationHookOptions<OrderLanguagesMutation, OrderLanguagesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OrderLanguagesMutation, OrderLanguagesMutationVariables>(OrderLanguagesDocument, options);
      }
export type OrderLanguagesMutationHookResult = ReturnType<typeof useOrderLanguagesMutation>;
export type OrderLanguagesMutationResult = Apollo.MutationResult<OrderLanguagesMutation>;
export type OrderLanguagesMutationOptions = Apollo.BaseMutationOptions<OrderLanguagesMutation, OrderLanguagesMutationVariables>;
export const CreateLanguageDocument = gql`
    mutation createLanguage($input: CreateLanguageInput!) {
  createLanguage(input: $input) {
    ...languageFragment
  }
}
    ${LanguageFragmentFragmentDoc}`;
export type CreateLanguageMutationFn = Apollo.MutationFunction<CreateLanguageMutation, CreateLanguageMutationVariables>;

/**
 * __useCreateLanguageMutation__
 *
 * To run a mutation, you first call `useCreateLanguageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLanguageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLanguageMutation, { data, loading, error }] = useCreateLanguageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateLanguageMutation(baseOptions?: Apollo.MutationHookOptions<CreateLanguageMutation, CreateLanguageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateLanguageMutation, CreateLanguageMutationVariables>(CreateLanguageDocument, options);
      }
export type CreateLanguageMutationHookResult = ReturnType<typeof useCreateLanguageMutation>;
export type CreateLanguageMutationResult = Apollo.MutationResult<CreateLanguageMutation>;
export type CreateLanguageMutationOptions = Apollo.BaseMutationOptions<CreateLanguageMutation, CreateLanguageMutationVariables>;
export const UpdateLanguageDocument = gql`
    mutation updateLanguage($input: UpdateLanguageInput!) {
  updateLanguage(input: $input) {
    ...languageFragment
  }
}
    ${LanguageFragmentFragmentDoc}`;
export type UpdateLanguageMutationFn = Apollo.MutationFunction<UpdateLanguageMutation, UpdateLanguageMutationVariables>;

/**
 * __useUpdateLanguageMutation__
 *
 * To run a mutation, you first call `useUpdateLanguageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLanguageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLanguageMutation, { data, loading, error }] = useUpdateLanguageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateLanguageMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLanguageMutation, UpdateLanguageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLanguageMutation, UpdateLanguageMutationVariables>(UpdateLanguageDocument, options);
      }
export type UpdateLanguageMutationHookResult = ReturnType<typeof useUpdateLanguageMutation>;
export type UpdateLanguageMutationResult = Apollo.MutationResult<UpdateLanguageMutation>;
export type UpdateLanguageMutationOptions = Apollo.BaseMutationOptions<UpdateLanguageMutation, UpdateLanguageMutationVariables>;
export const DeleteLanguageDocument = gql`
    mutation deleteLanguage($input: DeleteLanguageInput!) {
  deleteLanguage(input: $input)
}
    `;
export type DeleteLanguageMutationFn = Apollo.MutationFunction<DeleteLanguageMutation, DeleteLanguageMutationVariables>;

/**
 * __useDeleteLanguageMutation__
 *
 * To run a mutation, you first call `useDeleteLanguageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLanguageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLanguageMutation, { data, loading, error }] = useDeleteLanguageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteLanguageMutation(baseOptions?: Apollo.MutationHookOptions<DeleteLanguageMutation, DeleteLanguageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteLanguageMutation, DeleteLanguageMutationVariables>(DeleteLanguageDocument, options);
      }
export type DeleteLanguageMutationHookResult = ReturnType<typeof useDeleteLanguageMutation>;
export type DeleteLanguageMutationResult = Apollo.MutationResult<DeleteLanguageMutation>;
export type DeleteLanguageMutationOptions = Apollo.BaseMutationOptions<DeleteLanguageMutation, DeleteLanguageMutationVariables>;
export const MakeDefaultDocument = gql`
    mutation makeDefault($input: MakeDefaultInput!) {
  makeDefault(input: $input) {
    ...languageFragment
  }
}
    ${LanguageFragmentFragmentDoc}`;
export type MakeDefaultMutationFn = Apollo.MutationFunction<MakeDefaultMutation, MakeDefaultMutationVariables>;

/**
 * __useMakeDefaultMutation__
 *
 * To run a mutation, you first call `useMakeDefaultMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMakeDefaultMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [makeDefaultMutation, { data, loading, error }] = useMakeDefaultMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMakeDefaultMutation(baseOptions?: Apollo.MutationHookOptions<MakeDefaultMutation, MakeDefaultMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MakeDefaultMutation, MakeDefaultMutationVariables>(MakeDefaultDocument, options);
      }
export type MakeDefaultMutationHookResult = ReturnType<typeof useMakeDefaultMutation>;
export type MakeDefaultMutationResult = Apollo.MutationResult<MakeDefaultMutation>;
export type MakeDefaultMutationOptions = Apollo.BaseMutationOptions<MakeDefaultMutation, MakeDefaultMutationVariables>;
export const UpdateLegalDocDocument = gql`
    mutation updateLegalDoc($input: UpdateLegalDocInput!) {
  updateLegalDoc(input: $input) {
    id
    type
    description
  }
}
    `;
export type UpdateLegalDocMutationFn = Apollo.MutationFunction<UpdateLegalDocMutation, UpdateLegalDocMutationVariables>;

/**
 * __useUpdateLegalDocMutation__
 *
 * To run a mutation, you first call `useUpdateLegalDocMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLegalDocMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLegalDocMutation, { data, loading, error }] = useUpdateLegalDocMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateLegalDocMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLegalDocMutation, UpdateLegalDocMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLegalDocMutation, UpdateLegalDocMutationVariables>(UpdateLegalDocDocument, options);
      }
export type UpdateLegalDocMutationHookResult = ReturnType<typeof useUpdateLegalDocMutation>;
export type UpdateLegalDocMutationResult = Apollo.MutationResult<UpdateLegalDocMutation>;
export type UpdateLegalDocMutationOptions = Apollo.BaseMutationOptions<UpdateLegalDocMutation, UpdateLegalDocMutationVariables>;
export const SetScriptsDocument = gql`
    mutation setScripts($input: SetScriptInput!) {
  setScripts(input: $input) {
    content
    languageCode
    position
  }
}
    `;
export type SetScriptsMutationFn = Apollo.MutationFunction<SetScriptsMutation, SetScriptsMutationVariables>;

/**
 * __useSetScriptsMutation__
 *
 * To run a mutation, you first call `useSetScriptsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetScriptsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setScriptsMutation, { data, loading, error }] = useSetScriptsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetScriptsMutation(baseOptions?: Apollo.MutationHookOptions<SetScriptsMutation, SetScriptsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetScriptsMutation, SetScriptsMutationVariables>(SetScriptsDocument, options);
      }
export type SetScriptsMutationHookResult = ReturnType<typeof useSetScriptsMutation>;
export type SetScriptsMutationResult = Apollo.MutationResult<SetScriptsMutation>;
export type SetScriptsMutationOptions = Apollo.BaseMutationOptions<SetScriptsMutation, SetScriptsMutationVariables>;
export const ConfigurationsDocument = gql`
    query configurations {
  configurations {
    settings {
      name
      value
    }
  }
}
    `;

/**
 * __useConfigurationsQuery__
 *
 * To run a query within a React component, call `useConfigurationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useConfigurationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConfigurationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useConfigurationsQuery(baseOptions?: Apollo.QueryHookOptions<ConfigurationsQuery, ConfigurationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConfigurationsQuery, ConfigurationsQueryVariables>(ConfigurationsDocument, options);
      }
export function useConfigurationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConfigurationsQuery, ConfigurationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConfigurationsQuery, ConfigurationsQueryVariables>(ConfigurationsDocument, options);
        }
export function useConfigurationsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ConfigurationsQuery, ConfigurationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ConfigurationsQuery, ConfigurationsQueryVariables>(ConfigurationsDocument, options);
        }
export type ConfigurationsQueryHookResult = ReturnType<typeof useConfigurationsQuery>;
export type ConfigurationsLazyQueryHookResult = ReturnType<typeof useConfigurationsLazyQuery>;
export type ConfigurationsSuspenseQueryHookResult = ReturnType<typeof useConfigurationsSuspenseQuery>;
export type ConfigurationsQueryResult = Apollo.QueryResult<ConfigurationsQuery, ConfigurationsQueryVariables>;
export const LanguagesDocument = gql`
    query languages {
  languages {
    ...languageFragment
  }
}
    ${LanguageFragmentFragmentDoc}`;

/**
 * __useLanguagesQuery__
 *
 * To run a query within a React component, call `useLanguagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLanguagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLanguagesQuery({
 *   variables: {
 *   },
 * });
 */
export function useLanguagesQuery(baseOptions?: Apollo.QueryHookOptions<LanguagesQuery, LanguagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LanguagesQuery, LanguagesQueryVariables>(LanguagesDocument, options);
      }
export function useLanguagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LanguagesQuery, LanguagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LanguagesQuery, LanguagesQueryVariables>(LanguagesDocument, options);
        }
export function useLanguagesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<LanguagesQuery, LanguagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<LanguagesQuery, LanguagesQueryVariables>(LanguagesDocument, options);
        }
export type LanguagesQueryHookResult = ReturnType<typeof useLanguagesQuery>;
export type LanguagesLazyQueryHookResult = ReturnType<typeof useLanguagesLazyQuery>;
export type LanguagesSuspenseQueryHookResult = ReturnType<typeof useLanguagesSuspenseQuery>;
export type LanguagesQueryResult = Apollo.QueryResult<LanguagesQuery, LanguagesQueryVariables>;
export const MediaByIdsDocument = gql`
    query mediaByIds($ids: [ID!]!) {
  mediaByIds(ids: $ids) {
    ... on MediaInterface {
      ...MediaFileFragment
    }
  }
}
    ${MediaFileFragmentFragmentDoc}`;

/**
 * __useMediaByIdsQuery__
 *
 * To run a query within a React component, call `useMediaByIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMediaByIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMediaByIdsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useMediaByIdsQuery(baseOptions: Apollo.QueryHookOptions<MediaByIdsQuery, MediaByIdsQueryVariables> & ({ variables: MediaByIdsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MediaByIdsQuery, MediaByIdsQueryVariables>(MediaByIdsDocument, options);
      }
export function useMediaByIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MediaByIdsQuery, MediaByIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MediaByIdsQuery, MediaByIdsQueryVariables>(MediaByIdsDocument, options);
        }
export function useMediaByIdsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MediaByIdsQuery, MediaByIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MediaByIdsQuery, MediaByIdsQueryVariables>(MediaByIdsDocument, options);
        }
export type MediaByIdsQueryHookResult = ReturnType<typeof useMediaByIdsQuery>;
export type MediaByIdsLazyQueryHookResult = ReturnType<typeof useMediaByIdsLazyQuery>;
export type MediaByIdsSuspenseQueryHookResult = ReturnType<typeof useMediaByIdsSuspenseQuery>;
export type MediaByIdsQueryResult = Apollo.QueryResult<MediaByIdsQuery, MediaByIdsQueryVariables>;
export const LanguageDocument = gql`
    query language($id: ID!) {
  language(id: $id) {
    ...languageFragment
  }
}
    ${LanguageFragmentFragmentDoc}`;

/**
 * __useLanguageQuery__
 *
 * To run a query within a React component, call `useLanguageQuery` and pass it any options that fit your needs.
 * When your component renders, `useLanguageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLanguageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLanguageQuery(baseOptions: Apollo.QueryHookOptions<LanguageQuery, LanguageQueryVariables> & ({ variables: LanguageQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LanguageQuery, LanguageQueryVariables>(LanguageDocument, options);
      }
export function useLanguageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LanguageQuery, LanguageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LanguageQuery, LanguageQueryVariables>(LanguageDocument, options);
        }
export function useLanguageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<LanguageQuery, LanguageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<LanguageQuery, LanguageQueryVariables>(LanguageDocument, options);
        }
export type LanguageQueryHookResult = ReturnType<typeof useLanguageQuery>;
export type LanguageLazyQueryHookResult = ReturnType<typeof useLanguageLazyQuery>;
export type LanguageSuspenseQueryHookResult = ReturnType<typeof useLanguageSuspenseQuery>;
export type LanguageQueryResult = Apollo.QueryResult<LanguageQuery, LanguageQueryVariables>;
export const DesignPresetsDocument = gql`
    query designPresets {
  designPresets {
    ...designPresetFragment
  }
}
    ${DesignPresetFragmentFragmentDoc}`;

/**
 * __useDesignPresetsQuery__
 *
 * To run a query within a React component, call `useDesignPresetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDesignPresetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDesignPresetsQuery({
 *   variables: {
 *   },
 * });
 */
export function useDesignPresetsQuery(baseOptions?: Apollo.QueryHookOptions<DesignPresetsQuery, DesignPresetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DesignPresetsQuery, DesignPresetsQueryVariables>(DesignPresetsDocument, options);
      }
export function useDesignPresetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DesignPresetsQuery, DesignPresetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DesignPresetsQuery, DesignPresetsQueryVariables>(DesignPresetsDocument, options);
        }
export function useDesignPresetsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<DesignPresetsQuery, DesignPresetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DesignPresetsQuery, DesignPresetsQueryVariables>(DesignPresetsDocument, options);
        }
export type DesignPresetsQueryHookResult = ReturnType<typeof useDesignPresetsQuery>;
export type DesignPresetsLazyQueryHookResult = ReturnType<typeof useDesignPresetsLazyQuery>;
export type DesignPresetsSuspenseQueryHookResult = ReturnType<typeof useDesignPresetsSuspenseQuery>;
export type DesignPresetsQueryResult = Apollo.QueryResult<DesignPresetsQuery, DesignPresetsQueryVariables>;
export const LegalDocsDocument = gql`
    query LegalDocs {
  legalDocs {
    id
    type
  }
}
    `;

/**
 * __useLegalDocsQuery__
 *
 * To run a query within a React component, call `useLegalDocsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLegalDocsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLegalDocsQuery({
 *   variables: {
 *   },
 * });
 */
export function useLegalDocsQuery(baseOptions?: Apollo.QueryHookOptions<LegalDocsQuery, LegalDocsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LegalDocsQuery, LegalDocsQueryVariables>(LegalDocsDocument, options);
      }
export function useLegalDocsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LegalDocsQuery, LegalDocsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LegalDocsQuery, LegalDocsQueryVariables>(LegalDocsDocument, options);
        }
export function useLegalDocsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<LegalDocsQuery, LegalDocsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<LegalDocsQuery, LegalDocsQueryVariables>(LegalDocsDocument, options);
        }
export type LegalDocsQueryHookResult = ReturnType<typeof useLegalDocsQuery>;
export type LegalDocsLazyQueryHookResult = ReturnType<typeof useLegalDocsLazyQuery>;
export type LegalDocsSuspenseQueryHookResult = ReturnType<typeof useLegalDocsSuspenseQuery>;
export type LegalDocsQueryResult = Apollo.QueryResult<LegalDocsQuery, LegalDocsQueryVariables>;
export const LegalDocDocument = gql`
    query LegalDoc($id: ID!) {
  legalDoc(id: $id) {
    id
    type
    description
  }
}
    `;

/**
 * __useLegalDocQuery__
 *
 * To run a query within a React component, call `useLegalDocQuery` and pass it any options that fit your needs.
 * When your component renders, `useLegalDocQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLegalDocQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLegalDocQuery(baseOptions: Apollo.QueryHookOptions<LegalDocQuery, LegalDocQueryVariables> & ({ variables: LegalDocQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LegalDocQuery, LegalDocQueryVariables>(LegalDocDocument, options);
      }
export function useLegalDocLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LegalDocQuery, LegalDocQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LegalDocQuery, LegalDocQueryVariables>(LegalDocDocument, options);
        }
export function useLegalDocSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<LegalDocQuery, LegalDocQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<LegalDocQuery, LegalDocQueryVariables>(LegalDocDocument, options);
        }
export type LegalDocQueryHookResult = ReturnType<typeof useLegalDocQuery>;
export type LegalDocLazyQueryHookResult = ReturnType<typeof useLegalDocLazyQuery>;
export type LegalDocSuspenseQueryHookResult = ReturnType<typeof useLegalDocSuspenseQuery>;
export type LegalDocQueryResult = Apollo.QueryResult<LegalDocQuery, LegalDocQueryVariables>;
export const ScriptDocument = gql`
    query script {
  script {
    position
    content
    translations {
      languageCode
      position
      content
    }
  }
}
    `;

/**
 * __useScriptQuery__
 *
 * To run a query within a React component, call `useScriptQuery` and pass it any options that fit your needs.
 * When your component renders, `useScriptQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScriptQuery({
 *   variables: {
 *   },
 * });
 */
export function useScriptQuery(baseOptions?: Apollo.QueryHookOptions<ScriptQuery, ScriptQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ScriptQuery, ScriptQueryVariables>(ScriptDocument, options);
      }
export function useScriptLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ScriptQuery, ScriptQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ScriptQuery, ScriptQueryVariables>(ScriptDocument, options);
        }
export function useScriptSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ScriptQuery, ScriptQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ScriptQuery, ScriptQueryVariables>(ScriptDocument, options);
        }
export type ScriptQueryHookResult = ReturnType<typeof useScriptQuery>;
export type ScriptLazyQueryHookResult = ReturnType<typeof useScriptLazyQuery>;
export type ScriptSuspenseQueryHookResult = ReturnType<typeof useScriptSuspenseQuery>;
export type ScriptQueryResult = Apollo.QueryResult<ScriptQuery, ScriptQueryVariables>;