import {
  FieldFunctionOptions,
  InMemoryCache,
  makeVar,
  TypePolicy
} from '@apollo/client';
import { uniqBy } from 'lodash';
import { KeyValuePair, Undefinable } from 'core/globalTypes';
import { PROJECT_LOGO_VARIABLE } from 'core/constants/common';
import {
  NotificationTemplateGroupType,
  TransactionStatus
} from 'generatedHooks/commerce/generated';
import dateTimeConverter from 'helpers/commonHelpers/dateTimeConverter';

import createTypePolicyForDates from './helpers/createTypePolicyForDates';

const areCssVariablesGeneratedVar = makeVar(false);
const designPresetIdVar = makeVar<null | string>(null);
// const currentLanguageCodeVar = makeVar('');
export const globalConfigs = makeVar<any>({});
export const coreConfigs = makeVar<any>({});
export const currentUser = makeVar<any>(null);
export const isMobileDevice = makeVar<boolean>(false);

function merge(
  existing = { data: [] },
  incoming: { data: [] },
  args?: FieldFunctionOptions
) {
  // TODO:fonts there's a variable called `fetchMore` below. It does the same thing as onlyIncoming, so use the same technique and remove `fetchMore`.
  if (args?.variables?.onlyIncoming) {
    return incoming;
  }

  return {
    ...incoming,
    data: uniqBy([...existing.data, ...incoming.data], '__ref')
  };
}

const mediaKeyFields: TypePolicy = {
  keyFields: incoming => `Media:${incoming.id}`
};

export const cache = new InMemoryCache({
  possibleTypes: {
    MediaInterface: ['Image', 'Video', 'Audio', 'Document', 'Folder', 'Vector'],
    ProductTypeInterface: [
      'BookingAppointmentProduct',
      'BookingEventProduct',
      'BookingRentalProduct',
      'BookingTableProduct',
      'DownloadableProduct',
      'SimpleProduct',
      'ConfigurableProduct',
      'SubscriptionProduct',
      'BookingReservationProduct',
      'GiftCardProduct'
    ]
  },
  typePolicies: {
    Category: {
      fields: {
        children: {
          merge(existing, incoming) {
            return incoming?.length ? incoming : null;
          }
        }
      }
    },
    // SimpleProduct: {
    //   merge: true
    // },

    // ...createTypePolicyForDates('Order'),
    // ...createTypePolicyForDates('Order', 'createdAt'),
    ...createTypePolicyForDates('Customer', 'createdAt', { dateOnly: true }),
    ...createTypePolicyForDates('AbandonedCart'),
    ...createTypePolicyForDates('Transaction'),
    ...createTypePolicyForDates(
      'SubscriptionService',
      ['nextBillingDate', 'startBillingDate'],
      { dateOnly: true, convertByTimezone: false }
    ),
    ...createTypePolicyForDates('Domain', 'expiryDate', { dateOnly: true }),
    ...createTypePolicyForDates('ActivityRecord', 'dateTime'),
    ...createTypePolicyForDates('CustomerGroup', 'createdAt'),
    ...createTypePolicyForDates('OrderActivityRecord', 'dateTime'),
    ...createTypePolicyForDates('CustomerActivityRecord', 'dateTime'),
    ...createTypePolicyForDates('FormSubmissionActivityRecord', 'dateTime'),
    ...createTypePolicyForDates('FormSubmission', 'submittedAt'),
    ...createTypePolicyForDates('TicketAvailabilities', ['dateFrom', 'dateTo']),
    ...createTypePolicyForDates('CollectionItem'),
    ...createTypePolicyForDates('Review'),
    ...createTypePolicyForDates('ShipmentTrackingEvent', 'dateTime'),
    Shipment: {
      fields: {
        isManualShipment(_, { readField }) {
          return !(
            readField('carrierAlias') ||
            readField('carrierService') ||
            readField('carrierServiceName')
          );
        },
        createdAt: {
          read(createdAt) {
            return dateTimeConverter(createdAt);
          }
        }
      }
    },
    OrderTransaction: {
      fields: {
        status: {
          read(status: TransactionStatus) {
            if (status === TransactionStatus.FetchFailed) {
              return TransactionStatus.Declined;
            }

            return status;
          }
        },
        createdAt: {
          read(createdAt) {
            return dateTimeConverter(createdAt);
          }
        }
      }
    },
    Configuration: {
      fields: {
        languageInfo: {
          keyArgs: []
        }
      }
    },
    Setting: {
      keyFields: incoming => `${incoming.__typename}:${incoming.name}`
    },
    LanguageInfo: {
      keyFields: incoming => `${incoming.__typename}:${incoming.code}`
    },
    NotificationTemplate: {
      fields: {
        content(content, { readField }) {
          const groupType = readField('groupType');
          const isAdminNotification =
            groupType === NotificationTemplateGroupType.Admin;

          const resolvedVariables: Undefinable<KeyValuePair> =
            readField('resolvedVariables');

          if (!isAdminNotification && resolvedVariables?.project_logo) {
            return content.replaceAll(
              PROJECT_LOGO_VARIABLE,
              resolvedVariables?.project_logo
            );
          }
        }
      }
    },
    Page: {
      fields: {
        children: {
          read(children) {
            return children && children.length ? children : null;
          },
          merge(existing, incoming) {
            return incoming;
          }
        }
      },
      keyFields: incoming => {
        const languageCode = localStorage.getItem('currentLanguageCode');

        return `${incoming.__typename}:${incoming.id}${
          languageCode ? `:${languageCode}` : ''
        }`;
      }
    },
    MenuItem: {
      fields: {
        children: {
          merge(existing, incoming) {
            return incoming?.length ? incoming : null;
          }
        }
      }
    },
    DeliveryDayWithSlots: {
      keyFields: incoming => `DeliveryWeekDay:${incoming.weekDay?.toString()}`
    },
    //INFO: this part will change all products cache key to Product:ID
    // SimpleProduct: {
    //   keyFields: incoming => `Product:${incoming.id}`
    // },
    // ConfigurableProduct: {
    //   keyFields: incoming => `Product:${incoming.id}`
    // },
    // DownloadableProduct: {
    //   keyFields: incoming => `Product:${incoming.id}`
    // },
    // SubscriptionProduct: {
    //   keyFields: incoming => `Product:${incoming.id}`
    // },
    // BookingAppointmentProduct: {
    //   keyFields: incoming => `Product:${incoming.id}`
    // },
    // BookingRentalProduct: {
    //   keyFields: incoming => `Product:${incoming.id}`
    // },
    // BookingEventProduct: {
    //   keyFields: incoming => `Product:${incoming.id}`
    // },
    // BookingTableProduct: {
    //   keyFields: incoming => `Product:${incoming.id}`
    // },
    Query: {
      fields: {
        configurations: {
          merge(existing = {}, incoming = {}) {
            return { ...existing, ...incoming };
          }
        },
        mediaWithFolders: {
          keyArgs: ['search', 'type', 'parentId', 'orderBy'],
          merge(
            existing = { data: [] },
            incoming: { data: [] },
            { variables }
          ) {
            return variables?.fetchMore ? merge(existing, incoming) : incoming;
          }
        },
        unsplashImages: {
          keyArgs: ['search'],
          merge
        },
        folders: {
          keyArgs: ['first', 'type'],
          merge
        },
        customFonts: {
          keyArgs: [],
          merge
        },
        collections: {
          keyArgs: ['search'],
          merge
        },
        pages: {
          keyArgs: ['search'],
          merge
        },
        seatMapVenues: {
          keyArgs: [],
          merge: (existing = [], incoming) => {
            return [...existing, ...incoming];
          }
        },

        areCssVariablesGenerated() {
          return areCssVariablesGeneratedVar();
        }
      }
    },
    Grid: {
      keyFields: incoming =>
        `Grid:${(incoming.designPreset as { id: string })?.id}:${incoming.type}`
    },
    PresetFont: {
      keyFields: incoming =>
        `PresetFont:${(incoming.designPreset as { id: string })?.id}:${
          incoming.type
        }:${incoming.family}`
    },
    CustomFont: {
      keyFields: incoming => `CustomFont:${incoming.type}:${incoming.family}`
    },
    GoogleFont: {
      keyFields: incoming => `GoogleFont:${incoming.family}:${incoming.type}`
    },
    FontVariant: {
      keyFields: false
    },
    Image: mediaKeyFields,
    Folder: mediaKeyFields,
    Video: mediaKeyFields,
    Audio: mediaKeyFields,
    Vector: mediaKeyFields,
    Document: {
      keyFields: incoming => `Media:${incoming.id}`
    },
    FormFieldWidgetBinding: {
      keyFields: incoming =>
        `FormFieldWidgetBinding:${incoming.hash}:${incoming.targetProperty}`
    },
    CssVariable: {
      keyFields: incoming =>
        `CssVariable:${designPresetIdVar() as string}:${
          (incoming.uiElement as { id: string })?.id || 'General'
        }:${incoming.name}`
    },
    UiElement: {
      keyFields: incoming =>
        `${incoming.__typename}:${designPresetIdVar() as string}:${
          incoming.id
        }`,
      fields: {
        params: {
          read(incoming) {
            return typeof incoming === 'string'
              ? JSON.parse(incoming)
              : incoming;
          }
        }
      }
    },
    DefaultUiElement: {
      fields: {
        params: {
          read(incoming) {
            return typeof incoming === 'string'
              ? JSON.parse(incoming)
              : incoming;
          }
        }
      }
    }
  }
});
