import { FC, useState, useEffect } from 'react';
import { MinusOutlined } from '@ant-design/icons';
import { useFilterQuery, useHistory } from 'hooks';

import useDebounce from 'hooks/useDebounce';
import { PriceRangeProps } from './types';
import { Input, Space, Typography } from 'components/basic';

const { UcBodyText } = Typography;

const PriceRange: FC<PriceRangeProps> = ({
  values: controlValue,
  fromKey,
  toKey,
  onChange,
  prefix,
  debounce = 600,
  min = 0,
  max = Infinity
}) => {
  const { push, resetQuery } = useHistory();
  const { queryObject } = useFilterQuery();
  const [inputValue, setInputValue] = useState({
    [fromKey]: queryObject[fromKey],
    [toKey]: queryObject[toKey]
  });

  const debouncedToValue = useDebounce(inputValue[toKey], debounce);
  const debouncedFromValue = useDebounce(inputValue[fromKey], debounce);

  const pushToQueryString = (debouncedValue: string, key: string) => {
    if (debouncedValue) {
      push({
        [key]: debouncedValue
      });
    }

    if (debouncedValue === null) {
      resetQuery(key);
    }
  };

  useEffect(() => {
    pushToQueryString(debouncedFromValue, fromKey);
  }, [debouncedFromValue]);

  useEffect(() => {
    pushToQueryString(debouncedToValue, toKey);
  }, [debouncedToValue]);

  const inputProps = {
    placeholder: '0', //default all input placeholder
    style: { width: '100%' },
    prefix: <UcBodyText size="small">{prefix}</UcBodyText>
  };

  const handleInputChange = (value: number | null, key: string) => {
    if (onChange) {
      onChange({
        name: key,
        value
      });
    } else {
      setInputValue(prevState => ({
        ...prevState,
        [key]: value
      }));
    }
  };

  const controlValueFromValue = controlValue?.[fromKey];
  const controlValueToValue = controlValue?.[toKey];
  const fromValue = controlValueFromValue ?? queryObject[fromKey];
  const toValue = controlValueToValue ?? queryObject[toKey];

  return (
    <Space align="center">
      <div>
        <Input.InputNumber
          value={controlValueFromValue ?? queryObject[fromKey]}
          onChange={(value: number | null) => handleInputChange(value, fromKey)}
          min={min}
          max={toValue}
          {...inputProps}
        />
      </div>
      <MinusOutlined />
      <div>
        <Input.InputNumber
          value={controlValueToValue ?? queryObject[toKey]}
          onChange={(value: number | null) => handleInputChange(value, toKey)}
          min={fromValue || 0}
          max={max}
          {...inputProps}
        />
      </div>
    </Space>
  );
};

export default PriceRange;
