export const TIME_FORMAT = 'HH:mm:ss';
export const TIME_FORMAT_WITHOUT_SECONDS = 'HH:mm';
export const TIME_FORMAT_WITHOUT_SECONDS_12H = 'h:mm A';
export const DATE_FORMAT = 'YYYY-MM-DD';
export const FULL_DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const FULL_DATE_FORMAT_WITHOUT_SECONDS = 'YYYY-MM-DD HH:mm';
export const DEFAULT_TIME_VALUE = '00:00:00';

export const JS_START_DATE = '1970-01-01';

export const DATE_FORMAT_DOT = 'DD.MM.YYYY';
