import { FC } from 'react';
import { RadioChangeEvent } from 'antd';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useReactiveVar } from '@apollo/client';
import { isMobileDevice } from 'apollo/cache';

import { ProductTypeChooser } from './components';
import { ProductTypeChooserListProps } from './types';
import { getNavigationPath } from './helpers';
import TypeChooserRadioButton from '../TypeChooserRadioButton';
import { ProductType } from 'pages/products/productFamily/types';

const { TypeChooserList } = TypeChooserRadioButton;

const ProductTypeChooserList: FC<ProductTypeChooserListProps> = ({
  dataSource,
  setSelectedType,
  setSelectedFamilyId,
  setSelectedGroupType,
  navigatingPage,
  withHeading = true,
  selectedType,
  loading,
  checkLimitations,
  checkPermissions,
  heading,
  hideArrows
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation('products');

  const isMobile = useReactiveVar(isMobileDevice);

  const onChangeHandler = (e: RadioChangeEvent) => {
    const { type, id, groupType } = e.target.value;

    if (navigatingPage) {
      navigate(getNavigationPath(navigatingPage, id, groupType));

      return;
    }

    setSelectedType?.(type);
    setSelectedFamilyId?.(id);
    setSelectedGroupType?.(groupType);
  };

  return (
    <TypeChooserList
      dataSource={dataSource}
      onChange={onChangeHandler}
      renderItem={item => (
        <ProductTypeChooser
          key={item.id}
          familyId={item.id}
          title={item.name}
          familyCode={item.code}
          icon={item.icon as ProductType}
          groupType={item.groupType}
          productsCount={item.productsCount}
          isSelected={item.code === selectedType}
          checkLimitations={checkLimitations}
          checkPermissions={checkPermissions}
          hideArrow={hideArrows}
        />
      )}
      heading={
        withHeading && !isMobile
          ? heading || t('products.chooseProductFamilyType')
          : null
      }
      loading={loading}
    />
  );
};

export default ProductTypeChooserList;
