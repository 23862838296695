import * as React from 'react';
import { DownOutlined } from '@ant-design/icons';
import { createDataTestAttribute } from 'helpers/automationHelpers';
import { DataTestAttributes } from 'helpers/automationHelpers/types';

import { Dropdown, Button, Menu } from 'components/basic';
import styles from './styles.module.less';

// const Item = styled.a`
//   line-height: 31px;
//   text-indent: 4px;
//   font-size: var(--ant-font-size-lg);
// `;
//
// const Label = styled.span`
//   &:hover {
//     cursor: pointer;
//   }
// `;
//
// const SubMenu = styled(Menu.SubMenu)`
//   div,
//   svg {
//     font-size: var(--ant-font-size-lg);
//     line-height: 31px;
//     text-indent: 4px;
//   }
// `;
//
// const MenuIcon = styled.span`
//   margin-left: -8px;
//   margin-right: 8px;
// `;
//
// const SubMenuIcon = styled.span`
//   margin-left: -8px;
// `;
//
type MenuItemType = {
  text?: string;
  icon?: React.ReactNode;
  render?: React.ReactNode;
  subMenu?: MenuType[];
  dataTest?: string;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
};

type MenuType = {
  title?: string;
  items: Array<MenuItemType | null>;
};

const makeMenuItem = (menuItem: MenuItemType, key: number) => (
  <Menu.Item key={key} data-test={menuItem.dataTest}>
    <a
      className={styles.Item}
      data-test={menuItem.text}
      onClick={menuItem.onClick}
    >
      {menuItem.text ? (
        <>
          <span className={styles.MenuIcon}>{menuItem.icon}</span>
          {menuItem.text}
        </>
      ) : (
        menuItem.render
      )}
    </a>
  </Menu.Item>
);

const makeSubMenu = (menuItem: MenuItemType, index: number) =>
  menuItem.subMenu && (
    <Menu.SubMenu
      className={styles.SubMenu}
      key={index}
      title={menuItem.text}
      icon={
        menuItem.icon && (
          <span className={styles.SubMenuIcon}>{menuItem.icon}</span>
        )
      }
    >
      {makeMenu(menuItem.subMenu)}
    </Menu.SubMenu>
  );

function makeMenu(menus: MenuType[]) {
  return menus.map((menu: MenuType, i) => (
    <Menu.ItemGroup key={i} title={menu.title}>
      {menu.items.map((menuItem, index) => {
        if (!menuItem) {
          return <Menu.Divider key="div" />;
        }

        return menuItem.subMenu
          ? makeSubMenu(menuItem, index)
          : makeMenuItem(menuItem, index);
      })}
      {i !== menus.length - 1 && <Menu.Divider />}
    </Menu.ItemGroup>
  ));
}

type Props = {
  triggerType?: 'button' | 'link';
  menuItems: MenuType[];
  label: string;
};

function ActionDropdown({ menuItems, label, triggerType = 'button' }: Props) {
  return (
    <Dropdown
      trigger={['click']}
      placement="bottomRight"
      overlayStyle={{ width: 240 }}
      overlay={<Menu data-test="menu-items">{makeMenu(menuItems)}</Menu>}
    >
      {triggerType === 'button' ? (
        <Button
          data-test={createDataTestAttribute({
            dataTestAttribute: DataTestAttributes.Dropdown,
            prefix: 'new'
          })}
          type="primary"
          size="middle"
        >
          {label} <DownOutlined />
        </Button>
      ) : (
        <span
          className={styles.Label}
          data-test={createDataTestAttribute({
            dataTestAttribute: DataTestAttributes.Dropdown,
            prefix: 'ordering'
          })}
        >
          {label} <DownOutlined />
        </span>
      )}
    </Dropdown>
  );
}

export default ActionDropdown;
