import { FC, useContext, useMemo, useState } from 'react';
import {
  Avatar,
  Space,
  Layout,
  Dropdown,
  Row,
  Col,
  Tag,
  Divider,
  Flex
} from 'antd';
import { useTranslation } from 'react-i18next';
import { logout, redirectToAccounts } from 'containers/utils';
import { nameToAbbreviation, toSnakeCase } from 'helpers';
import { useScreenBreakpoints, useSidebarAvailableRoutes } from 'hooks';
import { createDataTestAttribute } from 'helpers/automationHelpers';
import { DataTestAttributes } from 'helpers/automationHelpers/types';
import { MenuProps } from 'antd/es';
import { HooryContext } from 'providers/HooryProvider';
import { useProjectContext } from 'providers/ProjectProvider';

import Notifications from '../Notifications';
import NavBar from '../NavBar';
import { ProjectsNavBar } from './components';
import styles from './styles.module.less';
import { ArrowDownIcon } from 'components/shared/SVG';
import { ShowElement } from 'components/shared';
import { Typography } from 'components/basic';
import getRoleColor from 'pages/settings/pages/team/Utils/getRoleColor';
import { HeaderProps } from './types';
import Help from 'components/global/Help';
import Channels from './components/Channels';
import PreviewButton from './components/PreviewButton';
import ChatButton from 'components/global/ChatButton';

const { VITE_APP_SERVER_URL, VITE_APP_BRANDBOOK_URL } = import.meta.env;

const { Header: AntHeader } = Layout;
const { UcBodyText } = Typography;

const Header: FC<HeaderProps> = ({
  style,
  children,
  userName,
  avatarSrc,
  defaultSelectedKeys = [],
  breakpointMd,
  projectsPage = false,
  userRole
}) => {
  const [visibleMenus, setVisibleMenus] = useState(false);
  const [visibleProjects, setVisibleProjects] = useState(false);
  const { t } = useTranslation(['common', 'team']);
  const { noAnyAccess } = useSidebarAvailableRoutes();
  const { lg } = useScreenBreakpoints();
  const project = useProjectContext();

  const {
    hoory: { isChatInitialized, openChat }
  } = useContext(HooryContext);

  const visibleHandlerMenus = (visibleMenus: boolean) => {
    if (visibleMenus) {
      setVisibleProjects(false);
    }

    setVisibleMenus(visibleMenus);
  };

  const visibleHandlerProjectsNavbar = (visibleProjects: boolean) => {
    if (visibleProjects) {
      setVisibleMenus(false);
    }

    setVisibleProjects(visibleProjects);
  };

  const goToBrandbook = () => {
    window.open(
      `${VITE_APP_SERVER_URL}/callback?target=blank&returnUrl=${VITE_APP_BRANDBOOK_URL}`,
      '_blank'
    );
  };

  const userInfoLabel = useMemo(
    () => (
      <Row wrap={false} style={{ marginBottom: 8 }} align="middle">
        <>
          <Col>
            <Avatar
              shape="circle"
              size={48}
              data-test="user-avatar"
              src={avatarSrc || undefined}
            >
              {nameToAbbreviation(userName)}
            </Avatar>
          </Col>
          <Col style={{ marginLeft: 12 }}>
            <Col>
              <UcBodyText
                className={styles.user_name}
                fontWeight="bold"
                size="small"
              >
                {userName}
              </UcBodyText>
            </Col>
            <Col>
              {userRole && (
                <Tag color={getRoleColor(userRole)} data-test="team-table-role">
                  {t(`team:defaultRoles.${toSnakeCase(userRole)}`, userRole)}
                </Tag>
              )}
            </Col>
          </Col>
        </>
      </Row>
    ),
    [avatarSrc, userName, userRole]
  );

  const menuList: Required<MenuProps>['items'][number][] = useMemo(
    () => [
      {
        key: 'userInfo',
        className: styles.user_info,
        label: userInfoLabel
      },
      {
        key: 'projects',
        className: styles.menu_item,
        'data-test': createDataTestAttribute({
          dataTestAttribute: DataTestAttributes.Option,
          prefix: 'my-projects'
        }),
        onClick: () => {
          window.open('/');
        },
        label: t('common:myProjects', 'My Projects')
      },
      {
        key: 'myBrandBook',
        className: styles.menu_item,
        'data-test': createDataTestAttribute({
          dataTestAttribute: DataTestAttributes.Option,
          prefix: 'my-brandbooks'
        }),
        onClick: goToBrandbook,
        label: t('common:myBrandbook', 'My Brandbooks')
      },
      {
        key: 'account',
        className: styles.menu_item,
        'data-test': createDataTestAttribute({
          dataTestAttribute: DataTestAttributes.Option,
          prefix: 'account-settings'
        }),
        onClick: redirectToAccounts,
        label: t('common:accountSettings', 'Account Settings')
      },
      {
        type: 'divider'
      },
      {
        key: 'logout',
        className: styles.menu_item,
        'data-test': createDataTestAttribute({
          dataTestAttribute: DataTestAttributes.Option,
          prefix: 'logout'
        }),
        onClick: logout,
        label: t('common:logOut', 'Log Out'),
        danger: true
      }
    ],
    [userInfoLabel]
  );

  return (
    <AntHeader
      style={style}
      className={`${styles.headerWrapper} ${project.isExpired ? styles.expired : ''}`}
    >
      <ShowElement isShow={!breakpointMd}>
        <>
          <ShowElement isShow={!noAnyAccess}>
            <NavBar
              visible={visibleMenus}
              visibleHandlerMenus={visibleHandlerMenus}
            />
          </ShowElement>
          <ProjectsNavBar
            visible={visibleProjects}
            visibleHandlerProjectsNavbar={visibleHandlerProjectsNavbar}
          />
        </>
      </ShowElement>

      {children}
      <Space
        className={`${styles.headerContent} ${projectsPage ? styles.projectPage : ''}`}
        size={16}
      >
        <ShowElement isShow={!projectsPage && lg}>
          <div style={{ width: 300 }} />
        </ShowElement>
        <ShowElement isShow={!projectsPage && lg}>
          <Channels />
        </ShowElement>
        <Flex gap={8} align="center">
          <ShowElement isShow={!projectsPage}>
            <Notifications />
          </ShowElement>
          <Help />
          <ChatButton disabled={isChatInitialized} onClick={openChat} />
          <ShowElement isShow={!projectsPage}>
            <Divider type="vertical" style={{ height: '100%' }} />
            <PreviewButton />
          </ShowElement>
          <Dropdown
            menu={{
              items: menuList,
              style: { padding: 16, marginTop: 8, width: 272 },
              defaultSelectedKeys: defaultSelectedKeys
            }}
            placement="bottomRight"
            trigger={['click']}
            className={styles.userDropdown}
          >
            <Space align="center" direction="horizontal">
              <Avatar
                shape="circle"
                size={40}
                data-test={createDataTestAttribute({
                  dataTestAttribute: DataTestAttributes.Dropdown,
                  prefix: 'user-avatar'
                })}
                src={avatarSrc || undefined}
              >
                {nameToAbbreviation(userName)}
              </Avatar>
              <ArrowDownIcon />
            </Space>
          </Dropdown>
        </Flex>
      </Space>
    </AntHeader>
  );
};

export default Header;
