import { FC } from 'react';
import { Upload, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { InboxOutlined, LoadingOutlined } from '@ant-design/icons';
import { UploadFile, UploadChangeParam } from 'antd/lib/upload/interface';
import { UploadRequestOption } from 'rc-upload/lib/interface';

import { CsvFileIcon, XlsFileIcon } from 'components/shared/SVG';
import styles from './styles.module.less';

const { Dragger } = Upload;

interface Props {
  onChangeHandler: (info: UploadChangeParam<UploadFile<any>>) => void;
  file: UploadFile[] | undefined;
  onCustomRequest: (options: UploadRequestOption<any>) => void;
  showUploadList: boolean;
  beforeUploadHandler: (file: File) => boolean;
  onRemoveHandler: () => void;
  fileUploadLoading: boolean;
}

const FileUploadDragger: FC<Props> = ({
  onChangeHandler,
  file,
  onCustomRequest,
  showUploadList,
  beforeUploadHandler,
  onRemoveHandler,
  fileUploadLoading
}) => {
  const { t } = useTranslation('importModal');

  const getValueFromFileChange = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }

    return e && e.fileList;
  };

  const fileIconRenderer = (file: UploadFile<any>) => {
    if (fileUploadLoading) {
      return <LoadingOutlined spin />;
    }

    return file.type === 'text/csv' ? (
      <CsvFileIcon color="#4DA789" />
    ) : (
      <XlsFileIcon color="#4DA789" />
    );
  };

  return (
    <>
      <Form.Item
        valuePropName="fileList"
        getValueFromEvent={getValueFromFileChange}
        noStyle
        name="uploadFile"
      >
        <Dragger
          beforeUpload={beforeUploadHandler}
          multiple={false}
          listType="picture"
          name="uploadFile"
          maxCount={1}
          onRemove={onRemoveHandler}
          showUploadList={showUploadList}
          customRequest={onCustomRequest}
          className={`${styles.dragger} ${styles.upload}`}
          onChange={onChangeHandler}
          fileList={file}
          iconRender={fileIconRenderer}
          height={146}
        >
          <p className={`ant-upload-drag-icon ${styles.dragIcon}`}>
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            {t('draggerText', 'Select or drag and drop file here')}
          </p>
          <p className="ant-upload-hint">
            {t('maximumSize', 'Maximum size: 64 MB')}
          </p>
        </Dragger>
      </Form.Item>
    </>
  );
};

export default FileUploadDragger;
