import { FC } from 'react';
import { NiladicVoid } from 'core/globalTypes';
import { useTranslation } from 'react-i18next';

import { Input, Modal } from 'components/basic';
import Form from 'components/core/form';

interface Props {
  onCloseModal: NiladicVoid;
  onSaveNewField: NiladicVoid;
  open: boolean;
}

const NewFieldModal: FC<Props> = ({ onCloseModal, open, onSaveNewField }) => {
  const { t } = useTranslation('importModal');

  return (
    <Modal
      onCancel={onCloseModal}
      title={t('newFieldTitle', 'New Field')}
      width={600}
      open={open}
      centered
      onOk={onSaveNewField}
      okText={'Save'}
      cancelText={'Cancel'}
    >
      <Form.Item
        name="fieldName"
        label={t('fieldName', 'Field Name')}
        labelCol={{ span: 24 }}
      >
        <Input placeholder={t('fieldName', 'Field Name')} />
      </Form.Item>
      <Form.Item
        name="defaultValue"
        label={t('nameForMissingValues', 'Name for missing values')}
        labelCol={{ span: 24 }}
      >
        <Input
          placeholder={t('nameForMissingValues', 'Name for missing values')}
        />
      </Form.Item>
    </Modal>
  );
};

export default NewFieldModal;
