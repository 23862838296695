import { FC, useMemo } from 'react';
import { Col, Row, Skeleton } from 'antd';
import { useScreenBreakpoints } from 'hooks';

import styles from 'components/wrapper/PageContainer/styles.module.less';
import { Typography } from 'components/basic';
import { BackButton, ShowElement, LoadingWrapper } from 'components/shared';
import { HeaderPageContainerProps } from 'components/wrapper/PageContainer/types';

const { UcHeading, UcBodyText } = Typography;

const HeaderPageContainer: FC<HeaderPageContainerProps> = ({
  onBack,
  title,
  extra,
  extraSaveBtn,
  loadingTitle,
  tagTitle,
  subtitle
}) => {
  const { lg } = useScreenBreakpoints();

  const subtitleContent = useMemo(() => {
    return typeof subtitle === 'string' ? (
      <UcBodyText type="secondary" size="small">
        {subtitle}
      </UcBodyText>
    ) : (
      subtitle
    );
  }, [subtitle]);

  return (
    <>
      <Row
        justify="space-between"
        className={`${styles.headerSection} headerRowPageContainer`}
        gutter={[0, 12]}
      >
        <Col flex={1}>
          <div className={styles.headerText}>
            <ShowElement isShow={Boolean(onBack)}>
              <BackButton onClick={onBack} />
            </ShowElement>
            <LoadingWrapper
              loading={Boolean(loadingTitle)}
              indicator={
                <Skeleton.Input
                  className={styles.skeletonTitle}
                  active
                  size="large"
                />
              }
            >
              <UcHeading
                fontWeight="bold"
                level={2}
                defaultMargin={false}
                title={typeof title === 'string' ? title : ''}
              >
                {title}
              </UcHeading>
              <span>{tagTitle}</span>
            </LoadingWrapper>
          </div>
        </Col>
        <Col
          className={
            lg
              ? `${styles.pageContainerExtra} pageContainerExtra`
              : styles.extraSaveBtn
          }
          flex={1}
        >
          {lg ? (
            <>
              {extra}
              {extraSaveBtn}
            </>
          ) : (
            extraSaveBtn
          )}
        </Col>
      </Row>
      <ShowElement isShow={Boolean(!lg && extra)}>
        <Row className="extraSecondSectionPageContainer">
          <Col className={styles.extra} span={24}>
            {extra}
          </Col>
        </Row>
      </ShowElement>
      <ShowElement isShow={Boolean(subtitle)}>
        <div style={{ marginLeft: 54 }}>{subtitleContent}</div>
      </ShowElement>
    </>
  );
};

export default HeaderPageContainer;
