import { Tabs, App } from 'antd';
import useCanFunction from 'providers/PermissionAndLimitationProvider/hooks/useCanFunction';
import { usePermissionAndLimitationContext } from 'providers/PermissionAndLimitationProvider';
import { useCallback, useMemo } from 'react';
import {
  Channel,
  useChannelsQuery,
  useVisualEditorVisitedMutation
} from 'generatedHooks/commerce/generated';
import { useProjectContext } from 'providers/ProjectProvider';
import { useTranslation } from 'react-i18next';
import {
  createDataTestAttribute,
  modalConfirmRenderWithDataTest
} from 'helpers/automationHelpers';
import { DataTestAttributes } from 'helpers/automationHelpers/types';

import styles from './styles.module.less';
import { ACTIVE_KEY } from './constants';

const { VITE_APP_SSO_URL } = import.meta.env;
const { useApp } = App;

const Channels = () => {
  const { modal } = useApp();
  const { t } = useTranslation(['channels', 'common']);

  const CanFunction = useCanFunction('tab');

  const { data } = useChannelsQuery();
  const channel = useMemo(() => data?.channels?.data[0], [data]);
  const [markVisualEditorVisited] = useVisualEditorVisitedMutation({
    variables: {
      id: channel?.id as string
    },
    update(cache) {
      cache.modify({
        fields: {
          channels: (existing = { data: [] }, { readField }) => {
            return existing.data.map((exChannel: Channel) => {
              if (readField('id', exChannel) === channel?.id) {
                return {
                  ...exChannel,
                  visualEditorVisited: true
                };
              }

              return exChannel;
            });
          }
        }
      });
    }
  });

  const {
    state: {
      permissions: {
        actions: { Access }
      }
    }
  } = usePermissionAndLimitationContext();

  const { id } = useProjectContext();

  const REDIRECT_URLS = {
    CMS: `${VITE_APP_SSO_URL}/callback?projectId=${id}&returnUrl=/admin`,
    VISUAL_EDITOR: `${VITE_APP_SSO_URL}/callback?projectId=${id}&returnUrl=/admin/~/visual-editor`
  };

  const goToVisualEditor = useCallback(() => {
    channel?.visualEditorVisited
      ? window.open(REDIRECT_URLS.VISUAL_EDITOR, '_blank')
      : modal.confirm({
          title: t('channels:enableVisualEditor'),
          content: t('channels:quickReminder'),
          okText: t('common:enable', 'Enable'),
          centered: true,
          onOk() {
            try {
              markVisualEditorVisited();
            } catch (e) {
              console.log(e);
            } finally {
              window.open(REDIRECT_URLS.VISUAL_EDITOR, '_blank');
            }
          },
          cancelButtonProps: {
            'data-test': createDataTestAttribute({
              dataTestAttribute: DataTestAttributes.Popup,
              prefix: `cancel-${DataTestAttributes.Button}`
            })
          },
          okButtonProps: {
            danger: true,
            'data-test': createDataTestAttribute({
              dataTestAttribute: DataTestAttributes.Popup,
              prefix: `confirm-${DataTestAttributes.Button}`
            })
          },
          modalRender: modalConfirmRenderWithDataTest
        });
  }, [channel?.visualEditorVisited]);

  const tabHandlersMapping = {
    [ACTIVE_KEY]: () => {},
    CMS: () => window.open(REDIRECT_URLS.CMS, '_blank'),
    VISUAL_EDITOR: goToVisualEditor
  };

  const items = useMemo(
    () =>
      [
        {
          key: ACTIVE_KEY,
          'data-test': createDataTestAttribute({
            dataTestAttribute: DataTestAttributes.Tab,
            prefix: 'project'
          }),
          tab: t('common:project'),
          disabled: false
        },
        CanFunction({
          I: Access.CMS,
          children: {
            key: 'CMS',
            'data-test': createDataTestAttribute({
              dataTestAttribute: DataTestAttributes.Tab,
              prefix: 'content'
            }),
            tab: t('channels:content')
          }
        }),
        CanFunction({
          I: Access.VisualEditor,
          children: {
            key: 'VISUAL_EDITOR',
            'data-test': createDataTestAttribute({
              dataTestAttribute: DataTestAttributes.Tab,
              prefix: 'design'
            }),
            tab: t('channels:design')
          }
        })
      ].map(({ tab, disabled, key, ...restArgs }: any) => ({
        key: key,
        label: <div data-test={restArgs['data-test']}>{tab}</div>,
        style: {
          fontWeight: 400,
          paddingLeft: 0
        },
        disabled
      })),
    [CanFunction]
  );

  return (
    <Tabs
      type="line"
      className={styles.channels}
      tabPosition="bottom"
      onChange={activeKey => {
        return tabHandlersMapping[
          activeKey as keyof typeof tabHandlersMapping
        ]();
      }}
      activeKey={ACTIVE_KEY}
      items={items}
    />
  );
};

export default Channels;
