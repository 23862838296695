import React, { FC, ReactNode, useMemo } from 'react';
import { FormItemProps } from 'antd/lib/form';
import { Form } from 'antd';
import { createDataTestAttribute } from 'helpers/automationHelpers';
import { DataTestAttributes } from 'helpers/automationHelpers/types';

import {
  Input,
  InputNumber,
  Switch,
  DatePicker,
  Checkbox,
  Radio,
  Select,
  Button,
  TranslatableSelect,
  TranslatableSwitch,
  TranslatableCheckbox
} from 'components/basic';
import TranslatableInput from 'components/basic/TranslatableInput/index';
import TranslatableTextArea from 'components/basic/TranslatableTextArea/index';
import TranslatableEditor from 'components/basic/EditorForAntd';
import Media from 'components/shared/Media';
import {
  NumberInput,
  PerLocaleSingleMedia,
  SaveButton
} from 'components/shared';

const { TextArea } = Input;

export type ControlTypes =
  | 'input'
  | 'input-number'
  | 'switch'
  | 'date-picker'
  | 'checkbox'
  | 'radio'
  | 'select'
  | 'submit'
  | 'translatable-input'
  | 'translatable-text-area'
  | 'translatable-editor'
  | 'checkbox-group'
  | 'text-area'
  | 'save'
  | 'translatable-select'
  | 'translatable-switch'
  | 'per-locale-media'
  | 'translatable-checkbox';

type GetRCPropsType<T> = T extends (props: infer R) => any
  ? R
  : T extends React.ComponentClass<infer R>
    ? R
    : any;

type InnerProps = {
  input: GetRCPropsType<typeof Input>;
  'input-number': GetRCPropsType<typeof InputNumber>;
  switch: GetRCPropsType<typeof Switch>;
  'date-picker': GetRCPropsType<typeof DatePicker>;
  checkbox: GetRCPropsType<typeof Checkbox>;
  'checkbox-group': GetRCPropsType<typeof Checkbox.Group>;
  radio: GetRCPropsType<typeof Radio>;
  select: GetRCPropsType<typeof Select>;
  submit: GetRCPropsType<typeof Button>;
  'translatable-input': GetRCPropsType<typeof TranslatableInput>;
  'translatable-text-area': GetRCPropsType<typeof TranslatableTextArea>;
  'text-area': GetRCPropsType<typeof TextArea>;
  'translatable-editor': GetRCPropsType<typeof TranslatableEditor>;
  save: GetRCPropsType<typeof Button>;
  'translatable-select': GetRCPropsType<typeof TranslatableSelect>;
  'translatable-switch': GetRCPropsType<typeof TranslatableSwitch>;
  'translatable-checkbox': GetRCPropsType<typeof TranslatableCheckbox>;
  media: GetRCPropsType<typeof Media>;
  'per-locale-media': GetRCPropsType<typeof PerLocaleSingleMedia>;
};

export interface UcFormItemProps<T extends ControlTypes = ControlTypes>
  extends Omit<FormItemProps, 'required'> {
  type?: T;
  options?: {
    label: string;
    value: any;
    disabled?: boolean;
  }[];
  innerProps?: InnerProps[T];
  required?: string | true;
  children?: ReactNode;
}

export class ControlMap {
  props: UcFormItemProps;

  constructor(props: UcFormItemProps) {
    this.props = props;
  }

  get innerProps() {
    return this.props.innerProps as object;
  }

  input() {
    return <Input size="large" {...this.innerProps} />;
  }

  'translatable-input'() {
    return <TranslatableInput size="large" {...this.innerProps} />;
  }

  'translatable-text-area'() {
    return <TranslatableTextArea size="large" {...this.innerProps} />;
  }

  'input-number'() {
    return <NumberInput size="large" {...this.innerProps} />;
  }

  'text-area'() {
    return <TextArea {...this.innerProps} />;
  }

  switch() {
    return <Switch {...this.innerProps} />;
  }

  'date-picker'() {
    return <DatePicker size="large" {...this.innerProps} />;
  }

  'translatable-editor'() {
    return <TranslatableEditor {...this.innerProps} />;
  }
  checkbox() {
    return <Checkbox {...this.innerProps}>{this.props.children}</Checkbox>;
  }
  'checkbox-group'() {
    // highlight-next-line
    return (
      <Checkbox.Group options={this.props.options} {...this.innerProps}>
        {this.props.children}
      </Checkbox.Group>
    );
  }

  radio() {
    // highlight-next-line
    return (
      <Radio.Group options={this.props.options} {...this.innerProps}>
        {this.props.children}
      </Radio.Group>
    );
  }

  select() {
    // highlight-next-line
    return (
      <Select size="large" options={this.props.options} {...this.innerProps}>
        {this.props.children}
      </Select>
    );
  }

  media() {
    return <Media mediaFor="categories" />;
  }

  gallery() {
    return <Media mediaFor="products" {...this.innerProps} />;
  }

  submit() {
    return (
      <Button
        data-testid="submit-button"
        size="large"
        htmlType="submit"
        {...this.innerProps}
      />
    );
  }

  save() {
    return (
      <SaveButton
        data-testid="submit-button"
        data-test={createDataTestAttribute({
          dataTestAttribute: DataTestAttributes.Button,
          prefix: 'save'
        })}
        size="large"
        htmlType="submit"
        {...this.innerProps}
      />
    );
  }

  'translatable-select'() {
    return (
      <TranslatableSelect
        size="large"
        options={this.props.options}
        {...this.innerProps}
      >
        {this.props.children}
      </TranslatableSelect>
    );
  }

  'translatable-switch'() {
    return <TranslatableSwitch {...this.innerProps} />;
  }

  'translatable-checkbox'() {
    return (
      <TranslatableCheckbox {...this.innerProps}>
        {this.props.children}
      </TranslatableCheckbox>
    );
  }

  'per-locale-media'() {
    return <PerLocaleSingleMedia {...this.innerProps} />;
  }
}

const UcFormItem: FC<UcFormItemProps> = props => {
  const {
    type,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    options,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    innerProps,
    required,
    rules: userRules,
    ...restProps
  } = props;

  const rules = useMemo(() => {
    if (userRules) {
      return userRules;
    }

    if (required) {
      if (typeof required === 'boolean') {
        return [{ required: true, message: `${props.label} is Required` }];
      }

      return [{ required: true, message: required }];
    }
  }, [required, userRules, props.label]);

  const controlMap = new ControlMap(props);

  return (
    <Form.Item {...restProps} rules={rules}>
      {type ? controlMap[type]() : props.children}
    </Form.Item>
  );
};

export default UcFormItem;
