import {
  ConditionOperator,
  QueryProductsConditionColumn,
  ProductReservationService
} from 'generatedHooks/commerce/generated';
import { Undefinable } from 'core/globalTypes';

import { productVisibilityType } from 'components/shared/Filter/utils';

const FilterKeys = {
  ...QueryProductsConditionColumn,
  PriceGte: `${QueryProductsConditionColumn.Price}_${ConditionOperator.Gte}`,
  PriceLte: `${QueryProductsConditionColumn.Price}_${ConditionOperator.Lte}`,
  TicketPriceGte: `${QueryProductsConditionColumn.TicketPrice}_${ConditionOperator.Gte}`,
  TicketPriceLte: `${QueryProductsConditionColumn.TicketPrice}_${ConditionOperator.Lte}`,
  ServicePriceGte: `${QueryProductsConditionColumn.ServicePrice}_${ConditionOperator.Gte}`,
  ServicePriceLte: `${QueryProductsConditionColumn.ServicePrice}_${ConditionOperator.Lte}`,
  AttributeFilter: 'attributeFilter'
};

/* A constant that is an object with keys that are the values of the enum QueryProductsConditionColumn. */
const filterInitialState = {
  [FilterKeys.CategoryId]: {
    column: QueryProductsConditionColumn.CategoryId,
    operator: ConditionOperator.In,
    value: []
  },
  [FilterKeys.ServicePriceGte]: {
    column: QueryProductsConditionColumn.ServicePrice,
    operator: ConditionOperator.Gte,
    value: null
  },
  [FilterKeys.ServicePriceLte]: {
    column: QueryProductsConditionColumn.ServicePrice,
    operator: ConditionOperator.Lte,
    value: null
  },
  [FilterKeys.Status]: {
    column: QueryProductsConditionColumn.Status,
    operator: ConditionOperator.Eq,
    value: null
  },
  [FilterKeys.AttributeFamilyId]: {
    column: QueryProductsConditionColumn.AttributeFamilyId,
    operator: ConditionOperator.Eq,
    value: null
  }
};

const productVisibilityInitialState = {
  [productVisibilityType.onlyInvisible]: null,
  [productVisibilityType.includeInvisible]: null
};

const qsValueMappingForProductVisibility = {
  false: false,
  true: true,
  '': null
};

const servicesListString = (
  services: Undefinable<ProductReservationService[]>
) => {
  if (!services) {
    return '-';
  }

  let res = '';
  services.forEach(element => {
    res ? (res = res + `, ${element.name}`) : (res = res + element.name);
  });

  return res ? res : '-';
};

export {
  filterInitialState,
  productVisibilityInitialState,
  FilterKeys,
  qsValueMappingForProductVisibility,
  servicesListString
};
