import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { PlusOutlined } from '@ant-design/icons';

import { Button } from 'components/basic';
import styles from 'pages/projects/Web/styles.module.less';

interface Props {}

const NewProjectButton: FC<Props> = () => {
  const { t } = useTranslation('projects');

  const newProjectHandler = () => {
    window.location.assign(
      `${import.meta.env.VITE_APP_SSO_URL}/callback?target=onboarding-start`
    );
  };

  return (
    <Button
      className={styles.addProjectBtn}
      type="primary"
      icon={<PlusOutlined />}
      data-test="create-new-project"
      onClick={newProjectHandler}
    >
      {t('newProject')}
    </Button>
  );
};

export default NewProjectButton;
