import dayjs, { Dayjs } from 'dayjs';
import {
  getFullDateFormat,
  momentToString,
  stringToMoment
} from 'helpers/dateHelpers';
import { FC, useMemo } from 'react';
import { DATE_FORMAT, FULL_DATE_FORMAT } from 'core/constants/date-constants';
import { useReactiveVar } from '@apollo/client';
import { coreConfigs } from 'apollo/cache';
import { DatePickerProps } from 'antd';
import { PureNullable } from 'core/globalTypes';

import { DatePicker } from 'components/basic';
import styles from './styles.module.less';

export type DatePickerFormatterProps = {
  value?: string;
  onChange?: (date: PureNullable<string>) => void;
  dateFrom?: Dayjs | undefined;
  dateTo?: Dayjs | undefined;
  showTime?: boolean;
  disableDateProp?: (current: Dayjs) => boolean;
} & Omit<DatePickerProps, 'value' | 'onChange'>;

const DatePickerFormatter: FC<DatePickerFormatterProps> = ({
  value,
  onChange,
  dateFrom,
  dateTo,
  showTime = true,
  changeOnBlur = true,
  disableDateProp,
  ...rest
}) => {
  const { date_format } = useReactiveVar(coreConfigs);

  const [format, beFormat] = useMemo(
    () =>
      showTime
        ? [getFullDateFormat(), FULL_DATE_FORMAT]
        : [date_format, DATE_FORMAT],
    [showTime, date_format]
  );

  const handleChange = (_: unknown, dateString?: string) => {
    onChange?.(
      momentToString(stringToMoment(dateString as string, format), beFormat)
    );
  };

  const disableDate = (current: Dayjs) => {
    if (current && current < dayjs().add(-1, 'minute')) {
      return true;
    }

    if (dateFrom && current < dayjs(dateFrom, format)) {
      return true;
    }

    return Boolean(dateTo && current > dayjs(dateTo, format));
  };

  return (
    <DatePicker
      disabledDate={disableDateProp || disableDate}
      onChange={handleChange}
      value={value ? stringToMoment(value, beFormat) : null}
      showTime={showTime}
      format={format}
      showSecond={false}
      changeOnBlur={changeOnBlur}
      rootClassName={changeOnBlur ? styles.hideFooter : ''}
      {...rest}
    />
  );
};

export default DatePickerFormatter;
