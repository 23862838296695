import { ReactNode, FC } from 'react';
import { PureNullable, Undefinable } from 'core/globalTypes';
import { createDataTestAttribute } from 'helpers/automationHelpers';
import { DataTestAttributes } from 'helpers/automationHelpers/types';

import { Typography, Card } from 'components/basic';
import { ShowElement, InfoTooltip } from 'components/shared';
import styles from './styles.module.less';

const { UcHeading, UcBodyText } = Typography;

interface ISettingsContentContainerProps {
  title?: string | ReactNode;
  subtitle?: string | ReactNode;
  infoTooltip?: string;
  children: ReactNode;
  extra?: ReactNode;
  bordered?: boolean;
  customized?: boolean;
  id?: string;
  disabled?: Undefinable<boolean>;
  disabledContent?: PureNullable<ReactNode>;
}

export const SettingsContentContainer: FC<ISettingsContentContainerProps> = ({
  disabled = false,
  title,
  subtitle,
  infoTooltip,
  children,
  extra,
  bordered = true,
  customized = true,
  disabledContent,
  id
}) => {
  return customized ? (
    <div id={id} className={`${styles.settings_content_container_customized}`}>
      <ShowElement isShow={disabled}>
        <div className={styles.disable_container}>{disabledContent}</div>
      </ShowElement>
      <Card
        data-test={createDataTestAttribute({
          dataTestAttribute: DataTestAttributes.Component,
          prefix: title
        })}
        bordered={bordered}
        extra={extra}
        title={
          title && (
            <UcHeading level={4}>
              {title}
              <ShowElement isShow={Boolean(infoTooltip)}>
                <InfoTooltip tooltipText={infoTooltip || ''} />
              </ShowElement>
            </UcHeading>
          )
        }
        className={styles.card}
      >
        {subtitle && (
          <UcBodyText
            className={styles.card_subtitle}
            size="small"
            type="secondary-dark"
          >
            {subtitle}
          </UcBodyText>
        )}
        {children}
      </Card>
    </div>
  ) : (
    <div className={`${styles.settings_content_container} `}>
      <div className={styles.content}>{children}</div>
    </div>
  );
};
