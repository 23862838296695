import { useState, FC, useEffect } from 'react';
import { Col, Card as AntCard, Row } from 'antd';
import { Project } from 'generatedHooks/accounts/generated';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import LazyImage from 'packages/Media/UIComponents/LazyImage/LazyImage';
import { imageErrorHandler, renderProjectStatusBadgeColor } from 'helpers';
import { mockImageSrc } from 'core/mocks/projects';
import { renderProjectStatus } from 'helpers/commonHelpers/renderProjectStatus';

import { VectorRightIcon } from 'components/shared/SVG';
import styles from 'pages/projects/Web/styles.module.less';
import Cover from '../Cover';
import Members from 'pages/projects/components/Members';
import { Button, Dropdown, Menu, Typography } from 'components/basic';
import Details from '../Details';
import Tag from 'pages/projects/components/CustomTag';
import { ImgIcon } from 'components/shared';
import { MENU_ITEMS, dropDownActionTypes } from 'pages/projects/constants';

const { Text, UcBodyText, UcLink } = Typography;
const { VITE_APP_SSO_URL } = import.meta.env;

interface Props {
  project: Project;
  leaveProject: (projectId: string, projectName: string) => void;
  deleteProjectHandler: (projectId: string, projectName: string) => void;
  openNotificationsSettingsModal: (
    projectId: string,
    projectClientName: string
  ) => void;
}

const Card: FC<Props> = ({
  project,
  leaveProject,
  deleteProjectHandler,
  openNotificationsSettingsModal
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation(['projects', 'common', 'menu']);
  const [dropDownVisible, setDropDownVisible] = useState(false);

  const menuActions = (
    projectId: string,
    projectName: string,
    projectUrl: string
  ) => ({
    [dropDownActionTypes.delete]: () =>
      deleteProjectHandler(projectId, projectName),
    [dropDownActionTypes.leave]: () => leaveProject(projectId, projectName),
    [dropDownActionTypes.goToVisualEditor]: () => {
      window.open(
        `${VITE_APP_SSO_URL}/callback?projectId=${projectId}&returnUrl=/admin/~/visual-editor`
      );
    },
    [dropDownActionTypes.goToCMS]: () => {
      window.open(
        `${VITE_APP_SSO_URL}/callback?projectId=${projectId}&returnUrl=/admin`
      );
    },
    [dropDownActionTypes.goToPublicMode]: () => {
      window.open(projectUrl);
    },
    [dropDownActionTypes.notificationSettings]: () => {
      openNotificationsSettingsModal(projectId, projectName);
    }
  });

  const handleScroll = () => {
    setDropDownVisible(false);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const menu = (projectId: string, projectName: string, url: string) => (
    <Menu
      onClick={e => {
        setDropDownVisible(false);
        menuActions(projectId, projectName, url)[e.key]();
      }}
    >
      {MENU_ITEMS(t).map(({ label, type, value }) => (
        <Menu.Item key={value} data-test="delete-project">
          <UcLink type={type}>{label}</UcLink>
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <AntCard
      className={`${styles.projectCardStyle} ${
        dropDownVisible ? styles.dropDownVisible : ''
      }`}
      size="small"
      cover={
        <Cover>
          <LazyImage
            src={project?.screenshotUrl}
            alt={project.name}
            onError={e => imageErrorHandler(e, mockImageSrc)}
          />
          <div className={styles.car_bottom_swiper}>
            <div className={styles.x}>
              <UcBodyText
                size="base"
                fontWeight="medium"
                className={styles.project_dashboard_text}
                onClick={() => navigate(`/${project.name}`)}
              >
                <span>
                  {t('projects:projectDashboard', 'Project Dashboard')}
                </span>
                <VectorRightIcon className={styles.right_action_icon} />
              </UcBodyText>
            </div>
            <Row
              justify="space-between"
              className={styles.swipe_action_content}
            >
              <Col>
                <Members members={project?.users?.data || []} size="large" />
              </Col>

              <Col>
                <Dropdown
                  trigger={['click']}
                  placement="bottomRight"
                  overlayStyle={{ marginTop: '8px' }}
                  open={dropDownVisible}
                  className={styles.project_card_drop_down}
                  overlay={menu(
                    project.id,
                    project.clientName,
                    project.previewUrl
                  )}
                  data-test={`project-action-menu-options`}
                  onOpenChange={e => setDropDownVisible(e)}
                >
                  <Button className={styles.card_drop_down_action}>
                    <ImgIcon size={22} icon="ellipsis" />
                  </Button>
                </Dropdown>
              </Col>
            </Row>
          </div>
        </Cover>
      }
      data-test={`project-card-${project.name
        .replaceAll(' ', '-')
        .toLowerCase()}`}
    >
      <Details>
        <Row wrap={false} justify="space-between">
          <Col xs={8} sm={10} lg={12} span={12}>
            <UcBodyText ellipsis={true} fontWeight="bold" size="base">
              {project.clientName}
            </UcBodyText>
          </Col>
          <Col>
            <Tag color={renderProjectStatusBadgeColor(project)}>
              <Text>{renderProjectStatus(project, t)}</Text>
            </Tag>
          </Col>
        </Row>
      </Details>
    </AntCard>
  );
};

export default Card;
