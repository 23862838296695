import { MenuItemType } from 'antd/lib/menu/hooks/useItems';
import { TFunction, Trans } from 'react-i18next';
import { dateDiff } from 'containers/utils';
import { CloseOutlined } from '@ant-design/icons';

import { Badge, Button, List, Space } from 'components/basic';
import styles from './styles.module.less';
import {
  getNotificationItemByType,
  getNotificationSubType
} from 'components/global/Notifications/utils';
import { INotification } from 'hooks/useNotifications/types';

interface Props {
  notificationItem: INotification;
  toggleRead: (notificationItem: INotification) => void;

  t: TFunction;
  deleteNotification: (id: string) => void;
}

const notificationItemRenderer = ({
  notificationItem,
  toggleRead,
  t,
  deleteNotification
}: Props): MenuItemType => {
  const {
    type,
    read,
    _id,
    notification: { meta }
  } = notificationItem;

  const notificationType = getNotificationSubType(type, meta.payload);

  const {
    icon: Icon,
    transComponentProps: { components, i18nkey, defaultValue, values }
  } = getNotificationItemByType(t, meta)[notificationType];

  return {
    key: _id,
    className: `${styles.notification_item} ${
      !read ? styles.notification_item_unread : ''
    }`,
    label: (
      <List.Item
        key={notificationItem._id}
        className={styles.list_item}
        extra={
          <Space direction="vertical">
            <div
              onClick={e => {
                e.stopPropagation();
                toggleRead(notificationItem);
              }}
            >
              <Badge color="geekblue" className={styles.read_toggle_badge} />
            </div>
          </Space>
        }
      >
        <List.Item.Meta
          avatar={<Icon />}
          title={
            <Trans
              t={t}
              i18nKey={i18nkey}
              components={components}
              defaults={defaultValue}
              values={values || {}}
            />
          }
          description={dateDiff(notificationItem.createdAt)}
        />
        <div className={styles.notification_delete_wrapper}>
          <Button
            onClick={e => {
              e.stopPropagation();
              deleteNotification(notificationItem._id);
            }}
            type="link"
            size="small"
            icon={<CloseOutlined />}
            className={styles.notification_delete_button}
          />
        </div>
      </List.Item>
    )
  };
};

export default notificationItemRenderer;
