interface IOptions {
  url: string;
  fileName: string;
}

const downloadFile = ({ url, fileName }: IOptions) => {
  const link = document.createElement('a');
  link.style.display = 'none';
  link.href = url;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export default downloadFile;
